@charset "UTF-8";

/* Strona główna - kategorie w naszym sklepie */
#nodes {
	margin-top:10px;
	overflow: hidden;
	.section-header {
		font-size: 22px;
		margin: 35px 0 10px;
		padding-bottom: 15px;
		border-bottom: 1px solid #ddd;
	}
	.category {
		float: left;
		width:19%;
		padding:1%;
		background-color:#fff;
		box-sizing:border-box;
		margin-top:12px;
		height:285px;
		&:not(:nth-of-type(5n+5)) {
			margin-right: 1.25%;
		}
	}
	.category-photo {
		width:151px;
		height:64px;
		display:block;
		img {
			width:100%;
			height:100%;
		}
	}
	.category-name {
		font-size:18px;
		text-align:center;
		height:44px;
		a {
			color:#000;
		}
	}
	.more-categories {
		display:block;
		text-align:center;
		color:#0191c9;
		text-transform:uppercase;
		font-size:12px;
		margin:10px 0 5px;
		font-weight:bold;
	}
	li {
		padding-left:10px;
		position:relative;
		margin-top:10px;
		&::before {
			content: "\2022";
			color:#0191c9;
			font-size:25px;
			position:absolute;
			left:0;
			top:50%;
			transform:translateY(-50%);
		}
		a {
			display:block;
			overflow:hidden;
			text-overflow:ellipsis;
			white-space:nowrap;
			font-weight:normal;
		}
	}
}
