
.social-fb,
.social-tw,
.social-pin,
.social-gplus,
.social-mail{
	width:26px;
	height:26px;
	overflow: hidden;
	display: inline-block;
	vertical-align: top;
	&:after{
		width:26px;
		height:26px;
		text-align: center;
		line-height: 26px;
		font-size:26px;
		vertical-align: top;
	}
}

.social-fb {
	&:after{
		@extend %icon-facebook;
	}
}
.social-tw {
	width:26px;
	height:26px;
	&:after{
		@extend %icon-twitter;
	}
}
.social-pin {
	width:26px;
	height:26px;
	&:after{
		@extend %icon-pinterest;
		color:#bd081c;
	}
}
.social-gplus {
	width:26px;
	height:26px;
	&:after{
		@extend %icon-google-plus;
		color:#d34836;
	}
}
.social-mail {
	&:after{
		@extend %icon-envelope-o;
		background: #999999;
		font-weight:bold;
		color:#fff;
		font-size: 17px;
		line-height: 25px;
	}
}
.social-box {
	.social-label {

	}
	float: right;
	height: 28px;
	line-height: 28px;

	ul {
		display: inline-block;

		li {
			display: inline-block;
			vertical-align: middle;
			margin-left: 2px;
		}
	}
}
