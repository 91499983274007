#comparison {
	#breadcrumb {
		&::after {
			display: none;
		}
	}

	#compare-view #compare-bar {
		display: none;
	}
}

#compare-view .products-prices{
	.price-voucher-title, 
	// .price-omnibus, 
	// .price-before, 
	.price-voucher-header, 
	.price-voucher-date, 
	.price-promotion-date{
		display: none !important;
	}
}
#compare-bar .product-preview-price{
	.price-voucher-title, 
	// .price-omnibus, 
	.price-before, 
	.price-voucher-header, 
	.price-voucher-date, 
	.price-promotion-date{
		display: none !important;
	}
}

#compare-view {
	padding: 0 10px;

	.activation-voucher {
		@extend %activation-voucher;
	}

	.product-price .subscription-price {
		align-items: flex-start;
	}

	.compare-title {
		margin-top: 0;
		font-size: 23px;
	}

	.row-details {
		display: table;
		width: 100%;

		.row,
		.row-attr {
			& > div {
				overflow-wrap: anywhere;
			}
		}
	}

	.products-buy,
	.products-names,
	.products-photos,
	.products-prices,
	.row,
	.row-attr,
	.row-category,
	.row-delete {
		display: table-row;

		& > div {
			display: table-cell;
			padding: 2px 20px 2px 0;
			position: relative;
			vertical-align: top;
		}
	}

	.products-buy > div {
		vertical-align: bottom;
	}

	.comparison-functions,
	.comparison-products {
		float: left;
		padding-top: 5px;
	}

	.comparison-products {
		display: table;
	}

	.products-count-2 {
		.comparison-functions,
		.row > div,
		.row-attr > div {
			width: 33.33%;
		}

		.comparison-products {
			width: 66.66%;
		}

		.products-buy,
		.products-names,
		.products-photos,
		.products-prices,
		.row-delete {
			& > div {
				width: 50%;
			}
		}
	}
	.price-omnibus, .price-before{
		font-size:12px;
		color: #666666;
		text-align:left;
	}
	.price-instalments-box{
		@extend %price-instalments-box;
		border:0;
		margin-top:0;
		padding:0 0 5px;
		.price-instalments:after{
			display: none;
		}
		&.is-voucher.using-discounted-price .price-instalments:not(.price-instalments-only-shop){
			color:#ED1C24;
		}
	}
	.products-count-3 {
		.comparison-functions,
		.row > div,
		.row-attr > div {
			width: 25%;
		}

		.comparison-products {
			width: 75%;
		}

		.products-buy,
		.products-names,
		.products-photos,
		.products-prices,
		.row-delete {
			& > div {
				width: 33.33%;
			}
		}
	}

	.row-category > div {
		background: #eaeaea;
		font-weight: bold;
		font-size: 12px;
		text-align: right;
		border-top: 20px solid #fff;
		text-transform: uppercase;
	}

	.attr-name {
		text-align: right;
	}
	.download-link::after {
		content: "";
		position: relative;
		top: 0px;
		left: 1px;
		display: inline-block;
		width: 4px;
		height: 4px;
		border-right: 2px solid #0c4da2;
		border-top: 2px solid #0c4da2;
		transform: rotate(45deg);
	}

	.fancyDict {
		color: inherit;
		border-bottom: 1px dotted black;
	}

	&.show-diff {
		.row-dif > div {
			background: #fffacc;
		}
	}

	.divider {
		background: #ccc;
		clear: both;
		height: 1px;
		margin-bottom: 0;
		width: 100%;
	}

	.remove-compare-box {
		overflow: hidden;
	}

	.remove-compare {
		@extend .remove-button;
		text-transform: uppercase;
		float: right;
		margin: 6px 22px 0 0;
		font-weight: bold;
		font-size: 10px;
		line-height: 27px;
		cursor: pointer;

		&::after {
			font-size: 22px;
			vertical-align: top;
		}
		@media only print {
			display: none;
		}
	}

	.show-difference {
		padding-top: 11px;
		display: inline-block;
	}

	.photo-hover {
		position: relative;
	}

	.products-photos {
		position: relative;

		div {
			text-align: center;
		}

		.product-status {
			left: 20px;
		}

		.ico-product-archival,
		.ico-product-notactive,
		a {
			display: inline-block;
			width: 190px;
			height: 169px;
		}

		img {
			width: 190px;
			height: 169px;
			margin-bottom: 10px;
			&.hover {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.products-names {
		.title {
			color: #000;
			font-weight: bold;
			display: block;
			padding-bottom: 5px;
			font-size: 16px;
		}

		.stars-rating {
			margin-bottom: 5px;
		}
	}

	.product-category {
		color: #5b5b5b;
	}
	@media only print {
		.products-buy .row > div {
			display: none;
		}
	}

	.price-voucher-name, .price-voucher-date{
		text-align: left;
	}
	.price-voucher-name{
		margin-top:10px;
		~ .price-omnibus{
			margin-bottom:10px;
		}
	}
	

	.price-normal {
		font-size: 40px;
	}
	/* EURSI-37170
	.price-old {
		display: inline-block;
	}
	*/

	.price-normal,
	.shop-price-message {
		line-height: 1;
		margin-bottom: 13px;
		display: block;
	}

	.shop-delivery-only-available {
		width: 100%;
		@extend %btn-third;
	}

	.label-button {
		@extend %btn;
		background: #ffffff;
		border: 1px solid #eaeaea;
		box-sizing: border-box;
		color: #999;
		margin-bottom: 10px;
		width: 100%;
	}

	.add-to-cart {
		width: 100%;
		@extend %btn-primary;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.details-info{
		margin: 10px 0 20px;
		@media only print {
			display: none !important;
		}
		.score-box{
			margin-top:-2px;
			float: right;
		}
	}
	.see-details{
		font-weight: bold;
		font-size: 12px;
	}
	.see-others {
		margin-bottom: 20px;
		display: block;
		font-weight: bold;
		font-size: 12px;
		margin-top: 10px;
		@media only print {
			display: none !important;
		}
	}

	.specification-header {
		padding: 5px 0;

		.label {
			font-weight: bold;
			font-size: 18px;
		}
	}

	.clipboard-button,
	.compare-back-button,
	.print-button,
	.report-bug,
	.sharebook {
		float: left;
		clear: both;
		@media only print {
			display: none;
		}
	}

	.social-box {
		margin-top: 10px;
		float: left;
		clear: both;
	}
}
