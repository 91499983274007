/*
 * common styles
 * *************
 */
.modal-form {
	h2 {
			padding: 0 10px;
			font-size: 24px;
	}
	.sub-title {
		padding: 10px 0;
		font-size: 14px;
	}
	p {
		margin-left: 10px;
	}
	.row {
		display: block;
		margin: 0 auto;
		padding:10px;
		& > label {
			text-transform: uppercase;
			display: inline-block;
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
	.row-2 {
		.inline-inputs {
			display: flex;
			justify-content: space-between;
			gap: 10px;
			.input-text {
				margin-bottom: 0;
			}
		}
	}
	button {
		margin: 0 10px 0 0;
		&.cancel {
			@extend %btn;
			@extend %btn-link;
		}
		&.send {
			@extend %btn-third;
			padding-left: 45px;
			padding-right: 45px;
		}
	}
	.error-report-value{
		display: flex;
		background: #ffffcc;
		width:100%;
		div{
			padding:7px 10px;
			font-size:14px;
		}
	}
	textarea, .select3-container {
		width:100%;
		margin-bottom: 5px;
		z-index: 2;
	}
	.select3-container .select3-option-list{
		max-height: 203px;
		overflow-y: auto;
	}
	input[type="text"] {
		width: 50%;
		margin-bottom: 5px;
		&.full-width {
			width: 100%;
		}
	}
	&:not([data-selected-subject=CONTENT_VIOLATION_ERROR]) {
		.violation-name-inputs {
			display: none;
		}
	}
	.legal-statement-checkbox {
		margin-left: -10px;
		margin-right: -10px;
		&:not(.visible-statement) {
			display: none;
		}
	}
	.form-disclaimer {
		font-size: 12px;
	}
	&.loading {
		@extend %loading;
	}
}

.new-modal-form {
	h2 {
		padding: 0 10px;
		font-size: 24px;
	}
	.sub-title {
		padding: 10px;
		font-size: 14px;
	}
	.column {
		padding:10px;
		& > label:first-child {
			text-transform: uppercase;
			display: inline-block;
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
	button {
		margin: 0 10px 0 0;
		&.cancel {
			@extend %btn;
			@extend %btn-link;
		}
		&.send {
			@extend %btn-third;
			padding-left: 45px;
			padding-right: 45px;
		}
	}
	textarea {
		width:100%;
		margin-bottom: 5px;
	}
	input[type="text"] {
		width: 50%;
		margin-bottom: 5px;
	}
	&.loading {
		@extend %loading;
	}
	.required-message em {
		color: #ed1c24;
	}
}
/*
 * END common styles
 * *****************
 */

#modal-error, #modal-technical-error, #filter-notify, #modal-utterance {
	width: 570px;
}

#cannot-add-product-reasons{
	width:380px;
	font-size:13px;
	h2{
		font-size:18px;
		font-weight: bold;
		padding:0 0 5px;
	}
	p{
		margin:10px 0;
	}
	ul{
		margin:0;
		padding:0;
	}
	li{
		position: relative;
		margin:0;
		padding:0 0 10px 30px;
		&:before{
			position: absolute;
			top:5px;
			left:10px;
			content:"";
			width:6px;
			height:6px;
			border-radius: 50%;
			background: #afafaf;
			display: block;
		}
	}
	.buttons{
		text-align: center;
		margin-top:10px;
	}
}

%new-form {
	.row {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	input[type="text"],
	input[type="email"]{
		width: 100%;
		padding: 32px 12px 18px;
		border-radius: 7px;
		font-size: 14px;
		line-height: 19px;
	}
	textarea {
		width: 100%;
		padding: 24px 12px 8px;
		border-radius: 7px;
		font-size: 14px;
		line-height: 19px;
	}
	label:not(.error) {
		position: absolute;
		top: 11px;
		left: 12px;
		right: 12px;
		z-index: 2;
		display: block;
		padding-top: 8px;
		padding-bottom: 2px;
		background: #ffffff;
		color: #767676;
		font-size: 12px;
		line-height: 16px;
	}
	.validate-target {
		margin-left: -10px;
		margin-right: -10px;
	}
	&.loading {
		@extend %loading;
	}
}

#modal-article-advice {
	max-width: 375px;
	@extend %new-form;
	h2 {
		margin-bottom: 28px;
		padding-right: 60px;
		font-size: 20px;
		line-height: 27px;
	}
	.sub-title {
		padding: 0;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.email-reminder {
		padding-left: 12px;
		padding-top: 5px;
		font-size: 12px;
		line-height: 16px;
		color: #767676;
	}
	.buttons {
		padding-top: 10px;
		button {
			width: 100%;
			margin: 0;
			&.send {
				@extend %btn-new-mobile;
			}
			&.cancel {
				@extend %btn-link;
				padding-top: 20px;
				color: #0876FE;
				font-size: 14px;
			}
		}
	}
	label:not(.error) {
		background: transparent;
	}
	.row-textarea label:not(.error) {
		background: #FFFFFF;
	}
	#article-advice-captcha {
		width: 100%;
		.info {
			max-width: 300px;
		}
	}
}

#modal-article-vote-up, 
#modal-article-vote-down {
	max-width: 375px;
	@extend %new-form;
	h2 {
		margin-bottom: 28px;
		padding-right: 60px;
		font-size: 20px;
		line-height: 27px;
	}
	p {
		font-size: 14px;
		line-height: 19px;
	}
	.sub-title {
		padding: 0;
		margin-bottom: 20px;
	}
	em {
		color: #ed1c24;
	}
	.email-reminder {
		padding-left: 12px;
		padding-top: 5px;
		font-size: 12px;
		line-height: 16px;
		color: #767676;
	}
	.vote-down-reason-list {
		padding-top: 10px;
		button[type="button"] {
			@extend %btn-new-mobile-ghost;
			margin: 0 10px 10px 0;
			&.selected {
				box-shadow: 0px 1px 3px rgba(48, 49, 51, 0.2), 0px 4px 8px rgba(48, 49, 51, 0.16), inset 0px 0px 0px 2px #016EF4;
			}
		}
	}
	.buttons {
		padding-top: 10px;
		button {
			width: 100%;
			margin: 0;
			&.send {
				@extend %btn-new-mobile;
			}
			&.cancel {
				@extend %btn-link;
				padding-top: 20px;
				color: #0876FE;
				font-size: 14px;
			}
		}
	}
	label:not(.error) {
		background: transparent;
	}
	.row-textarea label:not(.error) {
		background: #FFFFFF;
	}
	.email-agreement {
		padding-bottom: 20px;
		label.checkbox-css {
			margin-left: -10px;
		}
	}
	.vote-down-additional-section {
		display: none;
		&.show {
			display: block;
		}
	}
	.email-row, 
	.permissions-disclaimer {
		display: none;
		&.show {
			display: block;
		}
	}
	#article-vote-down-captcha,
	#article-vote-up-captcha {
		width: 100%;
		.info {
			max-width: 300px;
		}
	}
}