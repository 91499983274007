@charset "UTF-8";
#modal-active-client{
	max-width: 520px;
	padding: 40px 20px;
	background: #FFFAD1;
	text-align: center;

	.active-client-title{
		position: relative;
		padding-top: 106px;
		color: #0A0A0A;
		font-size: 20px;
		line-height: 27px;

		&::before{
			@extend %icon-warn3;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			color: #0A0A0A;
			font-size: 76px;
		}
	}
	.active-client-info{
		padding-top: 20px;
		padding-bottom: 20px;
		color: #0A0A0A;
		font-size: 20px;
		line-height: 27px;
		text-align: center;
	}
	.active-client-buttons{
		display: flex;
		flex-direction: column;
		align-items: center;

		.active-client-continue{
			@extend %btn-new-mobile;
			width: 380px;
		}
		.active-client-logout {
			@extend %btn-link;
			padding-top: 20px;
			color: #0876FE;
			font-weight: normal;
			font-size: 14px;
			&:hover {
				color: #0876FE;
			}
		}
	}
}