#products-header {
	padding: 0 10px 15px;
	h1, div {
		font-size: 24px;
		line-height: 30px;
	}
	h1 {
		display: inline;
		font-weight: bold;
		color: $text-color;
	}
	.list {
		display: inline;
		font-weight: normal;
		color: $text-color;
	}
	.count {
		display: inline;
		color: $text-color-lighter;
	}
}