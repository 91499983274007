.product-promotion {
	overflow: hidden;
}

.product-promotion-header {
	background-color: #eaeaea;
	color: $text-color;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 15px;
	padding: 5px 15px;
}

.product-promotion-items {
	margin-top: 10px;
	overflow: hidden;
}

.product-promotion-box {
	margin-bottom: 15px;
	float: left;
	width: 282px;
	padding: 0 12px;
	@media (max-width: #{$breakpoint-large}px) {
		width: 205px;
		padding: 0 10px;
	}
	&:nth-child(3n + 1) {
		clear: left;
	}
	img {
		width: 100%;
		display: block;
	}
	.product-promotion-image {
		overflow: hidden;
		margin-bottom: 10px;
		display: block;
		height: 193px;
		@media (max-width: #{$breakpoint-large}px) {
			height: 140px;
		}
	}
}


	.product-promotion-title {
		margin-bottom: 5px;
		color: #000;
		font-weight: bold;
		&:hover {
			color: #000;
		}
	}

	.product-promotion-description {
		margin-bottom: 5px;
	}

	.product-promotion-description, .product-promotion-title {
		font-size: 13px;
	}

	.product-promotion-date {
		color: #777;
	}
