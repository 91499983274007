.bottom-bar-container {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	max-width: 1300px;
	margin: auto;
	z-index: 3;
}

#bottom-bar {
	position: absolute;
	right: 50px;
	bottom: 0;
	background: #eaeaea;
	border: 1px solid $border-color;
	text-align: center;
	color: $text-color;
	font-weight: bold;
	z-index: 1;
	transition: all 0.3s ease;
	transform: translate(0px, 50px);

	>* {
		vertical-align: bottom;
		text-align: center;
		margin-bottom: 0px;
		transition: all 0.4s ease;
	}


	.show-more, .go-to-compare {
		padding: 3px 10px;
		text-transform:none;
		font-size:11px;
		white-space: nowrap;
		&+.show-more {
			border-left:1px solid #ccc;
		}
	}
	.disable-go-to-compare .go-to-compare{
		color:$text-color-lighter;
		&:hover{
			text-decoration:none;
		}
	}
	.disable-modal .show-more{
		display:none;
	}
	a {
		text-transform: uppercase;
	}
	.bottom-bar-description {
		display: inline-block;
		padding: 0 5px;
		font-size:11px;
	}
}
#clipboard-products{
	line-height:35px;
}
@if($app-katalog){
	#wc-clear-bottom-bar{
		border-left: 1px solid #ccc;
		opacity:0;
		height: 39px;
		display: inline-block;
		margin-bottom: 0;
	}
	.button-clear{
		margin:8px 10px 0;
		display: inline-block;
		&:after{
			@extend %icon-trash;
			color:red;
			font-size:20px;
		}
	}
}

#last-watched, #compare-products, #clipboard-products{
	opacity: 0;
	max-width: 1px;
	height: 39px;
	display: inline-block;
	margin-bottom: 0;

	&.visible{
		transform: translate(0px, 0px);
		margin-bottom: 50px;
		max-width: 450px;
		opacity: 1;

		&~.visible {
			border-left:1px solid #ccc;
			padding-left:5px;
		}
		&~ #wc-clear-bottom-bar{
			opacity:1;
			margin-bottom: 50px;
		}
	}

	&>* {
		vertical-align: middle;
	}
}

.bottom-bar-products {
	height: 34px;
	margin:3px 0 0;
	display: inline-block;
	text-align: center;

	li {
		display: inline-block;
		position: relative;
		border: 1px solid $border-color;
		vertical-align: middle;
		margin: 0 1px;
		overflow:hidden;
		width:34px;
		height:30px;
		&::after{
			@extend %icon-compare;
			font-size:12px;
			color:$text-color-lighter;
			line-height: 30px;
		}
	}

	.product-image {
		position: relative;
		display: block;
		height: 100%;

		img {
			max-height: 100%;
			font-size:8px;
			font-weight:normal;
		}
	}
}

#last-watched-wrapper {
	min-height: 100px;
	max-height: 447px;
}

@keyframes delete-last-watched-product {
	95% {
		transform: translate(-600px, 0px);
		opacity: 0;
	}

	96%, 100% {
		transform: translate(-600px, 0px);
		opacity: 0;
		overflow: hidden;
		padding: 0;
		height: 1px;
		min-height: 1px;
	}
}

.user-logged .bottom-bar-modal .note {
	display:none;
}
.bottom-bar-modal{
	ul{
		display: block;
		overflow: hidden;
		position: relative;
	}
	li{
		display: block;
		padding: 10px 0;
		width: 100%;
		border-bottom: 1px solid $border-color;
		transform: translate(0px, 0px);
		opacity: 1;
		min-height: 76px;
		&:first-child {
			border-top: 1px solid $border-color;
		}

		&>div {
			display: inline-block;
			vertical-align: top;
		}

		&.deleting {
			animation: delete-last-watched-product .8s forwards;
			max-height: 1px;
		}
	}
	.title {
		display:inline-block;
		padding-bottom: 25px;
		font-size: 23px;
		margin-right:5px;
		font-weight: bold;
	}
	.subtitle{
		display: block;
		padding-bottom: 25px;
		font-size: 12px;
		color:$text-color-lighter;
	}
	.buttons{
		margin-top:20px;
		overflow:hidden;
	}
	.close-modal {
		position: absolute;
		top: 12px;
		right: 12px;
		font-size:0;
		overflow: hidden;
		&::after{
			@extend %icon-modal-close;
			font-size:28px;
			color:$text-color-lighter;
		}
		&:hover{
			text-decoration:none;
		}
	}
	.empty{
		height:80px;
		line-height:77px;
		&::after{
			color:$text-color-lighter;
			font-size:13px;
			content: 'Mo\017C esz doda\0107  kolejny Towar'
		}
		&::before{
			color:$text-color-lighter;
			margin:0 36px 0 30px;
			display:inline-block;
			@extend %icon-compare;
			font-size:41px;
			width:48px;
			text-align:center;
		}
	}
	.loading {
		height:80px;
		width:100%;
		position:relative;
		&::after{
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -24px;
			margin-left: -24px;
			height: 28px;
			width: 28px;
			animation: rotate360 0.8s infinite linear;
			border: 8px solid #eaeaea;
			border-right-color: transparent;
			border-radius: 50%;
			content:"";
		}
	}
		@keyframes rotate360 {
		0%	{ transform: rotate(0deg); }
		100%	{ transform: rotate(360deg); }
	}
	.select3-container{
		display:inline-block;
		width: 180px;
		top: 10px;
	}
	.product-image {
		width: 100px;
		text-align: center;

		a {
			display: block;

			img {
				width: 72px;
				height: 65px;
			}
		}
	}

	.product-content {
		padding-left: 10px;
		width: 335px;

		.product-name {
			display: block;
			font-size: 16px;
			font-weight: bold;
			padding-bottom: 7px;

			>a {
				color: #2b2e38;
			}
		}

		.product-category {
			display: block;
			font-size: 12px;
			color: $text-color-lighter;
			padding-bottom: 7px;
		}

		.price-normal {
			font-size: 18px;
			padding-right: 7px;
		}
		.price-omnibus,
		.price-before {
			margin-top: 3px;
			text-align: left;
			font-size: 12px;
		}
	}

	.product-navigation {
		width: 120px;
		text-align: right;
		padding-right: 10px;

		.show-more {
			position: absolute;
			right: 15px;
			bottom: 10px;
			display: inline-block;
			font-size: 10px;
			font-weight: bold;
			text-transform: uppercase;
			margin-top: 37px;

			&:after {
				@extend %icon-small-arrow;
				margin-left:2px;
				font-size:18px;
			}
		}

		.button-delete {
			vertical-align: top;
			font-size: 0;
			margin-left: 10px;
			display: inline-block;
			&::after{
				font-size: 19px;
				color:$link-color;
				@extend %icon-trash;
			}
			&:hover{
				text-decoration:none;
				&::after{
					color:$link-color-hover;
				}
			}
		}

		.button-clipboard {
			vertical-align: top;
			font-size: 0;
			display: inline-block;
			&::after{
				font-size: 19px;
				color:$link-color;
				@extend %icon-heart-empty;
			}
			&:hover{
				text-decoration:none;
				&::after{
					color:$link-color-hover;
				}
			}
		}
	}
	.compare-continous{
		float:left;
	}
	.go-to-compare{
		@extend %btn-primary;
		float:right;
	}
	.show-as-listing {
		display: inline-block;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
		padding-right: 8px;

		&:after {
			@extend %icon-small-arrow;
			margin-left:2px;
			font-size:18px;
		}
	}

	.note {
		font-size: 14px;
		margin:25px 0 0;
		a {
			font-weight: bold;
		}

		&::before {
			@extend %icon-info;
			font-size:16px;
			color:$text-color-lighter;
		}
	}
}
#last-watched-modal {
	padding: 32px;
	padding-right: 16px;
	width: 650px;

	#last-watched-wrapper .simplebar-content,
	#last-watched-wrapper:not(.simplebar) {
		padding-right: 17px;
		box-sizing: border-box;
	}
}
#compare-products-modal {
	padding: 30px;
	width: 500px;
}
#compare-products-list{
	.button-clipboard, .show-more{
		display:none;
	}
	.product-navigation{
		width:29px;
	}
	.product-content {
		width: 284px;
	}
}
