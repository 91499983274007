#filter-box{
	.filter-typ-checkbox{
		> a, > span.link{
			display:inline-block;
			color: $text-color;
			text-decoration:none;
			&:hover{
				text-decoration:underline;
			}
		}
		img{
			display:inline-block;
			vertical-align: middle;
		}
		em{}
	}


	.filter-deactive:not(.filter-selected){
		cursor:default;
	}
	.filter-item-status:not(.filter-deactive){
		&.filter-value-1 a{
			color: #17bf2e;
		}
		&.filter-value-2 a{
			color: #cf1919;
		}
		&.filter-value-3 a{
			color: #09409f;
		}
	}
}

#filter-box{
	margin-bottom:10px;
	padding:0 0 10px 0;
	> * {
		padding: 20px 0;
		overflow: hidden;
		&:first-child {
			padding-top: 0;
		}
		&:not(:first-child) {
			border-top: 1px solid $border-color;
		}
	}
	#filter-sort {
		overflow: visible;
		.none{
			display : none;
		}
	}
	.filter-hide{
		display:none;
	}
	.filter-button-hide{
		display:none;
	}
	.filter-button-show{
		display:inline-block;
	}
	.filter.show-more{
		.filter-hide{
			display:block;
		}
		.filter-button-show{
			display:none;
		}
		.filter-button-hide{
			display:inline-block;
		}
	}
	.filter-header{
		font-weight: bold;
		padding:10px 10px;
		text-align:left;
		font-size: 14px;
		background:#ffffff;
		color:#333;
		border-bottom:2px solid #FFE01D;
	}
	.filter-empty{
		text-align:center;
		border: 1px solid $border-color;
		border-bottom:0;
		padding: 10px 0;
		font-weight:bold;
		font-size: 12px;
	}
	.filter-name{
		clear:both;
		padding-left: 30px;
		margin: 0 10px 0 0;
		font-weight: bold;
		text-transform: uppercase;
		position: relative;
		cursor: pointer;
		float: left;
		clear: none;
		&:before {
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 8px solid #999;
			content: "";
			left: 7px;
			position: absolute;
			margin-top: -3px;
			top: 50%;
			transition: all 0.3s ease 0s;
		}
		&:hover:before {
			border-top: 8px solid $checkbox-color;
			@if($app-ole){
				border-top: 8px solid $link-color-hover;
			}
		}
	}
	.filter-clear {
		float: right;
		font-size: 9px;
		line-height: 18px;
		text-transform: uppercase;
		padding-right:15px;
		position:relative;
		&:after {
			@extend %icon-x;
			position:absolute;
			right:4px;
			top:4px;
			font-size:7px;
			color:$checkbox-color;
			@if($app-ole){
				color:$link-color;
			}
		}
	}
	.filter-clear-all {
		@extend .filter-clear;
	}
	.filter-item{
		clear:both;
		cursor: pointer;
		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}

	.filter:not(.filter-expanded){
		.filter-item, .filter-button {
			display: none !important;
		}
	}

	.filter-expanded {
		.filter-name{
			margin-bottom: 20px;
			&:before {
				transform: rotate(180deg);
			}
		}
	}
	.filter-typ-checkbox,
	.filter-typ-radio{
		position:relative;
		padding: 3px 0 0 30px;
		line-height: 18px;
		min-height: 20px;
		> a, > span.link{
			display: inline;
			color: $text-color;
		}
		> span.link{
			color: $text-color-lighter;
			&:hover{
				text-decoration:none;
			}
			em{
				color: $text-color-lighter;
			}
		}
		img{
			margin-right:3px;
			max-width:80px;
			max-height:18px;
			vertical-align: sub;
			width: auto;
		}
		em{
			color: $text-color-lighter;
			font-style: normal;
		}
		>a+em{
			display:inline;
		}
		&:hover input:not(:disabled) + i{
			border: 1px solid $checkbox-color;
			@if($app-ole){
				border: 1px solid $link-color-hover;
			}
		}
	}
	.filter-typ-radio{
		.radio-css {
			position: absolute;
			left: 5px;
		}
	}
	//style odpowiedzialne za przełączanie podfiltrow
	.filter-item{
		b{
			&::after{
				@extend %icon-arrow-down;
				font-size:17px;
				color:$text-color-lighter;
				margin:0 3px;
			}
		}
		&:hover b{
			color:$link-color;
		}
		&.filter-hasSubOptions.filter-show b::after{
			@extend %icon-arrow-up;
		}
		&.filter-isSubOption{
			display:none;
			&.filter-show{
				display:block;
			}
		}
		&.filter-hasSubOptions{
			&.filter-show{
				display:block;
			}
		}
	}
	//koniec styli odpowiedzialnych za przełączanie podfiltrow
	span.dictionary-icon{
		overflow:hidden;
		cursor:pointer;
		text-indent:0px;
		font-size:0;
		position:relative;
		width:14px;
		display:inline-block;
		height:15px;
		&::before{
			position:absolute;
			top:0;
			left:0;
			@extend %icon-tooltip;
			color:$text-color-lighter;
			font-size:14px;
			line-height:14px;
		}
	}
	.filter-hasIcon {
		a:hover {
			text-decoration: none;
		}
	}
	.filter-button{
		padding:0 10px;
		text-transform: lowercase;
		a{
			margin-left: 21px;
			font-weight: bold;
		}
	}
	.filter-selected{
		cursor:pointer;
		background-color: $filter-selected;
		&:hover{
			a{
				text-decoration:underline;
			}
		}
	}
	.filter-deactive{
		a{
			color:$text-color-lighter;
		}
	}
	.filter-price{
		a{
			display:inline-block;
		}
		.filter-item {
			margin-left: 5px;
			&> *{
				vertical-align:middle;
			}
		}
		label{
			display:inline-block;
		}
		input{
			@extend %input;
			width:75px;
			text-transform: lowercase;
			height: 25px;
		}
	}
	#filter-price-button {
		margin-left: 5px;
		padding: 4px 8px;
		height: 17px;
		@extend %btn;
		@extend %btn-second;
	}
	.filter-price-range {
		padding: 0 5px;
		font-size: 16px;
	}
	.filter-isSubOption{
		margin-left: 13px;
	}
	.stars-rating {
		margin-left: 5px;
		height: auto;
		vertical-align: initial;
		a {
			position: relative;
		}
	}
	.filter-item-grade {
		.link {
			&:hover {
				text-decoration: none;
			}
		}
		em {
			vertical-align: top;
		}
	}
	.filter-grade {
		.filter-typ-checkbox {
			padding-bottom: 0;
		}
	}
	.no-stars {
		background: none;
		top: 0!important;
		color: $text-color;
	}
}
#filter-selected{
	.filter-name {
		cursor: initial;
		padding-left: 5px;
		&:before {
			display: none;
		}
	}
	li {
		overflow: hidden;
		&:not(:first-child):not(.filter-no-url-item){
			cursor: pointer;
			a {
				background-color: $filter-selected;
				line-height: 30px;
				position: relative;
				color: $text-color;
				padding-left: 31px;
				display: block;
				&:before {
					@extend %icon-x;
					font-size:7px;
					color:$checkbox-color;
					@if($app-ole){
						color:$link-color;
					}
					width:14px;
					height:14px;
					text-align:center;
					line-height:13px;
					position: absolute;
					margin-top: 6px;
					left: 5px;
					border: 1px solid $border-color;
					background-color: #fff;
					pointer-events: none;
				}
				&:hover:before {
					border: 1px solid $checkbox-color;
					@if($app-ole){
						border: 1px solid $link-color-hover;
					}
				}
			}
			&:hover {
				> a {
					text-decoration: underline;
				}
			}
		}
		&.filter-no-url-item {
			cursor: initial;
			position: relative;
			padding-left: 31px;
			line-height: 30px;
			display: block;
			&:before {
				@extend %icon-x;
				font-size:8px;
				color:$text-color-lighter;
				width:14px;
				height:14px;
				text-align:center;
				line-height:14px;
				position: absolute;
				margin-top: 6px;
				left: 5px;
				border: 1px solid $border-color;
				background-color: #fff;
				pointer-events: none;
			}
			&:hover {
				cursor: not-allowed;
			}
		}
	}
}
#filter-itemsOnPage{
	float:left;
	label{
		float:left;
		margin:3px 5px 0 10px;
		display:inline-block;
		font-weight:bold;
	}
	select{
		width:40px;
	}
}
#filter-view{
	display:none;
	label{
		display: inline-block;
		margin-right:20px;
		padding-bottom: 10px;
		font-weight:bold;
		text-transform: uppercase;
	}
	a{
		margin-right:10px;
		display: inline-block;
		padding:4px;
		&:after{
			font-size:19px;
			color:$link-color;
		}

		&.tiles{
			&:after{
				@extend %icon-table;
			}
		}
		&.list{
			&:after{
				@extend %icon-bars;
			}
		}
	}
}
.view-tiles #filter-view a.tiles, .view-list #filter-view a.list{
	background:#ddd;
}

.flat-select,
#filter-sort{
	padding-left: 5px;
	label{
		display: block;
		padding-bottom: 10px;
		font-weight:bold;
		text-transform: uppercase;
	}
	.select3-container{
		display: block;
		width: 100%;
		border-color: $border-color;
		.select3-option-selected {
			background-color: $filter-selected;
			color: $text-color;
		}
		.select3-option-list{
			border-color: $border-color;
		}
		.select3-option:hover {
			background-color: $link-color;
			@if($app-ole){
				background-color: $link-color-hover;
			}
			color: #fff;
		}
		.select3-selection-arrow {
			border-left: none;
		}
	}
}
#no-selection-notify{
	border-top:1px solid #cacaca;
	padding:10px 10px 0;
	a{
		font-weight:bold;
	}
}
