.city-autocomplete-list {
	position: absolute;
	top: 40px;
	left: 0;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	list-style: none;
	margin: 0;
	padding: 0;
	background: #fff;
	max-height: 280px;
	display: block;
	z-index: 1;
	border-bottom: 1px solid $border-color;
	&.list-empty {
		display: none;
	}
	li {
		display: block;
		padding: 0 10px;
		line-height: 30px;
		border: 1px solid $border-color;
		border-bottom: 0;
		font-size: 12px;
		color: $text-color;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		&:hover, &.selected {
			background: #104790;
			color: #fff;
		}
		&.city-name-list-item-hidden {
			display: none;
		}
	}
}

.city-autocomplete-zipCode {
}

.city-autocomplete-cityName {
	&.city-autocomplete-loading {
		background: url($img-url + 'loading.gif') no-repeat 97% center transparent;
	}
}
