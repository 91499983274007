.product-status {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0 12px;
	height: 30px;
	z-index: 1;
	display: inline-block;
	text-align: center;
	line-height: 30px;
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: -20px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 30px 20px 0 0;
	}
	&.bestseller {
		background: $bestseller-color;

		&:after {
			border-color: $bestseller-color transparent transparent transparent;
		}
	}
	&.nowosc {
		background: $novelty-color;

		&:after {
			border-color: $novelty-color transparent transparent transparent;
		}
	}

	&.internet,
	&.promocja {
		background: $promotion-color;

		&:after {
			border-color: $promotion-color transparent transparent transparent;
		}
	}

	&.wyprzedaz {
		background: $sale-color;

		&:after {
			border-color: $sale-color transparent transparent transparent;
		}
	}
	&.outlet {
		background: #3B80BA;

		&:after {
			border-color: #3B80BA transparent transparent transparent;
		}
	}
	&.przedsprzedaz {
		background: $advance-sale-color;
		
		&:after {
			border-color: $advance-sale-color transparent transparent transparent;
		}
	}
}
.product-promotion-logo{
	position:absolute!important;
	z-index:2;
	bottom:0;
	right:0;
	width:80px !important;
	height:80px  !important;
	display:block;
	background: center center no-repeat;
	background-size: contain;
	&:hover {
		background: center center no-repeat;
		background-size: contain;
	}
}
