#error404 {}

#error-content {
	padding: 0 10px;

	h2 {
		font-size: 25px;
		font-weight: bold;
		padding: 20px 0;
	}

	ul {
		margin: 20px 0 40px 17px;
	}

	li {
		margin-bottom: 15px;
		font-size: 15px;
		list-style: inherit;
	}
}

#catalog-grid {
	padding: 0 10px 20px;
	overflow: hidden;

	.item {
		width: 23%;
		margin-right: 2%;
		float: left;

		&:nth-child(4n+1) {
			clear: both;
		}
	}

	h3 {
		padding: 0 0 8px;
		margin: 0;

		a {
			font-size: 15px;
			color: $text-color;
		}
	}

	ul {
		display: block;
		margin-bottom: 20px;
	}

	.count {
		color: $text-color-lighter;
		font-size: 13px;
	}

	li {
		font-size: 13px;

		.count {
			font-size: 11px;
		}
		display: block;
		margin-bottom: 5px;
	}

	.more {
		font-weight: bold;
	}
}
