#see-recommended {
	overflow: hidden;
	margin-top: 50px;
	.see-also-title {
		font-weight: bold;
		font-size: 16px;
		color: $text-color;
		background-color: #eaeaea;
		padding: 11px 15px;
		margin-bottom: 15px;
	}
	.see-also-box {
		width: 174px;
		height: 170px;
		padding-right: 10px;
		float: left;
		@media screen and (max-width: $breakpoint-large+px) {
			width: 126px;
		}
	}
	.see-also-price {
		font-weight: bold;
		font-size: 18px;
		color: #e63327;
	}
	.subscription-price {
		align-items: flex-start;
		p {
			text-align: left;
			span {
				display: block;
			}
		}
		.price-monthly {
			font-size: 18px;
		}
		.promotion-price {
			font-size: 12px;
		}
	}
	.see-also-available-soon, .see-also-inactive {
		font-weight: bold;
		color: #e63327;
		line-height: 20px;
	}
	.photo-link {
		display: block;
		width: 71px;
		height: 63px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.see-also-text {
		height: 60px;
	}
	.see-also-name {
		line-height: 20px;
		max-height: 40px;
		overflow: hidden;
		padding: 0;
		a {
			color: $text-color;
			&:hover {
				color: $text-color;
			}
		}
	}
	.see-also-category {
		color: $text-color-lighter;
		line-height: 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.see-also-details-link {
		line-height: 20px;
	}
	ul {
		overflow: hidden;
		left: 0;
		position: absolute;
	}
	.see-also-carousel.product-slider {
		height: 170px;
		position: relative;
	}
}
