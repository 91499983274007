#top-product-opinions {
	margin-bottom: 20px;
	&.extended-header {
		.product-header h2{
			height: 40px;
		}
	}
	.top-product-opinions-header {
		padding: 5px 15px;
		background-color: #eaeaea;
		font-weight: bold;
		font-size: 24px;
	}

	.products-list {
		display: table;
		overflow: hidden;
	}

	.product {
		display: table-cell;
		width: 25%;
		padding: 20px 13px 20px;
		vertical-align: top;
		&:last-child {
			margin-right: 0;
		}

		.stars-rating {
			margin-bottom: 5px;
		}

		.price-normal {
			font-size: 20px;
		}

		.price-instalments, .price-old, .product-saved, .price-promotion-date {
			display: none;
		}
	}

	.product-header {
		margin-bottom: 10px;
		h2 {
			font-size: 14px;
			line-height: 20px;
			max-height: 40px;
			overflow: hidden;
			a {
				color: $text-color;
			}
		}
	}

	.product-photos {
		margin-bottom: 15px;
		text-align: center;
		a{
			width: 190px;
			height: 170px;
			display:block;
			margin:0 auto;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}

	.product-opinion {
		height: 90px;
		margin-bottom: 10px;
		position: relative;
		line-height: 18px;
		overflow: hidden;

		a {
			color: $text-color;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		}
	}

	.product-opinion-author {
		line-height: 18px;

		a {
			color: $text-color-lighter;
		}

	}

	.product-opinion-date {
		display: block;
	}
}

@media (max-width: $breakpoint-large+px) {
	#top-product-opinions {
		.product {
			padding: 10px 10px;
		}

		.product-photos {
			a {
				width: 102px;
				height: 91px;
			}
		}
	}
}
