#gift-card-payment {
	#top {
		height: 68px;
	}
}

#cart #gift-card-payment-type .errorBox {
	margin: 0;
}

#gift-card-payment-type {
	padding: 10px;
	margin: 20px 0 30px;

	.gift-card {
		padding: 6px 15px 3px;
		position: relative;
		border: 2px solid transparent;

		&.statusError {
			input.error {
				border-color: #ff0000;
			}

			span.error {
				display: none !important;

				&:first-of-type {
					display: block !important;
				}
			}
		}

		.gift-card-info {
			font-weight: bold;
			color: #ed1c24;
		}

		.gift-card-fields {
			display: inline-block;
			width: 387px;
			margin: 0 10px 0 0;
			vertical-align: top;
			label {
				display: block;
				margin: 0 0 6px;
				font-size: 13px;
				font-weight: bold;
			}

			.text {
				height: 35px;
				width: 100%;
				border: 1px solid $border-color;
				text-align: center;
				font-size: 14px;
				box-sizing: border-box;
				color: #333333;
				&:disabled {
					color: #6c6e6e;
					font-weight: bold;
					background-color: #fafafa;
				}
			}

			.gift-card-pin {
				float: right;
				width: 115px;

				input,
				label {
					text-align: center;
				}
			}

			.gift-card-number {
				float: left;
				width: 261px;
			}
		}
		.gift-card-actions {
			display: inline-block;
			vertical-align: bottom;
			text-align: right;
			> * {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.add-gift-card-button-approve {
			@extend %btn;
			@extend %btn-second;
			margin: 0;
		}
	}

	.add-gift-card-form {
		&:first-of-type {
			.gift-card-remove {
				display: none;
			}
		}
	}

	.gift-card-to-pay {
		font-size: 18px;
		display: inline-block;
		width: 100%;
		text-align: right;
		box-sizing: border-box;
		padding: 50px 10px 0 0;

		b {
			color: #cc0000;
		}
	}

	.gift-card-has-remaining-funds {
		font-size: 14px;

		b {
			color: #005CA9;
		}
	}
}

.payment-impossible-modal-content {
	padding: 11px 0;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}
#added-gift-card {
	padding: 13px 0 0;

	.step {
		font-size: 18px;
		font-weight: bold;
		padding: 10px 10px 9px 45px;
		position: relative;

		.cancel-payment {
			display: inline-block;
			margin-left: 6px;
			font-size: 12px;
		}

		&::before {
			width: 25px;
			height: 25px;
			background: #6e6e6e;
			font-weight: bold;
			color: #fff;
			border-radius: 50%;
			line-height: 24px;
			text-align: center;
			position: absolute;
			top: 8px;
			left: 9px;
		}
	}

	.step-1::before {
		content: "1";
	}

	.step-2::before {
		content: "2";
	}

	#gift-card-payment-type {
		margin: 0 7px 0 21px;
		padding: 0 0 22px 23px;
		position: relative;
		border-left: 1px solid #cccccc;
	}

	.list {
		display: table;
		width: 100%;
	}

	.list .gift-card {
		display: table-row;

		> * {
			vertical-align: middle;
			display: table-cell;
			padding: 9px 13px;
		}

		.gift-card-options {
			text-align: right;
			vertical-align: bottom;
			padding-right: 35px;
		}
	}
	.gift-card-balance {
		display: block;
		font-size: 14px;
		text-align: inherit;
	}
	.gift-card-price {
		font-weight: bold;
	}

	.add-another-gift-card-button {
		display: block;
		border:0;
		padding: 0 0 0 10px;
		width: 170px;
		height: 28px;
		font-size: 13px;
		line-height: 17px;
		text-transform: initial;
		font-weight: bold;
		color: #005CA9;
		&::before{
			content:'+';
			font-size: 20px;
			line-height: 17px;
			font-weight: initial;
			position: relative;
			padding-right: 5px;
			display: inline-block;
			vertical-align: top;
		}
		&:hover {
			color: #2B77B8;
		}
	}

	.add-another-gift-card {
		margin-top: 6px;
	}

	.gift-card-remove {
		position: relative;
		padding-top: 3px;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: bold;
		color: #6e6e6e;
		&::before{
			position: relative;
			@extend %icon-close;
			padding-right: 5px;
			display: inline-block;
			vertical-align: middle;
			transform: translateY(-1px);
		}
		&:hover {
			text-decoration: none;
		}
	}
	.list .gift-card-remove {
		display: block;

	}
	.add-gift-card-form{
		border:0;
		margin: 7px 0 0 !important;
		padding: 10px 12px 13px;
	}
	.gift-card-remove-another{
		text-transform: uppercase;
		margin: 0 0 0 25px;
		position: relative;
	}
}
