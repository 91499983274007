$red: red;
$border-color-focus: #aaa;

%input{
	border: solid 1px $border-color;
	border-radius: 0;
	height: 30px;
	line-height: 30px;
	padding: 0 6px;
	color: $text-color;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
	font-size: 12px;

	&.error {
		border: solid 1px $red !important;
	}

	&:focus{
		border-color: $border-color-focus;
		outline: none;
	}
}
.validate-target{
	padding: 10px 10px 0 10px;

	&.validate-error{
		padding: 10px;
		background-color: #fad6d4;
		.checkbox-css i{
			border: 1px solid $red;
		}
	}

	input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"] {
		@extend %input;
	}

	textarea {
		border: solid 1px $border-color;
		border-radius: 0;
		padding: 6px;
		color: $text-color;
		box-sizing: border-box;
		&.error {
			border: solid 1px $red !important;
		}
		&:focus{
			border-color: $border-color-focus;
			outline: none;
		}
	}

	/*.all-errors {
		label.error {
			margin-top: 2px;
		}
	}*/

	&.radio-group label.error,
	input + label.error,
	select + label.error,
	.all-errors label.error,
	textarea + label.error,
	.radio-group + label.error,
	input[type="hidden"] ~ label.error,
	.radio-css+label.error,
	.checkbox-css label.error,
	.radio-css label.error,
	&.validate-error label.error {
		color: $red !important;
		font-size: 12px;
		display: block;
		font-weight: bold;
	}

	.radio-group + label.error,
	.all-errors label.error,
	textarea + label.error,
	.checkbox-css label.error,
	.radio-css label.error {
		display: block;
	}

	/*.required-fields {
		font-size: 13px;
		white-space: nowrap;
		font-weight: normal;
		color: $text-color-lighter;
		em {
			color: $red;
		}
	}*/

}
.row-textarea{
	.char-counter{
		float: right;
		padding-right: 10px;
		font-size: 12px;
		line-height: 16px;
		color: #767676;
	}
}
.remodal.limited-service-modal {
	form {
		em {
			color: $red;
		}

		.row {
			width: 100%;
			padding: 0 0 15px 0;

			&:last-child{
				padding: 0;
			}

			.column{
				display: inline-block;
				vertical-align: top;
				padding-right: 15px;

				&.validate-target{
					padding: 10px;
				}

				&:last-child{
					padding-right: 0;
				}
			}
		}
	}
}
