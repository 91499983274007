$lettersSlideMax:11;
$lettersSPagesMax:3;

@if($app-euro){
	#definitions-container {
		width: 920px;
		padding: 0 10px 0 20px;
		box-sizing: border-box;
		background-color: #fff;
		float: left;
	}
	@media all and (max-width: #{$breakpoint-large}px) {
		#definitions-container {
			width: 680px;
		}
	}
}
@if($app-ole){
	#definitions-container {
		padding-top: 10px;
	}
}

#definitions {
	.definitions-header {
		padding-bottom:20px;
		h1 {
			margin:0 0 10px 0;
			font-size:25px;
		}
		p {
			margin:0 0 10px 0;
			font-size:20px;
			font-weight: bold;
		}
	}
	.definitions-intro {
		font-size: 14px;
	}
	h3 {
		font-size:16px;
	}
	#definitions-search-form {
		label {
			display:block;
			margin-bottom:10px;
			font-weight:bold;
		}
		.form-elements {
			overflow: hidden;
		}
		.input {
			float: left;
		}
		.submit {
			width:129px;
			float: left;
			button {
				margin-left:20px;
				padding:5px 15px 4px;
				@extend %btn-primary;
				&:after {
					@extend %icon-search;
					font-size: 20px;
				}
			}
		}
		.input {
			width: 50%;
			input[name=keyword] {
				width:100%;
				border: 1px solid $border-color;
				padding: 5px;
				font-size: 15px;
			}
		}
	}
	.letter-header {
		margin: 15px 0;
		font-weight: bold;
		font-size: 16px;
		padding: 0;
	}
	#definitions-letters {
		border-top:1px solid $border-color;
		border-bottom:1px solid $border-color;
		height:40px;

		margin-bottom:20px;
		width:100%;
		position:relative;
		.next, .prev, li a, li span{
			border-right: 1px solid $border-color;
			line-height:39px;
			font-size:15px;
			text-align:center;
			font-weight:bold;
			display:block;
			background:#ffffff;
			color:$text-color;
			box-sizing:border-box;
		}
		li:first-child{
			> a{
				border-left: 1px solid $border-color;
			}
		}
		li span{
			color:$text-color-lighter;
		}
		li a{
			&:hover {
				text-decoration:none;
				background:#E6E8EF;
			}
			&.active {
				background:#005ca9;
				color:#fff;
			}
		}
		.list{
			position:relative;
		}
		.next, .prev{
			position:absolute;
			z-index:1;
			top:0;
			width:(50%/$lettersSlideMax);
			background: #005ca9;
			overflow:hidden;
			color: #fff;
		}
		.next{
			right:0;
			&::after {
				@extend %icon-arrow;
				font-size: 20px;
				margin-top: -4px;
			}
		}
		.prev{
			left:0;
			&::after {
				@extend %icon-arrow;
				transform: rotate(180deg);
				font-size: 20px;
			}
		}
		&.letters-slide-last .next, &.letters-slide-0 .prev{
			display:none;
		}
		overflow:hidden;
		padding-left:(50%/$lettersSlideMax);
		padding-right:(50%/$lettersSlideMax);
		box-sizing: border-box;
		&.letters-slide-0{
			padding-left:0;
		}
		&.letters-slide-last{
			padding-right:0;
		}
		ul {
			margin:0;
			position:absolute;
			top:0;
			left:0;
			padding:0;
			list-style:none;
			transition:all 0.2s;
			width:($lettersSPagesMax*100%);
		}
		@for $i from 1 through $lettersSPagesMax {
			&.letters-slide-#{$i} ul {
				left: -100% * $i;
			}
		}
		li {
			float:left;
			width:(100%/$lettersSlideMax/$lettersSPagesMax);
		}
	}

	#definitions-lists {
		.list {
			border-bottom: solid 1px $border-color;
		}
		.definition {
			clear:both;
			> a {
				border-top: solid 1px $border-color;
				height:42px;
				line-height: 42px;
				display:block;
				overflow:hidden;
				text-overflow: ellipsis;
				padding:0 60px 0 10px;
				white-space:nowrap;
				color:$text-color;
				font-weight: bold;
				position:relative;
				font-size: 14px;
				&:hover {
					text-decoration: none;
					background:#FCFBF7;
				}
				&::after {
					@extend %icon-arrow;
					position: absolute;
					top: 13px;
					right: 20px;
					font-size: 20px;
				}
			}
		}
	}
}
