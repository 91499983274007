#login-modal {
	max-width: 500px;
	padding: 0;

	h2 {
		padding: 15px 30px 0;
		font-size: 24px;
	}

	.password-toggle {
		top: -21px;
		right: 0;
	}

	.error-box {
		padding: 10px 10px 0 10px;
		&:before {
			margin: 5px 0;
			background-color: #fad6d4;
			opacity: 1;
		}
	}
}

#loginView-register {
	width: 475px;

	.statusError, .statusOk {
        margin-left: 0;
    }
}

#login-layer {
	margin-top: 10px;

	.buttons {
		text-align: center;
	}

	.header {
		padding: 0 30px;

		h3 {
			padding: 0;
			font-size: 14px;
		}

		.welcomeSubMessageForUnloggedUser {
			margin: 10px 0;
			font-size: 12px;
		}
	}

	.tabs {
		padding: 0 20px;
	}

	.row {
		text-align: left;
		display: inline-block;
		width: 100%;
		box-sizing: border-box;

		&.row-input {
			label.error {
				padding-left:102px;
			}
			>label:not(.error) {
				display: inline-block;
				width: 100px;
				font-size: 14px;
			}
		}

		input[type=email],
		input[type=password],
		input[type=text] {
			width: 285px;
		}
	}

	.tab-login,
	.tab-register,
	.tab-remind {
		margin-bottom: 30px;
	}

	.errorText {
		white-space: normal;	//na rejestracji potafi wyjść poza warstwę
		line-height: 30px;
		color: red;
		text-align: center;
		font-weight: bold;
		span {
			display: none;
		}
	}

	.header,
	.tab {
		display: none;
	}

	&.login-mode {
		.header-login,
		.tab-login {
			display: block;
		}

		.menu-register,
		.menu-remind {
			display: none;
		}
	}

	&.register-mode {
		.header-register,
		.tab-register {
			display: block;
		}

		.header-register p {
			max-width: 400px;
		}

		.menu-login,
		.menu-remind {
			display: none;
		}
	}

	&.remind-mode {
		.header-remind,
		.tab-remind {
			display: block;
		}

		.menu-login,
		.menu-register {
			display: none;
		}
	}

	&.success {
		.header,
		.tabs {
			display: none;
		}

		.header-success {
			margin-bottom: 20px;
			display: block;
		}
	}

	.cancel,
	.to-remind {
		font-size: 12px;
		float: right;
		margin: 10px 15px 0 0;

		&:after {
			content: "";
			display: inline-block;
			height: 40px;
			vertical-align: middle;
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	.btn-login,
	.btn-register,
	.btn-remind {
		margin: 10px 0 0 113px;
		font-size: 16px;
		letter-spacing: -0.03em;
		padding-left: 40px;
		padding-right: 40px;
		@extend %btn-primary;
	}

	.menu {
		padding-left: 10px;
		background: $border-color;
		border-top: 1px solid $border-color;
		height: 40px;
		line-height: 40px;
		font-size: 16px;
		text-align: left;
	}

	.check {
		@extend %btn-small;
		margin-top: 10px;
		margin-left: 102px;
	}

	.terms-text {
		vertical-align: super;
	}

	label.error {
		display: block;
		margin-top: 4px;
	}

	.permissions {
		padding-top: 10px;
		text-align: left;
		.permissions-header{
			font-weight: bold;
		}
	}
	.permissions-disclaimer{
		padding-top: 20px;
	}

	.regulation {
		text-align: left;
	}

	.login-error {
		color: $red;
		font-size: 12px;
		padding-bottom: 10px;
	}
}

#nick-status {
	float: right;
	font-size: 12px;
	vertical-align: middle;
	width: 130px;
	margin-top: 10px;
	text-align: right;
	line-height: 20px;
	&::before {
		margin-right:3px;
		font-size:10px;
		font-weight: bold;
	}
	&.valid {
		&::before {
			@extend %icon-check;
			color:#00b162;
		}
	}

	&.error {
		color: red;
		&::before {
			@extend %icon-close;
			color:red;
		}
	}
}

#remove-comment-modal {
	padding: 30px;
	font-size: 14px;

	h2 {
		margin-bottom: 10px;
		font-size: 24px;
	}

	.buttons {
		margin-top: 10px;
		text-align: center;

		.button-yes {
			margin-right: 10px;
		}
	}
}

#nick-modal {
	max-width: 350px;
	padding: 20px;

	h2 {
		padding: 10px;
		font-size: 24px;
	}

	#nickView-form > p {
		margin: 10px 0;
		font-size: 12px;
		padding: 0 10px;
	}

	#nick-status {
		width: 130px;
		background-position: 0 center;
		text-align: right;
		display: none;
	}

	.nickView-label {
		display: inline-block;
		width: 86px;
		font-size: 14px;
	}

	#login-layer .row input[type=email],
	#login-layer .row input[type=password],
	input[type=text] {
		width: 200px;
	}

	.save-button {
		padding: 0 10px;
	}

	.btn-save {
		width: 100%;
	}

	.check {
		margin-top: 10px;
		width: 100%;
		box-sizing: border-box;
	}

	.error,
	.valid {
		margin: 5px 0;
		text-align: center;
	}

	.check-nick {
		padding-bottom: 10px;
	}
}

#notice-modal {
	max-width: 530px;
	padding: 30px 20px;
	font-size: 14px;

	.notice-message {
		background: #fffcc5;
		border: 1px solid $border-color;
		padding: 15px;
		margin: 0 10px;
	}

	h2 {
		margin-bottom: 10px;
		font-size: 24px;
		padding: 0 10px;
	}

	p {
		margin-top: 10px;
		padding: 0 10px;
	}

	.notice-label {
		display: inline-block;
		width: 85px;
		font-size: 14px;
	}

	#login-layer .row input[type=email],
	#login-layer .row input[type=password],
	input[type=text] {
		width: 200px;
	}

	.buttons {
		margin-top: 10px;
		text-align: center;
	}

	.input-row {
		text-align: center;
	}

	.save-notice {
		@extend %btn-third;
	}
	.permissions-desc {
		padding-top: 10px;
		color: #666666;
		text-align: left;
		font-size: 10px;
		line-height: 12px;
	}
}