#brand {
	#node-tree {
		border-top: 0;
		em {
			float: none;
		}
	}
	.photo-hover {
		display: block;
		position: relative;
	}
	.brand-view-header {
		float: left;
		width: 100%;
		h1 {
			font-size: 24px;
			font-weight: bold;
			color: #1C1C1B;
			margin: 15px 0 25px 0;
			&:after{
				content: attr(data-count);
				margin-left:3px;
			}
		}
	}
	.brand-view-sidebar {
		float: left;
		width: 300px;
	}
	.brand-view-content {
		float: right;
		width: calc(100% - 300px);
		max-width: 870px;
	}
	.brand-banner {
		float: left;
		overflow: hidden;
		width: 100%;
		position: relative;
		margin-bottom: 40px;
		&:before {
			display: block;
			content: "";
			width: 100%;
			padding-top: (120 / 870) * 100%;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
	.brand-footer-description, .brand-header-description{
		font-size:13px;
	}
	.brand-footer-description{
		padding-bottom:20px;
	}
	.section {
		counter-reset: my-counter;
		margin-bottom: 20px;
		overflow: hidden;
		padding: 10px 0 30px 2px;
		margin:0 0 0 -2px;
	}
	.section-header {
		box-sizing: border-box;
		@if($app-euro){
			background: #ffe743;
		}@else{
			background: #EBF8FE;
		}
		padding:45px 15px 15px;
		height:225px;
		border-radius: 4px;
		overflow: hidden;
		@media all and (max-width: #{$breakpoint-large}px) {
			height:185px;
			padding-top:30px;
		}
		span{
			color:$text-color-lighter;
		}
	}
	.section-description{
		padding:15px 0;
		clear: both;
		font-size:13px;
		border-bottom: 1px solid #e6e6e6;
	}
	.section-title {
		font-size: 17px;
		font-weight: bold;
		margin-bottom: 10px;
		@media all and (max-width: #{$breakpoint-large}px) {
			font-size: 15px;
		}
	}
	.section-subtitle {
		font-size: 13px;
		font-weight: bold;
		padding:0 0 15px;
	}
	.section-column {
		box-sizing: border-box;
		float: left;
		width: 20%;
	}
	.see-more {
		text-transform: uppercase;
		color:$link-color;
		display: inline-block;
		margin-top:10px;
		@media all and (max-width: #{$breakpoint-large}px) {
			margin-top:0;
		}
		font-weight: bold;
		&:after{
			@extend %icon-arrow;
			font-size:12px;
			margin:-2px 0 0 5px;
		}
	}
	.product-image {
		padding: 8px;
		border: 2px solid transparent;
		position: relative;
		img {
			display: block;
			width: 100%;
			max-width: 120px;
		}
	}

	%important-product {
		border-style: solid;
		border-width: 2px;
		&:after {
			text-transform: uppercase;
			position: absolute;
			top: -9px;
			left: 8px;
			background: #fff;
			padding: 0 7px;
			font-weight: bold;
			font-size: 12px;
		}

	}

	.product-top-list {
		@if not ($app-euro) {
			@extend %important-product;
			border-color:#45abe5;
			&:after {
				content: "Bestseller";
				color: #45abe5;
			}
		}
	}
	.product-advance-sale {
		@extend %important-product;
		border-color: $advance-sale-color;
			&:after {
				content: "Przedsprzeda\017c";
				color: $advance-sale-color;
			}
	}
	.product-item {
		box-sizing: border-box;
		padding: 15px;
		height:225px;
		@media all and (max-width: #{$breakpoint-large}px) {
			height:185px;
		}
		overflow: hidden;
		border: 1px solid #eee;
		border-radius: 2px;
		margin: 0 10px 0 0;
		position: relative;
		&:before{
			position: absolute;
			top:0;
			left:0;
			width:25px;
			height:25px;
			line-height: 25px;
			background: #fff;
			border-right:1px solid #eee;
			border-bottom:1px solid #eee;
			z-index: 1;
			color:$text-color-lighter;
			text-align: center;
			content: counter(my-counter);
			counter-increment: my-counter;
			font-size:13px;
			font-weight: bold;
		}
		&:hover{
			box-shadow: 0 0 3px #bbb;
		}
	}
	.product-title {
		margin-top: 10px;
		max-height: 48px;
		overflow: hidden;
		a, a:hover {
			font-size: 12px;
			font-weight: bold;
			color: $text-color;
			text-decoration: none;
		}
	}
	.product-category {
		font-size: 12px;
		max-height: 15px;
		overflow: hidden;
		a {
			color: #999;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.section-carousel{
		.header{
			font-size: 21px;
			font-weight: bold;
			padding:0 0 20px 0;
		}
		.articles-carousel {
			position: relative;
			width:870px;
			margin-left:-7px;
		}
		.next {
			transform: translateX(0px) translateY(-50%);
		}
		.prev{
			transform: translateX(8px) translateY(-50%);
		}
		.articles-list {
			overflow: hidden;
			height: 100%;
			min-height: 222px;
			position: relative;
			padding: 3px;

			> div {
				transition: 0.3s;
				will-change: transform;
				display: flex;
				height: calc(100% - 3px);
			}
		}
		.article-item{
			a{
				border:1px solid #ddd;
				margin:0 5px;
				width:208px;
				display: inline-block;
				&:hover{
					box-shadow: 0 0 3px #bbb;
					text-decoration: none;
				}
			}
			.article-photo{}
			img{
				width:208px;
				height:128px;
			}
			.article-content{
				font-weight: bold;
				font-size: 14px;
				padding:10px;
				color:$text-color;
				height:33px;
				overflow:hidden;
				line-height: 1em;
			}
			.article-link{
				text-transform: uppercase;
				color:$link-color;
				padding: 5px 10px 14px;
				font-size:11px;
				font-weight: bold;
				&:after{
					@extend %icon-arrow;
					margin-left:5px;
				}
			}

		}

	}
}
