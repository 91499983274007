@import "../common/general/mixins-buttons";

@mixin grey-header() {
	background: linear-gradient(to right,  #ffffff 0%,#e2e2e2 100%);
}

%input{
	display:inline-block;
	height:20px;
	line-height:20px;
	border:1px solid #ccc;
	border-radius: 0;
	padding-left: 5px;
	color:#333;
	@if($app-euro){
		font-size:12px;
	}@else{
		font-size:11px;
	}
	box-shadow: none;
	box-sizing: border-box;
}

@mixin arrow($typ:'right'){
	position:relative;
	margin-right:9px;
	&::after{
		content:"";
		position:absolute;
		top:4px;
		width: 0;
		height: 0;
		border-style: solid;
		@if $typ=='up' {
			right:-9px;
			border-width: 0 3px 4px 3px;
			border-color: transparent transparent $link-color transparent ;
		}
		@if $typ=='down' {
			right:-9px;
			border-width: 4px 3px 0 3px;
			border-color: $link-color transparent transparent transparent ;
		}
		@if $typ=='left' {
			right:-6px;
			border-width: 3px 4px 3px 0;
			border-color: transparent $link-color transparent transparent ;
		}
		@if $typ=='right' {
			right:-6px;
			border-width: 3px 0 3px 4px;
			border-color: transparent transparent transparent $link-color;
		}
	}
}
@mixin placeholder(){
	input::-webkit-input-placeholder { font-style:italic; }
	input::-moz-placeholder { font-style:italic; } /* firefox 19+ */
	input:-ms-input-placeholder { font-style:italic; } /* ie */
	input:-moz-placeholder { font-style:italic; }
}
@mixin clearfix() {
	@extend %clearfix;
}
%clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

%price-instalment-i{
	display: table;
	font-style: normal;
	font-size:10px;
	font-weight: normal;
}
%price-instalments-box{
	.price-instalments-row{
		display: flex;
	}
	.price-instalment-voucher-info, .price-instalment-shop-only-info{
		font-size: 10px;
		color:#999999;
		margin-top:4px;
		a{
			color:#999999;
			&:before{
				font-size: 14px;
				margin:-4px 0 0 2px;
				@extend %icon-delivery-info;
			}
		}
	}
	border: 1px solid #cccccc;
	font-size: 12px;
	margin-top:5px;
	padding: 6px 6px 6px 12px;

	.price-instalments {
		display: inline-block;
		position: relative;
		padding-right:10px;
		width:140px;
		overflow: hidden;
		font-size:14px;
		white-space: nowrap;
		&:hover {
			text-decoration: none;
			border-bottom-color: $link-color;
		}
		i{
			@extend %price-instalment-i;
		}
		.constant-instalment-price {
			display: flex;

			span {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	a.price-instalments:after{
		@extend %icon-arrow-down;
		font-size:12px;
		position: absolute;
		top:50%;
		right: 0;
		margin-top:-5px;
	}
	.price-instalments-label {
		color: #666;
		font-weight: bold;
		font-size:14px;
		padding-right: 5px;
	}
}
@mixin box-shadow($horizontal:$default-box-shadow-horizontal, $vertical:$default-box-shadow-vertical, $blur:$default-box-shadow-blur, $color:$default-box-shadow-color, $inset: false) {
	@if $inset {
		box-shadow:inset $horizontal $vertical $blur $color;
	} @else {
		box-shadow: $horizontal $vertical $blur $color;
	}
}

@mixin default-box-shadow() {
	box-shadow: $default-box-shadow-horizontal $default-box-shadow-vertical $default-box-shadow-blur $default-box-shadow-color;
}
@mixin default-border-radius($overflow: hidden) {
	border-radius: $default-box-border-radius;
	overflow:$overflow;
}
@mixin loader-icon($size, $border:8px){
	content:'';
	position:absolute;
	top:50%;
	left:50%;
	margin-top:-($size/2);
	margin-left:-($size/2);
	height:$size;
	width:$size;
	z-index:7;
	animation:rotate360 0.8s infinite linear;
	border:min($size/4, $border) solid $loader-color;
	border-right-color:transparent;
	border-radius:50%;
	box-sizing: border-box;
	@keyframes rotate360{
		0%{transform:rotate(0deg)}
		100%{transform:rotate(360deg)}
	}
}
@mixin loader($size: 10px, $border: 8px) {
	position:relative;
	z-index:1;
	min-height:$size;
	&::after{
		@include loader-icon($size, $border);
	}
	&::before{
		position:absolute;
		content:'';
		top:0;
		left:0;
		width:100%;
		z-index:7;
		height:100%;
		background:#fff;
		opacity:0.6;
	}
}
@mixin line-clamp($lines: 3) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
%loading{
	@include loader(44px)
}

%loading-icon{
	@include loader-icon(44px);
}

%loading-small{
	@include loader(16px)
}

%center-v {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

%center-h {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

%center-both {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

%promotion-price {
	margin-bottom: 10px;
	color: #e30613;
	font-weight: bold;
	font-size: 14px;

	&:before {
		content: "%";
		color: #fff;
		width: 20px;
		height: 20px;
		text-align: center;
		display: inline-block;
		line-height: 20px;
		background: #e30613;
		border-radius: 4px;
		margin-right: 2px;
	}
}

%article-list{
	ul{
		list-style:none;
		margin:10px 0;
		li{
			position:relative;
			padding:0 0 3px 20px;
			&:before{
				content:"";
				border-radius:50%;
				position:absolute;
				top:1em;
				left:5px;
				display:block;
				width:5px;
				height:5px;
				background: #333;
				transform: translateY(calc((50% + 2px) * -1));
			}
		}
	}
}
%article-wysiwyg-styles {
	/* poniższe style przeniesione z widoku edycji artykułów dla wysiwyga dla konsola/editorArticleNew.css */
	span {
		white-space: normal !important;
	}
	h2 {
		margin: 0;
		padding: 16px 0;
		font-family: 'Lato', sans-serif;
		font-weight: 700;
		font-size: 28px;
		line-height: 36px;
		color: #0A0A0A;
	}
	h3 {
		margin: 0;
		padding: 8px 0 16px;
		font-family: 'Lato', sans-serif;
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		color: #0A0A0A;
	}
	ul{
		margin: 0;
		padding: 0 0 16px 0;
		li{
			position:relative;
			font-family: 'Lato', sans-serif;
			list-style-type: disc;
			font-size: 18px;
			line-height: 27px;
			background: none;
			margin: 0 0 0 27px;
			padding: 0;
		}
	}
	ol{
		
		margin: 0;
		padding: 0 0 16px 0;
		li{
			position:relative;
			font-family: 'Lato', sans-serif;
			font-size: 18px;
			line-height: 27px;
			background: none;
			margin: 0 0 0 27px;
			padding: 0;
		}
	}
	p {
		margin: 0;
		padding: 0 0 16px 0;
		font-family: 'Lato', sans-serif;
		font-size: 18px;
		line-height: 27px;
		color: #0A0A0A;
	}
	a {
		color: #016EF4; font-size: inherit; line-height: inherit; text-decoration: none;
	}
}

%activation-voucher {
	background: #F7F6FF;
	color: #473DB2;
	padding: 5px;
	margin-top: 8px;

	ul {
		margin-top: 8px;
	}

	li {
		list-style: initial;
		padding-left: 7px;
		margin-left: 14px;
		&:not(:last-child) {
			margin-bottom: 7px;
		}
	}
}