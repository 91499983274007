#empty-search-results {

	#breadcrumb {
		padding-left: 0;
	}

	.page {
		overflow: initial; // żeby nie ucinało strzałek na karuzeli
	}

	.empty-search-header {
		margin-left: 10px;
		margin-right: 10px;
	}

	.page-header {
		font-size: 2rem;
		margin-top: .5rem;
		margin-bottom: 2rem;

		span {
			color: #473DB2;
		}
	}

	.section-heading {
		background: #F7F7F7;
		font-size: 20px;
		font-weight: bold;
		border-radius: 7px;
		padding: 10px 15px;
		margin-bottom: 1.5rem;
		display: flex;
		justify-content: space-between;
	}

	.page-section {
		margin-bottom: 3rem;

		&.loading {
			text-align: center;
			@extend %loading;

			> * {
				display: none;
			}
		}

		&.is-hidden {
			display: none;
		}
	}
}

#suggested-products {

	.clear-suggested-products {
		padding: 0;
		@extend %btn-link;
		text-transform: initial;
		font-weight: normal;
		font-size: 14px;
	}
}

#contact-form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	scroll-margin-top: 150px;

	label:first-child {
		font-size: 14px;
		margin-bottom: 10px;
		display: block;
	}

	.send-contact-form {
		@extend %btn-third;
		font-size: 14px;
		padding: 5px 15px;
		border-radius: 4px;
		margin: 10px;
	}
}