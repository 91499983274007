#dictionary-modal, #definition-item {
	width: 710px;
	max-width: 710px;
	padding: 26px 30px;
	.report-technical-bug{
		text-transform: uppercase;
		font-size:11px;
		font-weight: normal;
		margin-left:10px;
		&:before{
			content: 'Zg\0142 o\015B  b\0142 \0105 d'
		}
	}
	.remodal-close {
		right: 10px;
		top: 10px;
	}

	.title {
		font-size: 23px;
		font-weight: bold;
		line-height: 23px;
	}

	.subsection-title {
		font-weight: bold;
		display: inline;
	}

	.dictionary-content {
		width: 100%;
		font-size: 12px;

		.subsection {
			padding-top: 12px;
			line-height: 1.7;
		}

		.definition, .connections {
			padding-top: 20px;
		}

		.more-definition {
			text-transform: uppercase;
			font-size: 10px;
			margin-top: 12px;
			display: block;
			font-weight: bold;
		}
	}

	.definition.read-more {
		.long-definition {
			display: none;
		}
	}

	&.show-long-description {
		.definition.read-more {
			.short-definition, .more-definition {
				display: none;
			}

			.long-definition {
				display: block;
			}
		}
	}

	#dictionary-actions {
		border-top: 1px solid #cacaca;
		padding-top: 15px;
		margin: 25px -30px 0;
		position: relative;

		&.voted-up {
			.rate-label {
				display: none;
			}

			.rate-label-up {
				display: block;
			}

			[class*='rate-button-'] {
				display: none;
			}
		}

		&.voted-edit {
			#rate-box {
				display: none;
			}
			#dictionary-edit-form {
				height: auto;
			}
		}

		&.voted-down {

			#rate-box {
				display: block;
			}
			#dictionary-edit-form {
				height: 0px;
			}

			.rate-label {
				display: none;
			}

			.rate-label-down {
				display: block;
			}

			[class*='rate-button-'] {
				display: none;
			}
		}

		&.voted-before {
			.rate-label {
				display: none;
			}

			.rate-label-voted {
				display: block;
			}

			[class*='rate-button-'] {
				display: none;
			}
		}

		#add-dictionary-definition{
			padding:10px 0 5px 0;
			display:inline-block;
			&:hover {
				text-decoration: none;
			}
		}

	}

	#rate-box {
		display: block;
		font-weight: bold;
		margin: 0 30px;

		[class*='rate-label-'] {
			display: none;
			line-height: 29px;
		}

		[class*='rate-button-'] {

			padding: 0;
			margin-left: 10px;

			&::before {
				font-size: 17px;
				margin-right: 5px;
				padding: 6px;
				border-radius: 50%;
			}
		}

		.rate-button-up {

			color: #00b162;

			&::before {
				@extend %icon-finger-up;
				background-color: #e5f7ef;
			}
		}

		.rate-button-down {

			color: #e63327;

			&::before {
				@extend %icon-finger-down;
				background-color: #fceae9;
			}
		}
	}

	#dictionary-edit-form {
		height: 0;
		transition: height 0.5s;
		overflow: hidden;

		.validate-target {
			margin: 0 15px 5px;
			height: 80px;
		}

		.btn-send {
			float: left;
			clear: left;
			margin-left: 25px;

			@extend %btn-medium;
			@extend %btn-third;
		}

		.btn-cancel {
			float: left;
			margin-left: 10px;
			text-transform: uppercase;
			font-weight: bold;

			@extend %btn-medium;
			@extend %btn-link;


			&:hover {
				color: $link-color-hover;
			}
		}
		#definition-content-captcha {
			height: auto;
		}
	}

	#dictionary-edit-message {
		height: 70px;
		width: 100%;
		margin: 0;
	}

	.report-bug {
		position: absolute;
		right: 25px;
		bottom: 1px;

		&:before {
			@extend %icon-error;
		}
	}

	&.in-progress {
		@extend %loading;
	}
}
