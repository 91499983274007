@if(not $app-katalog) {
.sticky-off {
	#top{
		position:static;
	}
	#top-bar{
		position:static;
		margin:0;
	}
}

.sticky #top:not(.top2) {
	position:fixed;
	backface-visibility: hidden;
	top:0;
	left:0;
	z-index:5;//ze względu na wchodzenie pod menu wyszukiwarke itd...
	width:100vw;
	height:auto;

	#header{
		height: 64px;
	}
	#favorite-box {
		@if($app-euro){
			right: 49px;
		} @else {
			right: 49px;
		}
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 31px;
		}
	}
	#contact-box {
		@if($app-euro){
			right: 96px;
		} @else {
			right: 93px;
		}
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 65px;
		}
	}
	#shops-box {
		right: 143px;
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 102px;
		}
	}
	
	#cart-box, #favorite-box, #contact-box, #shops-box {
		top: 18px;
		width: 46px;
		font-size: 0;
		@media all and (max-width: #{$breakpoint-large}px) {
			top: 20px;
		}
	}
	#cart-box > a::after{
		display:none;
	}
		
	
	#search-box {
		top:14px;
		@if($app-ole) {
			left:162px;
		}
		.input-wrap {
			@if($app-ole) {
				width: 603px;
				@media all and (min-width: 1440px) {
					width: 763px;
				}
				@media all and (max-width: #{$breakpoint-large}px) {
					width: 363px;
				}
			} @else {
				width: 370px;
				@media all and (min-width: 1440px) {
					width: 536px;
				}
				@media all and (max-width: #{$breakpoint-large}px) {
					width: 266px;
				}
			}
		}
		
	}
	#cart-box {
		top: 9px;
		right: 0;
		> a::before{
			margin-top: 16px;
		}
		> a > span{
			top: -10px;
			right:-4px;
		}
		@media all and (max-width: #{$breakpoint-large}px) {
			top: 10px;
			right: -5px;
		}
	}

	#logo{
		@if($app-euro) {
			top:9px;
		}@else{
			top:4px;
			width: 96px;
			height: 56px;
		}
	}
	#account-box{
		top: 16px;
		@if($app-euro) {
			right: 198px;
			@media all and (max-width: #{$breakpoint-large}px) {
				right: 156px;
			}
		}@else{
			right: 150px;
			@media all and (max-width: #{$breakpoint-large}px) {
				right: 120px;
			}
		}
		& > a {
			padding: 0px 16px 0px 41px;
			&::before {
				top: 0px;
			}
		}
	}
	
	#cart-items{
		top:44px;
	}
	#account-menu{
		top:29px;
	}
	.links, #services-bar{
		display:none;
	}
}
.sticky{
	&.operator-logged, &.operator-reserve-bar{
		&.product-menu-fixed #menu-product{
			top:110px;
		}
	}
}

.sticky-menu-collapsed #top:not(.top2) {
	#header {
		transition: padding 0.2s ease-in-out;
		padding: 0;
	}
}

.operator-reserve-bar:not(.sticky) #top {
	padding-top: 40px;
}

}