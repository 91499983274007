/* rozdzielenie css dla starych i nowych checkboxów */
.filter .checkbox-css.theme2 {
	position: absolute;
	left: 5px;
}

.checkbox-css:not(.theme2) {
	display: inline-block;
	vertical-align: middle;
	float:left;			/* poprzednie checkboxy tak miały i dla kompatybilności tak powinny pozosać*/
}

.checkbox-css {
	position: relative;
	i {
		box-sizing: border-box;
		position:relative;
		display: inline-block;
		width  : 17px;
		height : 17px;
		border: 1px solid $border-color;
		background: #fff;
		overflow: hidden;
		cursor: pointer;
		text-align:center;
		line-height:12px;
	}
	.text{
		vertical-align: top;
		display: inline-block;
		position: relative;
		min-height: 17px;
		line-height: 17px;
		padding-left: 27px;
		i {
			position: absolute;
			top:0;
			left:0;
		}
		em{
			color:red;
			position: absolute;
			top:0;
			left:20px;
		}
		&.long{
			.long-text{
				display:block;
			}
			.short-text{
				display:none;
			}
		}
	}
	.long-text{
		display:none;
	}
	.long-text, .short-text{
		a{
			font-weight:normal;
			margin:0 3px;
			display:inline-block;
			font-size:12px;
		}
	}
	.toggle-text::after {
		content: attr(data-unchecked);
		vertical-align: top;
		@if($app-katalog){
			color:#fff;
		}
	}
	input[type="checkbox"]:checked + i + .toggle-text::after {
		content: attr(data-checked);
	}
	input[type="checkbox"] {
		display:none;
	}
	input[type="checkbox"]:checked + i {
		&::after{
			@extend %icon-check;
			font-size:11px;
			color:$checkbox-color;
		}
	}
	input[type="checkbox"]:disabled + i {
		opacity: 0.3;
		cursor:not-allowed;
	}
	input[type="checkbox"]:disabled:checked + i {
		opacity: 0.5;
		border: 1px solid $border-color;
	}
	&:hover input[type="checkbox"] + i {
		opacity: 0.8;
	}
	&:hover input:not(:disabled) + i{
		border: 1px solid $link-color-hover;
	}
	&.checkbox-css-text.button {
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		cursor: pointer;
		&:hover input[type="checkbox"]:not(:disabled) {
			& + i {
				opacity: 1;
				border-color: #3c3b3b;
			}
			& ~ .toggle-text {
				&::before {
					background-color:lighten($checkbox-background, 10%);
				}
			}
			&:checked + i{
				background-color: $checkbox-text-color;
			}
			&:checked ~ .toggle-text {
				&::before {
					background-color: lighten($checkbox-active-background, 10%);
				}
			}
		}
		&:hover input:not(:disabled) + i {
			border: 1px solid $border-color;
		}
		i {
			position: absolute;
			top: 7px;
			left: if($app-ole, 12px, 7px);
		}
		.text {
			position: relative;
			display: block;
			.toggle-text {
				color: $checkbox-text-color;
				display: block;
				font-size: 12px;
				padding: 7px 10px 7px 14px;
				&::before {
					content: "";
					background-color: $checkbox-background;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					@if ($app-ole) {
						border-radius: 25px;
					}
				}
			}
		}
		input[type="checkbox"]:checked:not(:disabled) + i {
			&:not(:disabled){
				border-color: $checkbox-active-text-color;
			}
			background-color: $checkbox-check-background;
			&::after {
				color: $checkbox-check-color;
			}
			& + .toggle-text {
				color: $checkbox-active-text-color;
				&::before {
					background-color: $checkbox-active-background;
				}
			}
		}
		input[type="checkbox"]:disabled + i + .toggle-text, input[type="checkbox"]:disabled:hover + i + .toggle-text{
			&::before{
				background-color:#eee;
			}
			pointer-events: none;
			cursor:not-allowed;
			color: #bbb;
		}
	}
}

%newWcCheckbox{
	/* nowe style dla WC*/
	i{
		width: 20px;
		height: 20px;
		margin-right: 20px;
		border-radius: 2px;
		border-width: 2px;
	}
	input[type="checkbox"]:checked + i {
		background: #0876fe;
		border-color:#0876fe;
	}
	input[type="checkbox"]:checked + i::after {
		font-size: 12px;
		line-height: 1.5;
		color: #ffffff;
	}
	&:hover input:not(:disabled) + i {
		border-color: #0876fe;
	}
	.text {
		padding-left: 42px;
		font-size: 15px;
		line-height: 18px;
		font-weight: bold;
	}
}


%radio-css-i{
	width  : 16px;
	height : 16px;
	background: #FFF;
	position: relative;
	font-size: 18px;
	margin: 1px;
	border-radius: 8px;
	&:after {
		@extend %icon-radiobutton-unchecked;
		color: #ccc;
		position:absolute;
		padding: 1px;
		top:-2px;
		left:-2px;
	}
}
%radio-css-checked-i-after{
	@extend %icon-radiobutton-checked;
	@if($app-euro){
		color: $button-second-background;
	}@else{
		color: $button-primary-background;
	}
	vertical-align: top;
}

.radio-css {
	i {
		display: inline-block;
		box-sizing: border-box;
		width  : 17px;
		height : 17px;
		border: 1px solid $border-color;
		background: #fff;
		border-radius:50%;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		text-align:center;
		line-height:12px;
	}
	.toggle-text::after {
		content: attr(data-unchecked);
		vertical-align: top;
	}
	input[type="radio"]:checked + i + .toggle-text::after {
		content: attr(data-checked);
	}
	input[type="radio"] {
		display:none;
	}
	input[type="radio"]:checked + i {
		&::after{
			width:11px;
			height:11px;
			margin:2px 0 0 2px;
			display:block;
			border-radius: 50%;
			background: $checkbox-color;
			content:"";
		}
	}
	input[type="radio"]:disabled + i {
		opacity: 0.3;
		cursor:not-allowed;
	}
	input[type="radio"]:disabled:checked + i {
		opacity: 0.5;
		border: 1px solid $border-color;
	}
	&:hover input[type="radio"] + i {
		opacity: 0.8;
	}
	&:hover input:not(:disabled) + i{
		border: 1px solid $link-color-hover;
	}
	&.radio-css-text.button {
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		cursor: pointer;
		&:hover input[type="checkbox"] {
			& + i {
				opacity: 1;
				border-color: #3c3b3b;
			}
			& ~ .toggle-text {
				&::before {
					background-color: $button-primary-background-hover;
				}
			}
			&:checked + i{
				background-color: transparent;
			}
			&:checked ~ .toggle-text {
				&::before {
					background-color: #4f4e4e;
				}
			}
		}
		&:hover input:not(:disabled) + i {
			border: 1px solid $border-color;
		}
		i {
			position: absolute;
			top: 7px;
			left: 7px;
		}
		.text {
			position: relative;
			display: block;
			.toggle-text {
				color: $checkbox-text-color;
				display: block;
				font-size: 12px;
				padding: 7px 10px 7px 32px;
				&::before {
					content: "";
					background-color: $checkbox-background;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					@if ($app-ole) {
						border-radius: 25px;
					}
				}
			}
		}
		input[type="checkbox"]:checked + i {
			border-color: $checkbox-background;
			background-color: #000;
			&::after {
				color: $checkbox-background;
			}
			& + .toggle-text {
				color: $checkbox-background;
				&::before {
					background-color: $button-primary-background-hover;
				}
			}
		}
	}
	+label,
	+span.pointer {
		line-height: 16px;
		vertical-align: super;
	}
}
