.product-opinion-stars-grades {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 12px;
	.stars-rating {
		position: relative;
		width: 70px;
		height: 14px;
		line-height: 15px;
		margin-right: 4px;
		&::before{
			@extend %icon-stars;
			display: inline-flex;
			font-size: 14px;
			color:silver;
		}
	}
	.stars-rating-progress {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		&::before{
			@extend %icon-stars;
			font-size: 14px;
			color:#FFD800;
		}
	}
	.stars-grade-value{
		font-weight: bold;
		margin-right: 5px;
	}
	.grades-total-count{
		margin-right: 10px;
	}
}