.related-keywords-list {
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;

	li {
		list-style: none;

		&:last-child .related-keyword-link {
			margin-right: 0;
		}
	}

	.related-keyword-link {
		list-style: none;
		display: block;
		padding: 16px;
		border-radius: 4px;
		border: 1px solid #E5E5E5;
		font-size: 14px;
		margin: 0 10px 10px 0;

		span {
			position: relative;
			display: flex;
			padding-left: 20px;
			align-items: center;

			&:before {
				@extend %icon-search;
				position: absolute;
				transform: scaleX(-1);
				left: 0;
			}
		}
	}
}