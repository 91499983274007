#last-search-box {
	display: none;

	&.showed {
		display: block;
	}

	.header {
		background: #e2e2e2 none repeat scroll 0 0;
		height: 38px;
		line-height: 44px;
		text-align: center;
		border: 1px solid #ccc;
		border-bottom: 0;
		font-size: 13px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.list {
		border: 1px solid #ccc;
		background: #fff;
		border-top: 0;
		padding: 10px 0;
	}

	ul {
		margin: 0;
	}

	li {
		a {
			display: block;
			padding: 5px 10px;
			font-size: 12px;

			&:before {
				@extend %icon-arrow;
				font-size: 10px;
				margin-right: 5px;
			}
		}
	}
}
