.product-slider {
	position: relative;
	.product-slider-previous {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -30px;
		cursor: pointer;
		z-index: 2;
		padding: 0;
		width:40px;
		height:60px;
		text-align: center;
		background-color: #fff;
		font-size:30px;
		border: 1px solid $border-color;
		&:after {
			@extend %icon-big-arrow;
			font-weight: bold;
			color: $text-color-lighter;
			transform: scaleX(-1);
			line-height: 60px;
		}
	}
	.product-slider-next {
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -30px;
		cursor: pointer;
		z-index: 2;
		padding: 0;
		width:40px;
		height:60px;
		text-align: center;
		background-color: #fff;
		font-size:30px;
		border: 1px solid $border-color;
		&:after {
			@extend %icon-big-arrow;
			font-weight: bold;
			color: $text-color-lighter;
			line-height: 60px;
		}
	}
	.product-slider-next, .product-slider-previous {
		&:hover {
			border-color: #2b77b8;
			&:after {
				color: #2b77b8;
			}
		}
	}
	.button-disabled {
		pointer-events: none;
		&:after {
			color: #eee;
			border-color: #eee;
		}
	}
	&.button-previous-hidden {
		.product-slider-previous {
			display: none;
		}
		&::before {
			display: none;
		}
	}
	&.button-next-hidden {
		.product-slider-next {
			display: none;
		}
		&::after {
			display: none;
		}
	}
	&::before {
		background: linear-gradient(to right, rgba(255,255,255,1) 40%, rgba(255,255,255,0));
		content: "";
		display: block;
		height: 100%;
		width: 100px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
	}
	&::after {
		background: linear-gradient(to left, rgba(255,255,255,1) 40%, rgba(255,255,255,0));
		content: "";
		display: block;
		height: 100%;
		width: 100px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
}
