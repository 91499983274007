.stars-rating {
	height: 17px;
	vertical-align:middle;
	display: inline-block;
	box-sizing: content-box !important;
	a,>span,>div {
		display: inline-block;
		width: 85px;
		height: 17px;
		position:relative;
		&::before{
			@extend %icon-stars;
			font-size:17px;
			color:#DADADA;
			vertical-align:top;
		}
		span {
			display: block;
			height: 17px;
			overflow:hidden;
			position:absolute;
			top:0;
			left:0;
			&::before{
				vertical-align:top;
				@extend %icon-stars;
				font-size:17px;
				color:#FFD800;
			}
		}
		&:hover {
			background-position: left bottom;
		}
	}
	.rating-count {
		width: auto;
		color: $text-color-lighter;
		vertical-align: top;
		&::before {
			content: none;
		}
	}
	em{
		vertical-align: top;
		font-size:12px;
		margin-left:2px;
		color:$text-color-lighter;
		font-style:normal;
		line-height:17px;
		white-space:nowrap;
	}
}
