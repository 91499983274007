#promotion-package-modal {
	padding: 0 0 20px;
	max-width: 700px;
	text-align: left;
	> h2 {
		padding: 20px 20px 15px;
		font-size: 18px;
		font-weight: bold;
	}
	.empty-promotion-package {
		padding: 20px;
		text-align: center;
		font-size: 16px;
	}
	.promotion-package-elements {
		text-align: left;
		background: #164194;
		padding: 20px 16px;
		ul {
			list-style: none;
			display: flex;
			width: 100%;
			padding: 0;
			margin: 0;
		}
		li {
			background: #fff;
			text-align: center;
			padding: 16px 20px 24px;
			position: relative;
			width: 33%;
			min-width: 27%;
			min-height: 184px;
			* {
				text-align: center;
			}
			margin-right: 4px;
			&:not(:first-child):not(:last-child),
			&:last-child {
				&:after {
					width: 41px;
					height: 41px;
					background: #fff;
					border-radius: 50%;
					border: 4px solid #164194;
					font-size: 32px;
					line-height: 38px;
					text-align: center;
					color: #000;
					position: absolute;
					top: 118px;
					left: -28px;
					margin-top: -31px;
				}
			}
			&:not(:first-child):not(:last-child) {
				&:after {
					content: '+';
				}
			}
			&:last-child {
				margin-right: 0;
				&:after {
					content: '=';
				}
			}
		}

		.product-name,
		.service-header,
		.package-header {
			height: 42px;
			overflow: hidden;
			font-size: 16px;
			&::first-letter {
				text-transform: uppercase;
			}
		}
		.price-rrso{
			font-size: 14px;
		}
		.product-photo,
		.package-photo {
			padding-bottom: 23px;
			img {
				display: block;
				margin: 0 auto;
				width: 102px;
				height: 91px;
			}
		}
		.product-price {
			.price-new {
				display: inline;
				color: #e40414;
				font-weight: bold;
				font-size: 16px;
			}
			/*
			.price-old {
				display: inline;
				margin-left: 6px;
			}*/
		}

		.service-photo {
			padding-bottom: 23px;
			&:before {
				@extend %icon-service1;
				display: block;
				text-align: center;
				font-size: 56px;
				margin: 0 auto;
				color: #767676;
				width: 102px;
				height: 91px;
				line-height: 72px;
			}
		}
		.service-price {
			.price-normal {
				display: inline;
				//color: #e40414;
				font-weight: bold;
				font-size: 16px;
			}
		}
		.service-name {
			font-size: 14px;
			margin-top: 1px;
		}
		.package-photo {
			position: relative;
			&:after {
				@extend %icon-service1;
				font-size: 30px;
				position: absolute;
				bottom: 14px;
				right: calc(50% - 54px);
				color: #767676;
			}
		}
		.package-price {
			.price-new {
				display: inline-block;
				color: #e40414;
				font-weight: bold;
				font-size: 16px;
			}
			.price-old {
				display: inline-block;
				margin-left: 6px;
				font-size: 14px;
			}
		}
		.package-label {
			background: #f50a1b;
			color: #fff;
			padding: 1px 14px 2px;
			border-radius: 20px;
			font-size: 12px;
			font-weight: bold;
			display: inline-block;
			margin-top: 9px;
		}
		h1 {
			color: #ffed00;
			font-weight: bold;
			margin: 29px 0 0;
			font-size: 27px;
			+ h2 {
				margin-top: 16px;
			}
		}
		h2 {
			color: #fff;
			font-weight: bold;
			font-size: 20px;
			margin-top: 19px;
			margin-bottom: 14px;
		}
		&.promotion-package-installment {
			.product-price .price-new,
			.service-price .price-normal,
			.package-price .price-old {
				/*span {
					display: block;
					color: $text-color;
					font-weight: normal;
					text-align: center;
				}*/
			}
			.service-name {
				margin-top: 10px;
			}
			.package-price .price-new,
			.package-price .price-old {
				//display: inline;
				//text-decoration: none;
			}
		}
	}
	.promotion-package-content {
		text-align: left;
		padding: 31px 20px 20px;
	}
	.promotion-related-download {
		margin-top: 10px;

		.download-header {
			font-weight: bold;
			font-size: 14px;
			padding: 0 0 13px;
		}
		a {
		}
	}
	.promotion-package-action {
		text-align: center;
		padding: 0 16px;
	}
	.promotion-package-add {
		@extend %btn-primary;
		cursor: pointer;
		width: 100%;
	}
	.promotion-package-more {
		@extend %btn;
		@extend %btn-fourth;
		margin-top: 10px;
		display: block;
	}
}
