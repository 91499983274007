$previewHeight:590px;
$max-photos:16;

#multimedia-modal {

	width:$breakpoint-large-width+px;
	max-width:$breakpoint-large-width+px;
	padding:0;
	margin:0;
	position:relative;
	transform: none !important;
	@media all and (max-width: #{$breakpoint-large}px) {
		width:1000px;
		max-width:1000px;
	}
	&.type-photo .photo-types, &.type-video .video-types, &.type-presentation .presentation-types{
		background:#FFFBCC;
		&::before{
			color:$text-color !important;
		}
	}
	&.type-photo {
		.video, .presentation, .multimedia-video, .multimedia-presentation{
			display:none !important;
		}
	}
	&.type-video {
		.photo, .presentation, .multimedia-photo, .multimedia-presentation{
			display:none !important;
		}
	}
	&.type-presentation {
		.photo, .video, .multimedia-photo, .multimedia-video{
			display:none !important;
		}
	}
	&.preview-no-carousel{
		#multimedia-preview{
			.next, .prev{
				display:none !important;
			}
		}
	}
	&.preview-carousel{
		&.preview-carousel-first{
			#multimedia-preview{
				.prev{
					display:none !important;
				}
			}
		}
		&.preview-carousel-last{
			#multimedia-preview{
				.next{
					display:none !important;
				}
			}
		}
	}
	&.list-no-carousel{
		#multimedia-lists{
			.next, .prev{
				display:none !important;
			}
		}
	}
	&.list-carousel{
		#multimedia-lists{
			ul{
				position:absolute;
				top:0;
				left:0;
			}
		}
		&.list-carousel-first{
			#multimedia-lists .prev{
				display:none !important;
			}
		}
		&.list-carousel-last{
			#multimedia-lists .next{
				display:none !important;
			}
		}
	}
	&.show-zoom{
		#multimedia-preview{
			display:none;
		}
		#multimedia-zoom{
			display:block;
		}
	}
	@for $i from 0 through $max-photos {
		&.shift-#{$i} {
			#multimedia-lists ul{
				left: 0px - 91px * $i;
			}
		}
	}
}
#multimedia-zoom{
	display:none;
	width:100%;
	position:relative;
	height:$previewHeight;
	line-height: $previewHeight;
	overflow: hidden;
	text-align:center;
	cursor:move;
	img.small-photo{
		width:600px;
		height:532px;
		display:block;
		padding:20px 0;
		margin:0 auto;
		opacity:0.3;
	}
	&::after{
		opacity:0.3;
		@extend %icon-zoom-out;
		font-size:100px;
		color:#fff;
		position:absolute;
		top:50%;
		left:50%;
		margin: -35px 0 0 -35px;
		font-weight:bold;
	}
}
#multimedia-header{
	vertical-align:middle;
	height:50px;
	line-height:47px;
	border-bottom:1px solid $border-color;
	.title{
		width:720px;
		@media all and (max-width: #{$breakpoint-large}px) {
			width:550px;
		}
		margin:0 20px;
		display: inline-block;
		font-size:25px;
		font-weight:bold;
		line-height:50px;
		white-space:nowrap;
		text-overflow: ellipsis;
		overflow:hidden;
		vertical-align: middle;
	}
	button, a{
		vertical-align: middle;
		padding-top:0;
		padding-bottom:0;
		line-height:30px;
		height:auto;
		margin-left:10px;
	}
}
#multimedia-preview{
	width:100%;
	position:relative;
	height:$previewHeight;
	line-height: $previewHeight;
	user-select: none;
	.next, .prev{
		position:absolute;
		top:0;
		height:100%;
		width:68px;
		text-align:center;
		&::after{
			@extend %icon-arrow;
			font-size:71px;
			color:#9A9A9A;
		}
		&:hover{
			opacity:0.7;
		}
	}
	.next{
		right:0;
	}

	.prev{
		left:0;
		&::after{
			transform: rotate(180deg);
		}
	}
	.orbitvu-viewer {
		margin: 0 auto;
	}
	.presentation[data-url] object {
		width: 600px;
		height: 532px;
		margin: 30px auto;
	}
	.presentation.external {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	object{
		pointer-events: auto;
		display:block;
	}
	.list{
		overflow:hidden;
		position:relative;
		width:800px;
		height:100%;
		margin:0 auto;
	}
	ul{
		position:relative;
		top:0;
		width: 100%;
		height: 100%;
		text-align:center;
	}
	li{
		position:absolute;
		box-sizing: border-box;
		width:800px;
		text-align:center;
		display:inline-block;
		height:$previewHeight;
		transition: 0.5s left;
		&.video{

		}
		&.zoom{
			cursor:pointer;
			&::after{
				opacity:0.3;
				@extend %icon-zoom-in;
				font-size:100px;
				color:#fff;
				position:absolute;
				top:50%;
				left:50%;
				margin: -35px 0 0 -35px;
				font-weight:bold;
			}
		}
	}
	.preview-active{
		left:0;
	}
	.preview-left{
		left:-800px;
	}
	.preview-right{
		left:800px;
	}

	iframe{
		margin:0 auto;
		display:block;
		background:#000;
	}
	img{
		width:600px;
		height:532px;
		display:block;
		padding:30px 0;
		margin:0 auto;
	}
	#multimedia-presentation{
		position: relative;
		text-align:center;
		margin:0 auto;
		img {
			width: auto;
			height: auto;
			left:auto;
			right:auto;
			padding:0;
			display: block;
			cursor: col-resize;
			position: absolute;
			opacity: 0;
			transition: opacity 50ms;
			&.active {
				transition: none;
				opacity: 1;
			}
		}
		&.loading{
			@extend %loading;
			img{
				opacity:0;
			}
			.progress{
				display:block;
			}
			.status{
				display: none;
			}
		}
		.progress{
			display: none;
			font-size: 11px;
			font-weight: bold;
			top:50%;
			line-height: 1;
			text-align: center;
			width:100%;
			position: absolute;
			z-index:10;
			margin-top: -6px;
			@if($app-euro){
				color:#005ca9;
			}@else{
				color: #00c61e;
			}

		}
		.status{

			position: absolute;
			bottom:10px;
			width:300px;
			margin-left:-150px;
			left:50%;
			height:6px;
			background: #ddd;
			border:1px solid #ccc;
			border-radius: 3px;
			z-index: 2;
			span{
				height:10px;
				width: 20px;
				margin-left: -10px;
				@if($app-euro){
					background: #005ca9;
					border: 1px solid #005ca9;
				}@else{
					background: #00c61e;
					border: 1px solid #00c61e;
				}

				border-radius: 4px;
				display: inline-block;
				position: absolute;
				top:-4px;
				left:0;
				cursor: col-resize;
			}
		}
	}
}
#multimedia-lists{
	border-top:1px solid $border-color;
	text-align:center;
	position:relative;
	height:108px;
	line-height:108px;
	user-select: none;
	.next, .prev{
		position:absolute;
		top:0;
		height:99px;
		text-align:center;
		&::after{
			@extend %icon-arrow;
			font-size:40px;
			color:#9A9A9A;
		}
		&:hover{
			opacity:0.7;
		}
	}
	.next{
		right:230px;
		@media all and (max-width: #{$breakpoint-large}px) {
			right:140px;
		}
	}
	.prev{
		left:230px;
		@media all and (max-width: #{$breakpoint-large}px) {
			left:140px;
		}
		&::after{
			transform: rotate(180deg);
		}
	}
	.list{
		overflow:hidden;
		height:100px;
		width:640px;
		position:relative;
		margin:4px auto 0;
	}
	ul{
		list-style:none;
		height:100%;
		display:table;
		margin:0 auto;
		transition: 0.5s left;
	}
	li{
		display:table-cell;
		vertical-align: middle;
		a{
			width:71px;
			height:63px;
			line-height:63px;
			display:block;
			overflow:hidden;
			text-align:center;
			margin:0 10px;
			box-sizing: border-box;
			border:1px solid $border-color;
		}
		img{
			width:100%;
			height:100%;
		}
	}
	.multimedia-presentation a{
		position:relative;
		&::after{
			position:absolute;
			top:-3px;
			left:-6px;
			@extend %icon-360;
			font-size:70px;
			color:$text-color-lighter;
		}
	}
	.multimedia-video a{
		position:relative;

		&.empty-miniatures{
			img{
				display:none;
			}
			&::after{
				position:absolute;
				top:-3px;
				left:-6px;
				@extend %icon-video;
				font-size:70px;
				color:$text-color-lighter;
			}
		}
	}

}
#multimedia-types{
	user-select: none;
	position:absolute;
	bottom:0px;
	height:105px;
	left:10px;
	.photo-types{
		&::before{
			@extend %icon-multimedia-photo;
		}
	}
	.video-types{
		&::before{
			@extend %icon-multimedia-video;
		}
	}
	.presentation-types{
		&::before{
			@extend %icon-multimedia-360;
		}
	}
	a{
		display:block;
		padding:0 6px;
		color:$text-color-lighter;
		font-size:14px;
		text-decoration:none;
		vertical-align: inherit;
		line-height:32px;
		&:hover{
			text-decoration:none;
			background:#FFFBCC;
			&::before{
				color:$text-color !important;
			}
		}
		&::before{
			color:$link-color !important;
			font-size:32px;
			font-weight:bold;
			width:26px;
			text-align:right;
			margin-right:6px;
		}
	}
}
