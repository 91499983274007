#newsletter-modal {
	width:450px;
	.buttons{
		padding-top:10px;
		text-align:center;
	}
	h2{
		font-size: 17px;
		font-weight: bold;
		margin-bottom: 6px;
	}
	p{
		font-size: 15px;
		font-weight: normal;
	}
	button.close-button {
		@extend %btn-primary;
		margin:0 auto;
	}
}
