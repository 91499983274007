#header, #main-menu, #headless-main-menu, #top-bar, #promotion-bar, footer, #sticky-header,
#breadcrumb, #product-top, #menu-product, #product-tabs, #product-for-sale, #node,
#leaf, #product-archive, #multimedia-modal, #watched-list, #bottom-bar, #services-bar, #article, #author, #authors,
.service-modal, .limited-service-modal, #modal-error, #east-message, .remodal, #successor-product-section,
.warranty-item, #warranty-encouragement, #warranty-description, #delivery-modal, #to-cart, #main-page, .app-katalog body,
#compare-view, #shops-modal, #dictionary-modal, #error404, #error403, #gift-card-order-form, #definitions, #compare-bar, .shared-cart, body.order, .header-order, #search-shop, #brand, .cart-warranty-box,
#main-content, #account-change-payment, #modal-product-details, #account-change-delivery-time, .vue-page, #empty-search-results
{
	font:normal $font-default-size $font-default;
	color:$text-color;
	*{
		font-family:$font-default;
	}
}
#headless-main-menu, #top-bar{
	font:normal $font-default-size $font-default-with-ios;
	color:$text-color;
	*{
		font-family:$font-default-with-ios;
	}
}
input, button, textarea, .textarea-contenteditable *{
	font-family: $font-default;
}

#container > .content{
	padding-top:10px;
}
#container > .content-corners-top + .content{
	padding-top:0;
}

#leaf, #node, #error404, #error403, #product-card, #category-list,
#article, #ranking, .app-ole #articles-list, .app-ole #articles-category, .app-euro #article, .app-euro #articles-list, .app-euro #articles-category,
#comparison, #cms, #definitions, #locator, #shops,
#to-cart, #main-page,
.app-katalog, #shared-car, #brand, #empty-search-results {
	.page {
		width: $breakpoint-large-width+px;
		margin: auto;
		overflow: hidden;
		@media all and (max-width: #{$breakpoint-large}px) {
			width: $breakpoint-midium-width+px;
		}
		min-height:300px;
	}
	#breadcrumb {
		padding: 0 10px;
		&::after {
			display:none;
		}
	}
}

.app-ole #articles-category, .app-euro #articles-category {
	.page {
		//dla sticky menu
		overflow: visible;
	}
}

#cms{
	.page {
		width: $breakpoint-midium-width+px;
	}
	.full-width-page {
		width: 100%;
		#content-1c {
			width: 100%;
		}
	}
	#ui-datepicker-div {
		z-index: 1;
	}
}

.page{
	position: relative;
	z-index: 1;
}

@media (min-width: #{$breakpoint-large + 1}px) {
	.hidden-lg {
		display:none !important;
	}
	.hidden-md {
		display:block;
	}
}
@media (max-width: #{$breakpoint-large}px) {
	.hidden-lg {
		display:block;
	}
	.hidden-md {
		display:none !important;
	}
}

.js-livechat{
	display:none;
}
.livechat-on div.js-livechat{
	display:block;
}
.livechat-on a.js-livechat{
	display:inline-block;
}

#status-details-modal{
	width:400px;
}
#partner-details-modal{
	width:700px;
}
.additional-operator-message {
	@if($app-euro){
		background: #fffad7;
		border: 2px solid #ffe95b;
	}@else{
		background: #dcffe1;
		border: 2px solid #368F43;
	}
	font-size: 16px;
	margin-bottom: 10px !important;
	padding: 10px;
	text-align: center;
}

html.show-loading {
	cursor: wait;
	pointer-events: none;
	button,a{
		pointer-events: none;
	}
	body{
		@extend %loading;
		&::after{
			position:fixed;
		}
	}
}
html{
	.user-logged, .user-not-logged{
		display:none;
	}
	&.user-logged{
		.user-logged{
			display:block;
		}
		a.user-logged{
			display:inline-block;
		}

	}
	&.user-not-logged {
		.user-not-logged {
			display:block;
		}
		a.user-not-logged{
			display:inline-block;
		}

	}
	&.clean-mode {
		.banners{
			display: none !important;
		}
		#logo::before {
			content: "clean-mode";
			position: absolute;
			width: auto;
			height: auto;
			background: none;
			font-size: 14px;
			white-space: nowrap;
			@if($app-ole){
				left:130px;
				top:-16px;
				bottom: auto;
				right: auto;
				color: #fff;
			}@else{
			    bottom: -14px;
				right: 0;
				color:#333;
			}
		}
	}
	&.atr-mode {
		#logo{
			overflow: hidden;
			&::after {
				position: absolute;
				width: auto;
				height: auto;
				background: none;
				font-size: 14px;
				white-space: nowrap;
				left:0;
				content: "atr-mode: JS";
				@if($app-ole){
					top:-16px;
					right: auto;
					color: #fff;
				}@else{
					bottom: -14px;
					color:#333;
				}
			}
		}
	}
}

.operator-logged{
	.shopsItem .shop-code {display:block;}
}



#alert{
	max-width:350px;
	text-align:center;
	p{
		font-size:13px;
		font-weight:bold;
		margin:0 15px 5px;
	}
	button:not(.remodal-close){
		margin:10px auto 0;
	}
	button{
		border:0;
		text-shadow: none;
	}
}

#confirm{
	max-width:350px;
	text-align:center;
	p{
		font-size:13px;
		font-weight:bold;
		margin:0 15px 5px;
	}
	button:not(.remodal-close){
		margin:10px auto 0;
	}
	.buttons{
		margin:15px 0 0;
		text-align: center;
		button{
			margin:0 10px !important;
		}
	}
	button{
		border:0;
		text-shadow: none;
	}
}

.screen-reader-text {
	border: 0;
	font-weight: bold;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .6);
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal;
	z-index: 999;

	&:focus {
		background-color: #eee;
		clip: auto;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
	}
}

.photo-hover {
	.hover {
		position: absolute;
		top: 0;
		left: 0;
		visibility: hidden;
		opacity: 0.1;
		&.is-active {
			visibility: visible;
			opacity: 1;
			transition: opacity 0.5s ease-in-out;
		}
	}
	&:hover .hover.loaded {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.5s ease-in-out;
	}
}

.icon-visa-mastercard {
	background: url(/img/desktop/common/mastercard-visa.png) 0 0 no-repeat;
	display: inline-block;
	width: 44px;
	height: 14px;
	background-size: 100% auto;
	top: 2px;
	position: relative;
	margin-left: 2px;
}
