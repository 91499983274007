.price-normal {
	color: $text-color;
	font-weight: bold;
	white-space: nowrap;
}

.price-old {
	text-decoration: line-through;
	font-weight: bold;
	color: #1c1c1b;
	white-space: nowrap;
}

.package-badge {
	background: #164194 !important;
	color:#fff !important;
	font-weight: bold;
	white-space: nowrap;
	text-transform: uppercase;
	display: inline-block;
	height: 15px;
	line-height: 15px;
	padding:0 6px;
	font-size:10px !important;
}

.product-saved {
	background-color: #e63327;
	color: #fff;
	padding: 0 5px;
	display: inline-block;
	white-space: nowrap;

	span {
		color: #fff;
	}
}

.price-yearly{
	color: #e30613;
	font-weight: bold;
	white-space: nowrap;
}
.price-monthly-after-year{
	font-weight: bold;
}

.price-promotion-date {
	white-space: nowrap;
	font-size: 10px;
	font-weight: normal;
	padding: 5px 0 5px 0;
	line-height: 14px;
	color: $text-color-lighter;
}
.promotion-price-dates, .show-lowest-price {
	.price-normal{
		color: #e30613;
		+ .price-instalments {
			color: #e30613;
		}
	}
	+ .price-instalments-box .price-instalments{
		color: #e30613;
	}
}

.price-instalments {
	white-space: nowrap;
	//color: #e30613;
	color:$text-color;
	font-weight: bold;
	&.price-instalments-only-shop{
		color: $text-color;
		&:hover{
			color: $text-color;
		}
	}
	&:hover{
		//color: #e30613;
		color:$text-color;
	}
	span {
		color: inherit;
	}
}
.price-before{
	color: $text-color;
	font-size: 10px;
	font-weight: normal;
	text-align: right;
}
.price-omnibus {
	color: $text-color;
	font-size: 10px;
	font-weight: normal;
	text-align: right;
	span{
		white-space: nowrap;
		font-size: inherit;
	}
}
.price-presentation .price-promotion-name{
	text-align: right;
	margin:5px 0 3px;
	span{
		background: #45abe5;
		color:#fff;
		display: inline-block;
		padding:4px;
		border-radius: 2px;
		font-weight: bold;
		font-style: normal;
		font-size: 10px;
	}
}
.price-voucher{
	.price-normal{
		color:#ED1C24;
	}
	.price-voucher-name{
		margin-top:3px;
		font-size:10px;
		display: block;
		text-align: right;
		color: $text-color-lighter;
		strong{
			text-transform: uppercase;
			color: $text-color;
			display: inline-block;
			vertical-align: top;
		}
	}
	.price-voucher-end{
		font-size:13px;
		text-align:right;
		margin-top:5px;
	}
	.price-voucher-title{
		text-align: right;
		margin-top:5px;
		span{
			background: #45abe5;
			color:#fff;
			display: inline-block;
			padding:4px;
			border-radius: 2px;
			font-weight: bold;
			font-style: normal;
			font-size: 10px;
		}
		+ .price-voucher-name{
			margin-top:10px;
		}
	}
	.price-voucher-date{
		margin-top:5px;
		font-size:10px;
		color:$text-color-lighter;
		white-space: nowrap;
		text-align: right;
		display: block;
		~ .price-omnibus{
			margin-top:5px;
			margin-bottom:10px;
			+ .price-before{
				margin-top:-5px;
				margin-bottom:10px;
			}
		}
	}
	.price-voucher-info{
		&:before{
			@extend %icon-delivery-info;
			color:$text-color-lighter;
			font-size:13px;
			margin-left:2px;
			position: relative;
			top: -2px;
		}
	}
	.price-voucher-progress{
		height:12px;
		margin-top:5px;
		border-radius: 10px;
		background: #eee;
		border:1px solid #005ca9;
		span{
			display: block;
			background: #45abe5;
			height:12px;
			border-radius: 10px;

		}
	}
	.price-voucher-counter {
		text-align:right;
		margin-top:6px;
		.items-left{
			font-size:14px;
			text-align: center;
			background: #45abe5;
			color:#fff;
			font-style: normal;
			height: 22px;
			line-height: 20px;
			display: inline-block;
			min-width: 14px;
			border-radius: 20px;
			padding: 0 4px;
		}
	}
}
/* EURSI-37170
.price-cheaper{
	font-size: 13px;
	font-weight: bold;
	color: #e30613;
	white-space: nowrap;
	.price-old{
		font-size: 13px;
		color: $text-color;
		display: inline-block;
		font-weight: bold;
	}
	&.price-cheaper-top{
		&:before{
			content:"Hit cenowy!";
			color:#e30613;
		}
	}
}
*/
.price-history{
	font-size: 10px;
	padding: 2px 5px 5px 0;
	line-height: 14px;
	color: #999999;
	span{
		margin-left:2px;
	}
}
.prices-box{
	.price-instalments {
		display: flex;
		padding: 10px 0;
		label{
			font-size:11px;
			white-space: nowrap;
			text-transform: uppercase;
			color: #666;
			margin-right:4px;
		}
		> div{
			white-space: nowrap;
			font-weight: bold;
		}
		div {
			font-size:11px;
			color: #e30613;
			&:hover{
				color: #e30613;
			}
		}
	}
	.product-market-price, .product-competitor-price{
		padding:0 0 10px 0;
		width:215px;
		position:relative;
		background: #eaeaea;
		z-index:1;
		.less{
			display:block;
			margin:0 10px;
			border-top:1px solid #ccc;
			padding:6px;
			text-align:center;
			font-size:13px;
		}
		.more{
			position:absolute;
			width:31px;
			height:31px;
			line-height:31px;
			font-size:18px;
			display:block;
			background:#fff;
			color:$link-color;
			top:2px;
			right:2px;
			text-align :center;
			font-weight:bold;
			&:hover{
				text-decoration:none;
				color:#fff;
				background:$link-color;
			}
		}
		.popup{
			display:none;
			position:absolute;
			left:-10px;
			top:35px;
			border:2px solid #E30613;
			border-top:0;
			width:191px;
			padding:10px 10px 0;
			margin:0 10px;
			background:#fff;
			font-size:14px;
			div{
				font-size:19px;
				font-weight:bold;
				color:#E30613;
				margin-bottom:10px;
			}
			p{
				margin-bottom:10px;
				font-size:12px;
			}
		}
		&.show-popup .popup{
			display:block;
		}
	}
	.product-market-price {
		.box{
			background: #E30613;
			padding:0 35px 0 10px;
			color: #fff;
			white-space:nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-size:17px;
			text-align :center;
			height:35px;
			font-weight:bold;
			line-height: 35px;
			span{
				font-weight:bold;
			}
		}
	}
	.product-competitor-price {
		margin-top: 8px;
		padding-bottom: 0;
		.popup {
			top: 100%;
		}
		.box {
			background: #E30613;
			padding: 8px 35px 8px 10px;
			color: #fff;
			font-size: 17px;
			font-weight: bold;
		}
		.icon-more {
			padding: 9px;
			&:after {
				@extend %icon-info;
				color: #a3010a;
				font-size: 16px;
				position: absolute;
			}
			&:before {
				content: '';
				display: block;
				width: 14px;
				height: 14px;
				left: 10px;
				top: 10px;
				border-radius: 50%;
				background: #fff;
				position: absolute;
			}
			top: 0;
			right: 0;
			width: 16px;
			height: 16px;
			position: absolute;
		}
	}
	.competitor-price-item {
		padding: 5px;
		background: #f5f5f5;
		border-bottom: 1px solid #ddd;
		overflow: hidden;
		font-weight: bold;
		> div {
			float: left;
		}
	}
	.competitor-price {
		text-align: right;
		width: 30%;
	}
	.competitor-name {
		width: 70%;
	}
	.competitor-price-info {
		position: relative;
		.box {
			text-align: left;
		}
	}
}

.product-price .subscription-price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.price-monthly, .price-yearly{
		color: #e30613;
		font-size: 30px;
		display: flex;
		font-weight: bold;
		align-items: baseline;
		justify-content: flex-end;
	}
	.price-monthly-after-year{
		font-size: 14px;
	}

	p {
		color: $text-color;
		margin: 3px 0 7px;
		text-align: right;
		font-size: 12px;
	}

	.promotion-price {
		@extend %promotion-price;
	}
}