.password-invalid .password-toggle {
    position: absolute;
    top: -17px;
    right: if($app-ole, -12px, 0);
    z-index: 2;
    font-size: 11px;
    padding-right: 2px;
    padding-left: 4px;
    &:before{
        content:"Pokaż hasło";
    }
    .row[type="text"] &{
        &:before{
            content:"Ukryj hasło";
        }
    }
}

.row[type="text"] + .password-invalid .password-toggle:before{
    content:"Ukryj hasło";
}

.password-invalid{
	font-size: 11px;
    line-height: 13px;
    padding: 7px 105px;
    margin: 0;
    position: relative;

    .error {
        color: #ED1C24;
    }
    
    .error:before {
        background: #ED1C24;
    }

    ul{
        padding:0;
        margin:0;
        list-style: none;
    }
    li{
        padding:0 0 10px 20px;
        margin:0;
        position: relative;
        font-size:10px;
        width: 240px;
        
        &.ok {
           color: #0A0A0A; 
        }
        &.ok:before{
            @extend %icon-check;
            background:#028846 !important;
        }
        &.error:before{
            @extend %icon-close;
        }
        &:before{
            @extend %icon-close;
            background: #AFAFAF;
        }
        &:before, &.error:before, &.ok:before{
            border-radius:50%;
            width:14px;
            height:14px;
            line-height: 14px;
            color:#fff;
            text-align:center;
            position:absolute;
            left: 0;
        }
    }
    .password-without {
        display: none;
        span {
            display: block;
            margin-bottom: 8px;
            min-width: 150px;
        }
    }

    .show-without {
        display: block;
        color: #ED1C24;
        .error {
            display: block;
        }
        .error:before {
            background: #ED1C24;
        }
        li:not(.error) {
            display: none;
        }
    }

    .password-with {

        span {
            display: block;
            font-size: 11px;
            line-height: 13px;
            margin-bottom: 12px;
            min-width: 150px
        }
        
    }
}

.error-box {
    position: relative;
    z-index: 1;
    padding: 5px 0;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.2;
        background: #ff0000;
        margin: 0 7px 7px 12px;
    }

    >* {
        position: relative;
        z-index: 1;
    }

    .password-with span {
        color: #ED1C24;
    }
}

@if($app-ole) {
    .error-box:before {
        background-color: #E30613;
        opacity: 0.3;
    }
}