#empty-frontOperator-bar {display:none;}
#empty-partner-bar {display:none;}

#container {
	padding-top: 10px;
	z-index: 0;
}

//https://jira.unity.pl/browse/EURTM1-981 <-- tymczasowo dla zabezpieczenia
label.error img {
	display: none;
}
