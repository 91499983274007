@import "../vendor/remodal";
@import "../vendor/remodal-default-theme"; //podniesiony z-index ze wzgledu na zmiane w sticky

.remodal-overlay {
	z-index: 5;
}

.remodal-wrapper {
	z-index: 6;
}
.modal-top-layer .remodal-wrapper{
	z-index: 7;
}

.remodal-bottom {
	&.remodal-wrapper {
		top: auto;
		left: auto;
		padding: 0;
		overflow: visible!important;

		.remodal {
			margin: 0 20px;
			border: 1px solid #ccc;
			box-shadow: 0 -2px 10px rgba(0,0,0,.3);
		}

		&::after {
			vertical-align: bottom;
		}
	}

	&.remodal-overlay {
		display: none !important;
	}
}

.remodal-youtube{
	&.remodal-wrapper{
		padding:0;
	}
	.remodal{
		padding:0;
		width:720px;
		max-width: 100%;
		.remodal-close{
			top: -36px;
		}
		.video-box{
			background: #000;
			position: relative;
			padding-bottom: 53%;
			padding-top: 25px;
			height: 0;
		}
		iframe{
			background: #000;
			border:0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}