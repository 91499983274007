.social-photo {
	font-family: $font-default;
	text-align: center;
	width: 600px;
	height: 315px;
	.stars {
		float: right;
		width: 170px;
		margin-top: 5px;
		a {
			background:url($img-url+'stars.png') 0 -17px;
			height:16px;
			width:85px;
			overflow:hidden;
			display:inline-block;
			vertical-align:middle;
			span {
				background:url($img-url+'stars.png') 0 0;
				height:16px;
				display:block;
			}
		}
		em {
			color:#5b5b5b;
			display:inline-block;
			font-style: normal;
			font-size:15px;
			position: relative;
			top: 2px;
		}
	}
	.delivery-label {
		img {
			display: inline-block;
			position: relative;
			margin-right: 5px;
			top: 5px;
		}
		color: #999999;
		font-size: 15px;
		float: left;
	}
}
.social-photo-bottom {
	overflow: hidden;
	width: 500px;
	margin: 0 auto;
	h3 {
		margin-top: 0;
		text-align: center;
		font-size: 26px;
	}
}