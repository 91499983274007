#warranty-terms-modal{
	padding: 0;
}
.warranty-item{
	padding: 0;

	.warranty-header{
		padding: 30px;
		border-bottom: 1px solid $border-color;
	}

	.warranty-description{
		padding: 30px 30px 10px;
		font-size: 14px
	}

	.warranty-icon{
		float: left;
		width: 85px;

		img{
			@if($app-katalog){

			}@else{
				width: 58px;
				height: 77px;
			}
		}
	}

	.warranty-info{
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 85px);

		.title{
			font-size: 22px;
			font-weight: bold;
			margin-bottom: 10px;
			line-height: 20px;
		}

		.subtitle{
			font-size: 16px;
			margin-bottom: 10px;

			.price{
				color: red;
			}

			.price-only{
				font-weight: bold;
			}
			br {
				display: none;
			}
		}

		label.checkbox-css{
			display: inline-block;
		}
	}

	.warranty-description{
		line-height: 20px;

		ul{
			margin: 15px 0 15px 20px;

			li{
				list-style: none;
				position: relative;
				margin-bottom: 16px;

				&:before{
					@extend %icon-check;
					position: absolute;
					top: 3px;
					left: -20px;
					font-size: 11px;
					font-weight: bold;
					color: $link-color;
				}
			}
		}
	}

	.warranty-detailed-information{
		padding: 0 30px 30px 30px;
		a{
			display: inline-block;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 20px;
			font-weight: bold;

			&:after{
				margin: 0 0 0 5px;
				@extend %icon-arrow;
				font-size: 16px;
				line-height: 20px;
			}
		}
	}

	.warranty-actions{
		padding: 0 30px 30px;
		.warranty-add-to-cart{
			float: right;
		}
	}
}
