.product-for-sales-show #product-for-sale{
	visibility: visible;
	>* {
		opacity: 1;
	}
	&.position-bottom{
		position: absolute !important;
		bottom:0 !important;
		top:auto !important;
	}
}
#product-for-sale-box{
	position:absolute;
	left:936px;
	height:100%;
	@media screen and (max-width: $breakpoint-large+px) {
		left:695px;
	}
}

.product-sales .product-articles {
	background: #eaeaea;
	ul {
		padding: 0 15px 5px 15px;
	}
	li {
		padding-bottom: 5px;
	}
	a {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: bold;
		color: if($app-euro, $link-color, $primary-color);
	}
}
#product-for-sale {
	top: 0;
	margin-top: 5px;
	width: 244px;
	position: fixed;
	visibility: hidden;
	>* {
		transition: opacity 0.3s ease;
		opacity: 0;
	}

	.product-for-sale-title {
		font-weight: bold;
		font-size: 16px;
		display: block;
		padding: 15px 15px 0 15px;
		&.product-for-sale-title-long{
			font-size: 14px;
		}
	}
	.product-code-for-sale{
		padding: 5px 15px 0 15px;
	}
	.product-call-and-order{
		display:none !important;
	}
	.product-additional-services {
		background: #fff;
		display: table;
		width: 100%;
		margin-bottom: 15px;
		padding: 0 15px;
		box-sizing: border-box;
	}

	.product-for-sale-content {
		background: #eaeaea;
		position: relative;
		z-index: 2;

		.product-image {
			border: 1px solid $border-color;
			background: #fff;
			margin-bottom: 10px;
			display: inline-block;
			width: 190px;
			padding:0 12px;
			height: 169px;
			margin: 15px 15px 0 15px;

			img {
				display: block;
				width: 100%;
				height: 100%;
				margin: auto;
				cursor:pointer;
			}
		}
	}

	.product-service {
		display: table-row;

		.price-normal {
			white-space: nowrap;
			font-size: 14px;
			line-height: 20px;
			text-align: right;
			float: right;
			/*
			.price-old {
				font-weight: normal;
				display: block;
			}
			*/
		}

		& > * {
			padding: 9px 9px 9px 0;
			display: table-cell;
			vertical-align: top;
			border-bottom: 1px solid $border-color;
			position: relative;
		}
	}

	.product-service-info {
		max-width: 180px;
		line-height: 20px;

		label {
			font-weight: normal;
			font-size: 12px;
			line-height: 13px;
			max-height: 40px;
			overflow: hidden;
			display: block;
			margin-bottom: 3px;
		}

		a {
			white-space: nowrap;
		}
	}
	.product-for-sale-banner img {
		max-width: 100%;
	}
	.best-voucher .best-voucher-container {
		border:0;
	}
}
@media screen and (max-width: $breakpoint-midium+px) {
	#product-for-sale-box{
		display:none;
	}

}
@media screen and (max-height: 595px) {
	#product-image-sales{
		display:none;
	}
	#product-for-sale .product-for-sale-title {
		padding-top: 0;
	}
}
