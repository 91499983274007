.tooltip-icon {
	&::before{
		@extend %icon-tooltip;
		font-size:15px;
		color:$text-color-lighter;
		vertical-align: top;
	}
	cursor:help;
}

.tooltip {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index:7;		//ma byćwyżej niż remodal
	max-width: 400px;
	padding: 16px;
	visibility: hidden;
	color: #fff;
	border-radius: 7px;
	background: #0A0A0A;
	font-size: 14px;

	&:before {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
	}

	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
	}

	&[data-arrow-direction = top] {
		&:before {
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 9px solid #0a0a0a;
			bottom: 100%;
			z-index: 2;
		}
	}

	&[data-arrow-direction = bottom] {
		&:before {
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-top: 9px solid #0a0a0a;
			top: 100%;
			z-index: 2;
		}
	}

	&[data-arrow-place = left] {
		&:before {
			left: 21px;
		}

		&:after {
			left: 20px;
		}
	}

	&[data-arrow-place = right] {
		&:before {
			right: 21px;
		}

		&:after {
			right: 20px;
		}
	}
}

.tooltip-description {
	display: none;
}

.show-tooltip {
	.tooltip {
		visibility: visible;
	}
}
