
.video-preview {
	height: 73px;
	width: 66px;
	font-size:9px;
	line-height:10px;
	color:$text-color-lighter;
	position:absolute;
	bottom:0;
	right:0;
	background:#fff;
	border-top-left-radius: 9px;
	box-shadow:-2px -2px 2px rgba(173, 173, 173, 0.31);
	padding: 5px 5px 26px;
	img {
		width: 100%;
		height: 100%;
	}
}
