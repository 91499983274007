#east-message{
	z-index:5;
	position:fixed;
	transform: translateX(100%);
	transition: transform 0.3s;
	right:0;
	top:186px;
	width:240px;
	.message{
		border:1px solid #AAAAAA;
		position:relative;
		background: #ffffff;
		padding:15px;
		font-size:11px;
		width:300px;
		min-height:150px;
		&::before{
			content:'\0432  \043D  \0438  \043C  \0430  \043D  \0438  \0435';
			position:absolute;
			text-align:center;
			top:-1px;
			left:-40px;
			width:40px;
			background:#e63327;
			padding:13px 10px;
			color:#fff;
			font-size:22px;
			box-sizing: border-box;
			line-height:18px;
		}
	}
	&.show-message{
		transform: translateX(0);
	}
}
