// domyślne style dla karuzeli previewGallery.js
.preview-gallery {
	position: relative;

	.prev, .next {
		@extend %btn-raised;

		position: absolute;
		top: 50%;
		height: 90px;
		width: 35px;
		text-align: center;
		line-height: 45px;
		padding: 0;
		z-index: 2;

		&:before {
			@extend %icon-small-arrow;
			font-weight: bold;
			font-size: 32px;
		}
	}

	.prev {
		left: 0;
		transform: translateX(-10px) translateY(-50%);

		&:before {
			transform: rotate(180deg);
		}
	}

	.next {
		right: 0;
		transform: translateX(10px) translateY(-50%);
	}
}