%btn {
	padding: 11px 15px 10px 15px;
	font-size: 12px;
	text-transform: uppercase;
	border-radius:0;
	text-align:center;
	font-weight: bold;
	display: inline-block;
	white-space: normal;
	background: transparent;
	border: 0;
	line-height: 1.2;
	@if ($app-ole) {
		border-radius: 4px;
	}
	&:focus {
		outline: 0;
	}
	&:hover{
		text-decoration: none;
	}
}

%btn-primary {
	background: $button-primary-background;
	color: $button-primary-color;
	&:hover{
		background: $button-primary-background-hover;
		color:$button-primary-color-hover;
	}
	&:active {
		background-color: darken($button-primary-background, 5%);
	}
}
%btn-second {
	background: $button-second-background;
	color: $button-second-color;
	&:hover{
		background: $button-second-background-hover;
		color:$button-second-color-hover;
	}
}

%btn-third {
	background: $button-third-background;
	color: $button-third-color;
	&:hover{
		background: $button-third-background-hover;
		color:$button-third-color-hover;
	}
}

%btn-fourth {
	background: $button-fourth-background;
	color: $button-fourth-color;
	&:hover{
		background: $button-fourth-background-hover;
		color:$button-fourth-color-hover;
	}
}

%btn-raised {
	background: #f7f7f7;
	color: $link-color;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .25);

	&:hover {
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 1px 5px rgba(0, 0, 0, 0.15);
	}

	&:active {
		background: #f1f1f1;
	}
}

%btn-expand {
	display: block;
	background: transparent;
	border: 1px solid #e5e5e5;
	color: $link-color;
	padding: 6px 15px;
	text-transform: uppercase;
	margin: 20px auto;
	border-radius:0;
	position: relative;
	padding: 10px 10px 10px 30px;
	&:hover {
		border: 1px solid $link-color;
	}
	&::before {
		content: "";
		position: absolute;
		left: 12px;
		top: 50%;
		margin-top: -3px;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid $link-color;
	}
	&::after {
		content: attr(data-more);
	}
}
%btn-collapse{
	&::after {
		content: attr(data-less);
	}
	&::before {
		transform: rotate(180deg);
	}
}
%btn-input-size{
	padding: 6px 10px;
	font-size:12px;
}
%btn-ultrathin {
	padding: 2px 2px;
	font-size:10px;
	line-height: 9px;
}
%btn-thin {
	padding: 3px 4px;
	font-size:10px;
}
%btn-small {
	@if($app-euro){
	padding: 5px 6px;
	}@else{
		padding: 4px 6px;
	}
	font-size:11px;
	line-height: 1.1;
}
%btn-medium {
	padding: 7px 10px;
	font-size:12px;
}

%btn-big {
	padding: 19px 12px;
	@if($app-euro){
		font-size: 16px;
	}@else{
		font-size:14px;
	}
}

%btn-new-mobile{
	min-width: 100px;
	padding: 15px 30px;
	border:0;
	border-radius: 50px;
	background-color: #0876FE;
	color: #ffffff;
	font-size: 16px;
	line-height: 21px;
	box-shadow: 0px 1px 3px rgba(48, 49, 51, 0.2), 0px 4px 8px rgba(48, 49, 51, 0.16);
	&:hover{
		background: darken(#0876FE, 5%);
		color:#ffffff;
		border:0;
		text-decoration: none;
	}
}
%btn-new-mobile-ghost {
	padding: 12px 23px;
	border-radius: 50px;
	border: 0;
	background: #fff;
	color: #0975fd;
	font-size: 16px;
	line-height: 21px;
	text-transform: initial;
	box-shadow: 0px 1px 3px rgba(48, 49, 51, 0.2), 0px 4px 8px rgba(48, 49, 51, 0.16), inset 0px 0px 0px 1px #E5E5E5;
	&:hover{
		background: darken(#fff, 5%);
		border:0;
		text-decoration: none;
	}
}
%btn-link{
	background-color: transparent;
	color: $link-color;
	border:0;
	text-shadow: none;
	&:hover{
		background: none;
		color:$link-color-hover;
		border:0;
		text-decoration: underline;
	}
}

%btn-simple-link {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	color: $link-color;
	font-family: inherit;
	font-size: inherit;
	text-transform: initial;
	font-weight: normal;

	&:hover {
		color:$link-color-hover;
		text-decoration: underline;
	}
}

%btn-disabled {
	background: $button-disabled-background;
	color: $button-disabled-color;
	border:0;
	pointer-events: none;
	text-shadow:none;
	cursor:default;
}

%btn-ghost {
	background: #fff;
	color: #0975fd;
	padding: 8px 16px;
	border-radius: 50px;
	box-shadow: 0px 1px 1px rgba(48, 49, 51, 0.12), 0px 2px 4px rgba(48, 49, 51, 0.08), inset 0px 0px 0px 1px #E5E5E5;
}