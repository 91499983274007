
.product-code {
	color: $text-color-lighter;
	display: inline-block;
	>div{
		display: inline;
		position:relative;
		@if($app-katalog){
			user-select: all;
		}
	}
}
.product-ean {
	&:before{
		content: "EAN: ";
	}
	color: $text-color-lighter;
	display: inline-block;
	span{
		@if($app-katalog){
			user-select: all;
		}
	}
}
.score-box{
	font-size:11px;
	float:right;
	display: inline-block;
	margin:3px 0 0 0;
	span{
		white-space: nowrap;
		color: $link-color;
		margin-left:20px;
		display: inline-block;
		font-weight: normal;
		em{
			margin-left:2px;
			display: inline-block;
			border-radius: 50%;
			background: #3b80ba;
			font-weight: bold;
			color:#fff;
			height: 19px;
			width: 19px;
			line-height: 17px;
			text-align:center;
			font-style: normal;
		}
	}
	.score-hide{
		display:none !important;
	}
}
.product-score {
	font-weight: normal;
	color: $text-color-lighter;
	.score{
		white-space: nowrap;
	}
	.life-status{
		color:$text-color;
	}
	.symcar{
		white-space: nowrap;
		user-select:all;
		&:before{
			content:"sym. ";
		}
	}
}

#products .product-status-presale{
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 0;
	width: 100%;
	box-sizing: border-box;
}

.product-box{
	padding: 0 0 20px;
	margin: 0 10px;
	position: relative;
	.product-photos, .product-photo {
		width: 335px;
		padding-bottom: 20px;
		float: left;
		position: relative;
	}
	#product-photo{
		position: relative;
	}
	.product-status-presale{
		padding:0 15px 10px;
		margin-top:-10px;
		background: #eaeaea;
		span{
			border:2px solid #005CA9;
			text-align: center;
			text-transform: uppercase;
			display: block;
			padding:3px 0;
		}
	}
	.product-main {
		width: 920px;
		@media screen and (max-width: $breakpoint-large+px), print {
			width: 680px;
		}
	}
	.product-brand, .product-category, .stars-rating{
		display: inline-block;
		overflow:hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
		vertical-align: text-bottom;
	}
	.product-code {
		display: inline-block;
		margin-left:10px;

		span {
			position: relative;
			display: inline;
		}
	}
	.product-score {
		display: inline-block;
		.score{}
		.life-status{}
		.symcar{}
	}
	.product-brand{
		font-weight:normal;
		font-size: 12px;
		color: $text-color-lighter;
	}
	.product-brand{
		color: $text-color-lighter;
		max-width:190px;
	}
	.product-category{
		font-size: 12px;
		max-width:270px;
		margin:0;
		color: $text-color-lighter;
		a{
			font-weight:normal;
			font-size: 12px;
			color: $text-color-lighter;
		}
	}
	h1 {
		font-weight: bold;
		font-size: 24px;
		color: $text-color;
		margin-bottom:4px;
	}
	.stars-rating {
		margin-right:10px;
	}
	.product-header {
		padding-bottom:15px;
	}
	.wc-watched-box{
		padding:10px 10px 10px 40px;
		border:1px solid #759bbd;
		border-radius: 3px;
		clear: both;
		position: relative;
		margin:0 0 13px 0;
		top:10px;
		background: #f2f2f2;
		b{
			font-weight: normal;
		}
		strong{
			font-weight: bold;
		}
		&:before{
			@extend %icon-office;
			position: absolute;
			top:50%;
			margin-top:-10px;
			font-size:14px;
			left:14px;
			color: $link-color;
		}
	}

	.advertising-placement {
		margin-bottom: 5px;
		display: flex;
		flex-direction: column;

		.advertising-date {
			font-size: 12px;
			font-weight: normal;
			color: #999999;
			vertical-align: middle;
			white-space: nowrap;
		}

		// Wersja A - obrazki - usunieta

		// Start Wersja B - tekst
		@if ($app != 'katalog') {
			.product-card-test-enabled & {
				flex-direction: column;
				margin-bottom: 10px;

				.more-promotions,
				.promotion-block {
					display: block;
				}

				.promotion-item {
					&:not(:first-child) {
						margin-top: 15px;
					}
				}
			}
		}

		.more-promotions {
			@extend %btn-link;
			padding: 10px 0 0 0;
			text-transform: unset;
			font-weight: normal;
			margin-right: auto;
			display: none;

			span:before {
				content: attr(data-more-small);

				@media screen and (min-width: #{$breakpoint-large+1}px) {
					content: attr(data-more-large);
				}
			}

		}

		.promotion-block {
			display: none;
			border-left: 4px solid transparent;
			padding-left: 10px;
			font-weight: bold;
			line-height: 18px;

			&:hover {
				text-decoration: none;
			}

			.badge {
				display: inline-block;
				vertical-align: middle;
				padding: 1px 6px 3px 6px;
				border-radius: 2px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 100%;
				margin-right: 3px;
			}

			.promotion-text {
				vertical-align: middle;
				color: $text-color;

				span {
					padding-right: 15px;
				}

				i {
					margin-left: -15px;

					&:after {
						@extend %icon-delivery-info;
						width: 10px;
						margin-top: -2px;
						color: #828282;
						font-size: 14px;
					}
				}
				@if ($app-katalog) {
					margin-right: 8px;
				}
			}
		}

		a.promotion-block {
			span {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	@if ($app-katalog) {
		& .advertising-placement {
			flex-direction: column;
			margin-bottom: 10px;

			.more-promotions,
			.promotion-block {
				display: block;
			}

			.promotion-item {
				&:not(:first-child) {
					margin-top: 15px;
				}
			}
		}
	}

	.advertising-placement-outlet {
		display: none;
	}

	&.product-is-outlet {
		.advertising-placement-new {
			display: none;
		}

		.advertising-placement-outlet.is-active {
			display: flex;
		}

		#availability-notification-form {
			display: none;
		}
	}

	.product-info {
		width:555px;
		margin-left:30px;
		float:left;
		@media screen and (max-width: $breakpoint-large+px), print {
			width:315px;
		}
	}
	.product-attributes{

	}
	.product-community{
		clear:both;
	}
	.community-list {
		display: inline-block;
		> li {
			display: inline-block;
			margin-right: 3px;
			@media screen and (max-width: $breakpoint-large+px) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.product-sales-print-info {
		color: $link-color;
		display: none;
		padding: 15px;
	}
	#print-info-date {
		margin-bottom: 10px;
		position: relative;
		padding-left: 40px;
		height: 22px;
		line-height: 22px;
		&::before {
			@extend %icon-print;
			position: absolute;
			left: 0;
			top: 0;
			font-size:22px;
		}
		span {
			font-weight: bold;
		}
	}
	#print-info-phone {
		position: relative;
		padding-left: 40px;
		font-weight: bold;
		&::before {
			@extend %icon-phone;
			position: absolute;
			left: 0;
			top: 0;
			font-size:22px;
		}
	}
	.product-card-mini {
	}
	.product-sales {
		position: absolute;
		right: 0;
		top: 0;
	}
	.product-row{
		display:table;
		width:100%;
		clear: both;
	}
	.product-short-opinion{
		border-left :1px solid #ddd;
		font-style: italic;
		font-size: 14px;
		margin-bottom: 5px;
		padding-left: 10px;
		.rating-value {
			color: #999;
			margin-left: 10px;
			display: inline-block;
		}
		.stars-rating {
			> div {
				width: 60px;
			}
			> div:before,
			span:before {
				font-size: 12px;
				padding-top: 2px;
			}
		}
		.rating-count{
			display:none;
		}
		.nick,
		.date {
			color: #999;
			font-size: 12px;
			font-style: normal;
		}
		.text-column{
			.short{
				display:none;
			}
			.full{
				display:inline;
			}
			&.text-column-short{
				.short{
					display:inline;
				}
				.full{
					display:none;
				}
			}
		}
	}
}

.product-call-and-order {
	transition: all .2s;
	visibility: hidden;
	position: absolute;
	left: calc(100% + 20px);
	top: -30px;
	color: #fff;
	width: 200px;
	padding: 10px;
	opacity: 0;
	transform: translateX(50px);
	pointer-events: none;
	z-index: 2;

	&::before {
		content: "";
	}

	.call-order-text {
		font-size: 16px;
		margin: 20px 0;
	}

	.call-order-phone {
		font-size: 24px;
		margin: 10px 0;

		&::before {
			@extend %icon-phone3;
			font-size: 24px;
			margin-right: 10px;
		}
	}
}

.call-order-container {
	position: relative;

	&::before {
		position: absolute;
		top: 10px;
		left: -25px;
		content: "";
		border-color: transparent #3C3B3B transparent transparent;
		border-style: solid;
		border-width: 20px 15px 20px 0;
	}
}

.call-and-order {
	.product-code {
		background-color: #fffbcc;
		color: #000;
	}

	.call-button {
		background-color: #fffbc8;
		color: #000;
	}

	.product-call-and-order {
		background-color: #3C3B3B;
		opacity: 1;
		pointer-events: auto;
		visibility: visible;
		transform: translateX(0);
	}
}

.call-box{
	background: #eaeaea ;
	padding: 0 15px 15px;
	button{
		color: #555;
		font-size: 22px;
		border: 0;
		padding: 0;
		background: none;
		&:hover{
			color: lighten(#555, 5%);
		}
		}

	.call-button-info {
		font-size: 12px;
		display: block;
		margin-bottom: 5px;
		text-transform: none;
		font-weight: normal;
		text-align: left;
	}
}

.basic-tech-details + h5 {
	color: #999999;
}

.product-sales {
	box-sizing: border-box;
	width: 244px;
	.product-sales-print-logo {
		display: none;
		width: 248px;
		@media screen and (max-width: $breakpoint-large+px) {
			width:228px;
		}
		padding-bottom: 15px;
		img {
			width: 100%;
		}
	}
	.price-promotion-date {
		text-align: right;
	}
	.product-price .lower-price {
		margin-top:3px;
	}
	.price-voucher-title{
		margin-top:0;
		padding-top:10px;
		+.price-normal{
			padding-top: 4px;
		}
	}
	.price-omnibus, .price-before{
		font-size:14px;
	}
	.price-omnibus{
		margin-top:10px;
		+ .price-before{
			margin:7px 0 0;
		}
	}
	.price-before{
		+ .price-voucher-header{
			margin-top:14px;
		}
	}
	
	/* EURSI-37170
	.product-price .price-old{
		display: inline-block;
		font-size:14px;
		margin-right:5px;
		color: $text-color;
	}
	*/
	/*EURSI-37170
	.price-cheaper{
		text-align: right;
		font-size: 14px;
		font-weight: bold;
		color: #e30613;
		white-space: nowrap;
		&.price-cheaper-top{
			&:before{
				content:"Hit cenowy!";
				color: #e30613;
			}
		}
	}*/
	.price-history{
		text-align: right;
		font-size: 10px;
		padding: 2px 0 5px;
		line-height: 14px;
		color: #999999;
		font-weight: normal;
		span{
			margin-left:2px;
		}
	}
	.label-action{
		padding:2px 0 0 0;
		color:$text-color;
		position:relative;
		margin-bottom:5px;
		&::before{
			@extend %icon-warn;
			font-size:17px;
			width:20px;
			text-align:left;
		}
	}
	.label-button{
		padding:20px;
		background:#ffffff;
		color:$text-color-lighter;
		text-transform: uppercase;
		text-align:center;
		font-size:14px;
		font-weight:bold;
	}
	.best-voucher {
		position: relative;
		z-index: 2;
		@if($app-katalog){
			background: #d0dff2;
		}@else{
			background: #fffbcc;
		}
	}
	.price-box {
		display: flex;
		justify-content: space-between
	}
	.product-price {
		@if ($app-ole == false) {
			border-bottom: 1px solid #ccc;
		}
		margin: 0 15px 15px 15px;
		padding-bottom: 10px;
		@if ($app-euro) {
			margin-bottom:10px;
		} @else {
			background: #eaeaea;
			text-align: right;
		}
		&.price-show {
			.price-normal {
				display: block;
				text-align: right;
			}
		}
		&.instalments-show {
			.price-instalments {
				display: block;
				@if($app-katalog) {
					font-size: 14px;
				}
			}
			.is-voucher.using-discounted-price .price-instalments:not(.price-instalments-only-shop){
				color:#ED1C24;
			}
		}
		&.price-big{
			.price-normal {
				font-size: 34px;
			}
		}
		.price-normal {
			display: none;
			@if($app-katalog){
				font-size:30px;
				padding-top: 7px;
				padding-left: 5px;
				line-height: 35px;
			}@else{
				font-size: 39px;
				padding-top: 15px;
				line-height: 40px;
			}
		}
		@if($app-katalog==false) {
			.price-voucher + .price-instalments-box{
				margin-top: 5px;
			}
		}
		.price-instalments {
			display: none;
			text-align: left;
		}
		.product-available-soon {
			color: #e30613;
			font-size: 22px;
			font-weight: bold;
		}
	}
	@if($app-katalog==false) {
		.price-instalments-box {
			@extend %price-instalments-box;
		}
	}
	.product-button-outlet{
		padding-bottom:0;
		display:none;
		.action-primary{
			padding: 0;
		}
	}
	.product-button {
		padding: 0 15px 15px 15px;
		background: #eaeaea;

		.activation-voucher {
			@extend %activation-voucher;
			margin-left: -9px;
			margin-right: -9px;
		}

		.action-primary {
			padding-top: 10px;
		}
		button {
			display: block;
			width:100%;
			padding-left:0;
			padding-right:0;
			white-space:nowrap;
			&>span {
				display: block;
				margin: 3px 0;
			}
		}
		.add-to-cart {
			@extend %btn-primary;
		}
		button.shop-delivery-only-available{
			@extend %btn-primary;
			@extend %btn-big;
		}
		button.delivery-availability-status{
			@extend %btn-third;
			white-space:nowrap;
		}

		.delivery-price, .pickup-points, .terminal-enabled{
			margin-top:8px;
		}
		.delivery-price + .terminal-enabled, .delivery-price + .pickup-points{
			margin-top:0;
		}

		a {
			margin-bottom: 8px;
			font-weight: bold;
		}

		a:last-child{
			margin-bottom:0;
		}
		+.call-box{
			margin-top:-10px;
		}
	}
}
#products{
	.availability-notification{
		padding-top: 0;
		margin-top: 0;
	}
}
.product-is-outlet .availability-notification{
	display:none;
}
.availability-notification {
	padding: 10px;
	margin-top: 10px;
	background: #eaeaea;
	h3 {
		padding: 0;
		text-transform: uppercase;
		font-size: 11px;
		margin-bottom: 8px;
	}
	input {
		width: 204px;
		padding: 8px;
		border: 1px solid #cbcbcb;
		margin-bottom: 8px;
	}
	button {
		@extend %btn-primary;
		width: 100%;
	}
	.permissions-desc {
		padding-top: 15px;
		padding-bottom: 10px;
		color: #666666;
		font-size: 8px;
		line-height: 10px;
	}
	#email-error {
		display: block;
		color: red;
		font-weight: bold;
	}
}
#after-search-message, #from-product {
	display:none;
	&.showed{
		display:block;
	}
	padding:10px 15px;
	background:#EBF8FE;
	font-size:12px;
	color:$text-color;
}
#after-search-message{
	margin:10px;
}
#from-product {
	margin:10px 0;
}

.remodal.voucher-info{
	width:460px;
	padding:20px;
	font-size: 14px;
}
@if ($app-ole) {
	.outlet-quality-category {
		font-weight: bold;
		color: $outlet-color;
	}

	.outlet-quality-category-box {
		position: relative;
		align-items: center;
		display: flex;
		margin-top: 5px;
		@if ($app-ole) {
			justify-content: flex-end;
			margin: 15px 0 0;
		}
	}
	.outlet-category-info {
		cursor: pointer;
		margin-left: 10px;
		color: #666;
		font-size: 11px;
		border-bottom: 1px dotted #666;
		&.show-description .outlet-quality-description {
			opacity: 1;
			visibility: visible;
		}
	}
	.outlet-quality-label {
		margin-right: 5px;
	}
	.product-gnc-information {
		position: relative;
		margin: 0 15px 15px 15px;
		padding-bottom: 14px;
		border-bottom: 1px solid #ccc;
		.gnc-information-link {
			text-transform: uppercase;
			font-size: 11px;
			font-weight: bold;
			color: #333333;
			margin: 0;
			padding-left: 20px;
			&::after{
				@extend %icon-gnc;
				position: absolute;
				top: 0;
				left: 0;
				color: inherit;
				font-size: 16px;
			}
		}
	}
}
