#filter-box #filter-operator{
	display: none;
}
#filter-box #filter-stocks{
	overflow: visible;
	background: #fffbcc;
	padding: 10px;
	display: none;

	&+*{
		border-top: none;
		padding-top: 0;
	}

	.clear-selcted-shops{
		float:right;
		display:none;
		&.visible{
			display:block;
		}
	}

	.filter-stocks-header{
		overflow:auto;
	}

	label{
		display: block;
		padding-bottom: 10px;
		font-weight: bold;
		text-transform: uppercase;
		float:left;
	}

	.search{
		padding: 0 0 20px 0;
	}

	.filter{
		display: inline-block;
		margin: 0 0 0 -5px;
	}
}

html.operator-logged, html.operator-reserve-bar {
	#filter-box #filter-stocks, #filter-box #filter-operator{
		display: block;
		background: #FFFBCC;
		&+*{
			padding-top: 20px;
			border-top: 1px solid $border-color;
		}
	}
}


.product-stocks-box {
	background: #fffbcc;
	padding: 5px 10px;

	ul{
		li{
			padding: 4px 0;
			display: none;
			&.stocks-in-city, &.visible{
				display: block;
			}
			&.hidden{
				display:none;
			}
		}
	}

	.expand {
		@extend %btn-expand;
		color: $text-color;
		margin: 0 0 0 -8px;
		border: none;
		&:hover {
			border: none;
		}
		&:before {
			border-top-color: $text-color;
		}
	}
	.product-stocks {
		display: none;
		padding-top: 3px;
	}

	&.show-product-stocks {
		.expand {
			@extend %btn-collapse;
		}
		.product-stocks {
			display: block;
		}
	}

	.city-select {
		width: 224px;
		.select3-selection-icon {
			transform: none !important;
			border: none;
			margin-top: -15px;
			right: 8px;
			&:after{
				@extend %icon-search;
				color: #666;
			}
		}
	}

	.shops-list {
		padding: 10px 5px 5px 5px;
		.shop {
			padding: 4px 0;
		}
		span {
			font-weight: bold;
		}
	}
	.quantity {
		padding : 5px 0;
		span {
			font-weight: bold;
		}
	}
}

.best-voucher .best-voucher-container {
	border:0;
}
