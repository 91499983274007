#products{
	.product-tile{
		padding: 0;
		margin-bottom: 10px;
		position: relative;
		z-index:1;
		width:210px;
		box-sizing: border-box;

		.product-status {
			left: -1px;
			top: 10px;
		}
		>div{
			background:#fff;
			box-sizing: border-box;
			border:1px solid $border-color;
			width:100%;
		}
		&.product-top-list {
			border: 0;
			>div{
				border: 2px solid #45abe5;
			}
			.product-photo {
				margin-top: 10px;
			}
			.product-buttons {
				padding-bottom: 14px;
			}
			.product-more{
				width:110px;
			}
		}
		&.product-advance-sale {
			border: 0;
			>div{
				border: 2px solid $advance-sale-color;
			}
			.product-photo {
				margin-top: 10px;
			}
			.product-buttons {
				padding-bottom: 14px;
			}
			.product-more{
				width:110px;
			}
		}
		&:hover {
			z-index:2;
			.product-name{
				white-space: normal;
			}
			.price-promotion-date {
				display: block;
			}
			.product-desc {
				height: auto!important;
				&:after {
					display: none;
				}
			}
		}
		.product-unavailable {
			padding: 0 0 2px;
			line-height: 16px;
			display: inline-block;
			color: #e30613;
			font-weight: bold;
			white-space: nowrap;
			font-size: 15px;
			margin-right: 10px;
		}
		.product-photos, .product-photo {
			width: 206px;
			height: 185px;
			text-align:center;
			margin:10px auto 5px auto;
		}
		&.product-top-list .product-main{
			&::after {
				top: -211px;
				display: inline-block;
			}
		}
		&.product-advance-sale .product-main{
			&::after {
				top: -211px;
				display: inline-block;
			}
		}
		.product-main{
			width:auto!important;
			display:block;
			border:0;
			border-top:1px solid $border-color;
			padding:7px 15px 0;
		}
		.product-name{
			line-height: 19px;
			font-size:15px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		.price-promotion-date {
			display: none;
		}
		.product-price {
			padding:0 0 2px;
			/* EURSI-37170
			.price-old {
				display: inline-block;
				font-size:15px;
			}
			*/
			.price-normal{
				display:inline-block;
				font-size:15px;
				margin-right: 10px;
			}
			.price-instalments, .product-saved{
				display:none;
			}
		}
		.product-more{
			@extend %btn;
			@extend %btn-primary;
			width:112px;
		}
		.compare-button{
			float:right;
			min-width: 0!important;
			padding-right:0!important;
			width:35px!important;
			height:35px!important;
			text-align:center;
			&::after{
				content:"" !important;
			}
			&::before{
				margin-right:0;
			}
		}
		.product-energy{
			transform : scale(0.7);
			height: 42px;
			transform-origin: left top;
			margin-bottom:0;
		}

		.product-attributes .attributes-row{
			padding:0;
		}
		.product-buttons{
			margin-top: 5px;
			padding:0 15px 15px;
			button{
				margin-top:10px;
				width:100%;
			}
			.home-delivery-action{
				display:none;
			}
		}
		.stars-rating{
			margin: 0 0 8px 0;
		}
		.product-desc{
			padding:0 15px;
			overflow: hidden;
			position:relative;
			&[data-height="62"] {
				min-height: 62px;
				height: 62px;
			}
			&.product-desc-long{
				&::after{
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 12px;
					background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #fff 100%);
				}
			}
		}

		.advertising-placement {
			display: flex;
			flex-direction: column;
			margin-top: 5px;
			margin-bottom: 10px;
			flex-direction: column;

			.promotion-item {
				&:not(:first-child) {
					display: none;
				}
			}

			.more-promotions {
				@extend %btn-link;
				padding: 10px 0 0 0;
				text-transform: unset;
				font-weight: normal;
				margin-right: auto;
				display: block;

				span:before {
					content: attr(data-more-small);
				}
			}

			.promotion-block {
				display: block;
				border-left: 4px solid transparent;
				padding-left: 10px;
				font-weight: bold;
				line-height: 18px;

				&:hover {
					text-decoration: none;
				}

				.badge {
					display: inline-block;
					vertical-align: middle;
					padding: 1px 6px 3px 6px;
					border-radius: 2px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					max-width: 100%;
					margin-right: 3px;
				}

				.promotion-text {
					vertical-align: middle;
					color: $text-color;

					span {
						padding-right: 15px;

						&:hover {
							text-decoration: underline;
						}
					}

					i {
						margin-left: -15px;

						&:after {
							@extend %icon-delivery-info;
							width: 10px;
							margin-top: -2px;
							color: #828282;
							font-size: 14px;
						}
					}
				}
			}
		}

		.advertising-placement-outlet {
			display: none;
		}
	}
	&.tiles {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@media all and (max-width: #{$breakpoint-large}px) {
		.product-tile {
			margin-bottom: 25px;
		}
	}

}
