.remodal.service-modal{
	padding: 0;
	font-size: 14px;

	.header{
		display: inline-block;
		padding: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid $border-color;
	}

	.product-photo{
		float: left;
		margin-right: 15px;
		width: 80px;
		height: 80px;
		overflow: hidden;

		img{
			width: 100%;
		}
	}

	.product-info{
		float: left;
		width: 545px;
		color: $text-color;

		.name{
			margin-bottom: 5px;
			font-size: 24px;
			line-height: 22px;
			font-weight: bold;
		}

		.code{
			font-size: 12px;
			color: $text-color-lighter;
			margin-bottom: 15px;
		}

		.product-sales-box{
			display: inline-block;
			vertical-align: top;

			.price-old, .price-normal, .add-service-with-product, .price-promotion-date {
				display: inline-block;
				line-height: 31px;
				height: 31px;
				vertical-align: top;
			}
			/*
			.price-old{
				font-size: 16px;
				line-height: 27px;
				margin-right: 5px;
			}
			*/
			.price-normal{
				font-size: 24px;
				margin-right: 12px;
			}

			.price-promotion-date{
				padding: 0;
				margin-right: 12px;
			}
		}

		.actions{
			display: inline-block;
			vertical-align: top;

			.add-service-with-product{
				display: inline-block;
				vertical-align: top;
			}

			.btn{
				padding: 7px 15px;
			}
		}
	}

	.desc-wrapper{
		padding: 30px;
		max-height: 400px;

		.desc{
			.simplebar-content{
				padding-right: 10px;
			}
		}
	}

	.desc{
		ul{
			margin: 15px 0 15px 20px;

			li{
				position: relative;
				margin-bottom: 16px;

				&:before{
					@extend %icon-check;
					position: absolute;
					top: 1px;
					left: -20px;
					font-size: 11px;
					font-weight: bold;
					color: $link-color;
				}
			}
		}
	}
}
