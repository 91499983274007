.delete-watched-button {
	display: inline-block;
	text-transform: uppercase;
	height: 28px;
	line-height: 28px;
	font-weight: bold;
	font-size: 10px;

	&::before {
		padding-right: 3px;
		font-size: 19px;
		color: $link-color;
		@extend %icon-trash;
	}

	&:hover {
		opacity: 0.85;
		text-decoration: none;
	}
}

#watched-list {
	.statusDetails {
		white-space: nowrap;
	}
}

#max-clipboard-products-message {
	padding: 20px;
	text-align: center;
	h2 {
		margin-bottom: 20px;
	}
	p {
		margin: 0 auto;
	}
	.buttons {
		margin-top: 20px;
		text-align: center;
		button + button {
			margin-left: 10px;
		}
	}
	.button-next {
		@extend %btn-primary;
	}
	.button-back {
		@extend %btn-fourth;
	}
}
