@if(not $app-katalog) {
#account-box {
	position: absolute;
	top: 15px;

	@if ($app-euro) {
		right: 307px;
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 269px;
		}
		border-right: 1px solid #fff;
	} @else {
		right: 243px;
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 216px;
		}
		@media all and (min-width: 1440px) {
			right: 245px;
		}
		border-right: 1px solid #0a0a0a;
	}

	strong {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin: 0 0 2px;
		max-width: 120px;
		min-width: 107px;
	}
	&:hover {
		text-decoration: none;
	}
	> a {
		font-size: 12px;
		@if ($app-euro) {
			color: #fff;
		} @else {
			color: #0a0a0a;
		}
		position: relative;
		padding: 8px 16px 8px 41px;
		&:before {
			@extend %icon-user2;
			position: absolute;
			top: 8px;
			left: 0;
			font-size: 32px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	.account-menu-dropdown {
		&::after {
			margin-left: 5px;
			@extend %icon-caret-down;
			transition: all 0.2;
			.show-account-menu & {
				transform: scaleY(-1) translateY(2px);
			}
		}
	}
}

.show-account-menu {
	#account-menu {
		display: flex;
	}
}
// menu zalogowanego użytkownika
#account-menu {
	position: absolute;
	top: calc(100% - 11px);
	left: 0;
	display: none;
	flex-direction: column;
	width: 240px;
	padding: 8px 0;
	background: #FFFFFF;
	border: 2px solid #E5E5E5;
	border-radius: 7px;
	.account-menu-item {
		display: flex;
		border-bottom: 1px solid #E5E5E5;
	}
	.account-menu-link {
		display: flex;
		width: 100%;
		padding: 12px 16px;
		font-size: 14px;
		color: $text-color;
		&:hover {
			text-decoration: none;
		}
	}
	.account-menu-link-name {
		display: block;
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.account-menu-link-image {
		display: flex;
		width: 16px;
		height: 16px;
		margin-right: 8px;
		img {
			display: block;
			margin: 0;
			max-width: 100%;
			width: auto;
			height: auto;
			font-size: 0;
			overflow: hidden;
		}
	}
	.account-logged {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
	}
	.logout-button {
		margin-top: 5px;
		@extend %btn-ghost;
		font-weight: bold;
		&:hover {
			text-decoration: none;
		}
	}
}
}