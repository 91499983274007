@if(not $app-katalog) {
#main-menu {
	position:relative;
	font-size:$font-default-size;
	z-index:4;
	width:100%;
	display:table;
}

#top-menu {
	margin:0 auto;
	width:1218px;
	display:flex;
	&::before{
		content:"";
		position:absolute;
		top:0;
		left:0;
		width:100%;
		@if($app-ole) {
			height:39px;
			background: #00DC4B;
			border-bottom: 1px solid #ccc;
		} @else if($app-euro) {
			background: #3A3D40;
			height:39px;
		}
	}
	> li {
		height:39px;
		&:last-child {
			margin-left: auto;
		}
		@if($app-ole) {
			&:first-child {
				margin-left: 207px;
			}
		}

		> a {
			font-size: 12px;
			padding: 0 11px;
			white-space:nowrap;
			@media all and (min-width: 1440px) {
				font-size: 14px;
				font-weight: bold;
				padding: 0 12px;
			}
			color:$button-primary-color;
			text-transform:uppercase;
			height: 35px;
			margin-top: 4px;
			line-height: 31px;
			display:block;
			position:relative;
			text-align: center;
			@if($app-ole) {
				color:#0A0A0A;
			} @else if($app-euro) {
				color:#fff;
			}

			&:hover {
				text-decoration:none;
			}
		}
		&.active {
			> a {
				text-decoration:none;
				
				@if($app-ole) {
					color: $text-color;
					background: #fff;
				} @else if($app-euro) {
					color: $text-color;
					background: #fff;
				}
			}
		}
		&.unavailable {
			cursor:pointer;
			pointer-events:none;
		}
	}
}

#second-menu {
	text-align:left;
	display:none;
	position: absolute;
	width: 100%;
	&.active {
		display: block;
	}
	> li {
		display:none;
		background-color:#F7F7F7;
		padding: 10px 0 35px 0;
		&.active {
			display:block;
		}

		a{
			color:$text-color
		}
	}
}

@media all and (max-width: #{$breakpoint-large}px) {
	#top-menu {
		width:#{$breakpoint-midium-width}px;
		> li {
			> a {
				font-size:12px;
				white-space:nowrap;
			}
		}
	}
	#second-menu {
		img {
			width: 930px;
			margin: 0 auto;
		}
	}
}
@media all and (min-width: 1440px) {
	#top-menu {
		width: 1376px;
	}
}
}
.menu-open {
	body:before {
		content: "";
		background: rgba(43,46,56,.9);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 4;
	}
}
.new-menu {
	#main-menu {
		display: none;
	}
	&.menu-open body {
		overflow: hidden;
	}
}
.old-menu {
	#headless-main-menu { 
		display: none;
	}
}