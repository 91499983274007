@charset "UTF-8";

@if($app-katalog) {
.app-katalog #to-cart{
	background: #f7f4f4;
	.to-cart-header{
		display: none;
	}
	#added-product{
		background:#fff;
	}
}
.to-cart-buttons{
	padding:10px 15px;
	margin:10px 0;
	background:#fff;
	float:left;
	width: 942px;
	box-sizing: border-box;
	@media all and (max-width: #{$breakpoint-large}px) {
		width: 700px;
	}
	overflow: hidden;
	clear: both;
	.go-to-basket {
		@extend %btn;
		@extend %btn-primary;
		float:right;
	}

	.go-back {
		@extend %btn;
		@extend %btn-fourth;
		float:left;
	}
}

#to-cart {
	.message {
		margin: 10px 0;
		.message-content {
		}
		@if($app-katalog){
			background: #fff;
			border:2px solid $button-primary-background;
			padding: 15px 10px;
			font-weight: normal;
			text-align: center;
			font-size: 14px;
		}@else{
			background: #d0dff2;
			padding: 15px 10px;
			text-align: center;
			.message-content {
				font-weight: bold;
				font-size: 15px;
				text-transform: uppercase;
			}
			&.message-product-added {
				margin: 15px 0;
				padding: 17px;
				background: #D9F3E7;
				.message-content {
					font-size: 18px;
					text-transform: uppercase;
					font-weight: bold;
					color: #00B162;
					&:before {
						@extend %icon-check;
						width: 20px;
						height: 20px;
						background: #00B162;
						color: #D9F3E7;
						border-radius: 50%;
						font-size: 12px;
						text-align: center;
						line-height: 20px;
						margin-right: 5px;
						position: relative;
						top: -1px;
					}
				}
			}
		}
	}


	.to-cart-header {
		@if ($app-katalog) {
			background-color: #eaeaea;
			padding: 5px 15px;
			font-size: 24px;
			width: 942px;
			float: left;

			@media all and (max-width: #{$breakpoint-large}px) {
				width: 700px;
			}
		}
		font-weight: bold;
		color: #1c1c1b;
		font-size: 18px;
		margin: 0;
		box-sizing: border-box;

		p {
			margin: 0;
			padding: 0;
		}
	}
}

#added-product {
	float: right;
	margin-bottom: 10px;
	background: #fff;
	padding: 0;
	width: 245px;

	.added-product-content {
		padding: 15px;
		background: #EAEAEA;
	}

	#count-accesories {
		font-weight: bold;

		&:not(:empty) {
			padding-bottom: 10px;
		}
	}

	.product-name {
		font-weight: bold;
		font-size: 16px;
	}

	.product-category {
		color: $text-color-lighter;
	}

	.subscription-price {
		align-items: flex-start;
		p {
			text-align: left;
		}
		.price-monthly {
			font-size: 20px;
		}
	}
	.promotion-label{
		background: #F50A1B;
		position: absolute;
		padding: 1px 10px;
		color:#FFF;
		text-transform: uppercase;
		display: table;
		text-align:center;
		border-radius: 20px;
		margin-top:-3px;
		font-weight: bold;
		font-size:12px;
		margin-bottom: 5px;
		top:10px;
		left:10px;
	}
	.price-promotion-label{
		color:$promotion-price-color;
		font-size:14px;
		margin:2px 0 5px;
	}
	.product-price {
		padding: 5px 0;

		.price-normal {
			font-size: 20px;
		}
		&.price-promotion {
			.price-instalments-rates, .price-normal{
				color: $promotion-price-color;
			}
		}
		&.product-prices-instalment{
			.price-normal {
				font-size: 12px;
				font-weight: normal;
				display:inline-block;
			}
			/*
			.price-old {
				font-size: 12px;
				display:inline-block;
				margin-left:10px;
			}
			*/
			.price-instalments-rates{
				font-size:16px;
				//color:#ED1C24;
				font-weight: bold;
			}
		}
		.price-omnibus{
			text-align:left;
			font-size:14px;
			margin-top:2px;
			margin-bottom: 5px;
		}
	}

	h2 {
		padding: 0;
		margin: 0;
		border-bottom: 1px solid #ccc;
	}

	.product-photo {
		border: 1px solid #ccc;
		background: #fff;
		display: inline-block;
		width: 190px;
		padding: 0 12px;
		height: 169px;
		margin: 0 auto;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.product-button {
		a {
			@extend %btn;
			display: block;
		}

		.go-to-basket {
			@extend %btn-primary;
			font-size: 16px;
			padding: 18px;
		}

		.go-back {
			margin-top: 10px;
			@if ($app-euro) {
				@extend %btn-third;
				@extend %btn-small;
			} @else {
				background: #fff;
				border: 1px solid #0191c9;
				color: #0191c9;
				padding: 5px;
			}
			margin-left: 15px;
			margin-right: 15px;
			padding: 12px;
		}
	}
	#instalment-calculator{
		display:none;
	}
}
}

#suggested-accessories{
	float: left;
	width: 942px;
	padding:0 15px;
	box-sizing: border-box;
	@media all and (max-width: #{$breakpoint-large}px) {
		width: 700px;
	}
	&.loading {
		@extend %loading;
		height: 200px;
	}
}
.remodal.suggested-accessories-modal{
	width: 930px;
	max-width: 930px;
	.group{
		width: auto;
	}
}
.suggested-accessories{
	background: #fff;
	min-height: 300px;
	.group{
		width: 440px;
		float:left;
		position: relative;
		z-index: 1;
		&:hover{
			z-index:2;
		}
		&:nth-child(odd){
			margin-right: 32px;
		}
	}
	a:hover{
		text-decoration: none;
	}
	.header{
		font-size:14px;
		font-weight: bold;
		min-height: 20px;
		line-height: 15px;
		padding:10px 0;
		&:first-letter {
			text-transform: uppercase;
		}
		button {
			@extend %btn-small;
			margin-left:10px;
			width:120px;
		}
		button:not(:disabled){
			@extend %btn-primary;
		}
		button:disabled{
			@extend %btn-disabled;
		}
	}
	.list{
		margin-bottom: 20px;
	}
	.accessory-item{
		display:inline-block;
		position: relative;
		z-index:1;
		width:215px;
		min-height:235px;
		float: left;
		margin-right:10px;
		margin-bottom: 10px;

		&:hover{
			.product-attributes{
				display: block;
			}
			z-index:2;
		}
		&:last-child{
			margin-right:0;
		}
		>div{
			background: #fff;
			box-sizing: border-box;
			border: 1px solid $border-color;
			width: 100%;
			padding:10px;
		}
	}
	.product-title{
		a{
			color:$text-color;
			font-size:14px;
		}
		height:51px;
		overflow: hidden;
	}
	.product-image{
		position: relative;
		text-align: center;
		a{
			display: block;
			margin: 0 auto;
			width: 102px;
			height: 91px;
		}
		img{
			width: 100%;
			height: 100%;
		}
	}
	.subscription-price {
		margin-top: 10px;
		align-items: flex-start;
		p {
			text-align: left;
			font-size: 11px;
		}
		.price-monthly {
			font-size: 17px;
		}
	}
	.product-prices{
		margin-top:10px;
		height:35px;
		overflow: hidden;
		div{
			display: inline-block;
		}
		.price-instalments-rates{
			float: right;
			//color:red;
			margin-top:5px;
		}
	}
	.price-normal{
		font-size:17px;
	}
	.product-buttons{
		margin-top:5px;
		height: 30px;
		> *{
			z-index:2;
		}
	}
	.product-attributes{
		margin-top:10px;
		display: none;
		font-size: 11px;
	}
}

@if(not $app-katalog) {
#to-cart{
	.page {
		@media all and (min-width: 1440px) {
			width: 1380px;
		}
		*{
			box-sizing: border-box;
		}
	}
	#product-added-message, #from-path-message{
		background: #f3ffec;
		display: flex;
		align-items: center;
		box-shadow: 0px 2px 7px 0px #28292A1A;
		border:1px solid #E5E5E5;
		padding: 14px 12px;
		margin:8px 0 0;
		border-radius: 7px;
		
		.text-message{
			font-size:20px;
			font-weight: bold;
			&:before{
				@extend %icon-check;
				color: #00ae50;
				margin: 0 11px 0 21px;
				font-weight: bold;
			}
		}
		.product-content{
			overflow: hidden;
		}
		.product-name{
			font-size: 16px;
			padding-left: 58px;
			margin-top: 13px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&::first-letter{
				text-transform: uppercase;
			}
			span{
				font-weight: bold;
			}
		}
		.buttons{
			display: flex;
			margin-left:auto;
		}
		.back{
			font-weight: bold;
			margin-right:13px;
			display: inline-block;
			padding: 0 22px;
			font-size:16px;
			height: 48px;
			line-height: 48px;
			white-space: nowrap;
			&:hover{
				text-decoration:none;
			}
			&:before{
				@extend %icon-arrow;
				transform: rotate(180deg);
				margin-right:4px;
				position: relative;
				top:-2px;
			}
		}
		.next{
			box-shadow: 0px 1px 3px 0px #30313333;
			background: #016EF4;
			padding: 0 22px;
			border-radius: 100px;
			color:#fff;
			display: inline-block;
			text-align: center;
			width:184px;
			font-size:16px;
			white-space: nowrap;
			font-weight: bold;
			height: 48px;
			line-height: 48px;
			&:hover{
				text-decoration:none;
			}
		}
		.product-image{
			width: 80px;
			height: 80px;
		}
	}
	#common-message{
		background: #E5E5E5;
		padding: 13px 16px;
		font-size: 24px;
		font-weight:bold;
		margin-top:16px;
	}
}
}
