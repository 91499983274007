.product-archive {
	width: 240px;
	padding: 15px 0 0 15px;
	box-sizing: border-box;

	h1, h2, h3, h4, h5, h6 {
		padding: 0;
	}

	.product-photos {
		width: 100%;
		margin-bottom: 15px;
		text-align: center;
		img {
			width: 205px;
			height: 191px;
			display:block;
			margin:0 auto;
		}
	}

	.product-header {
		max-height: 65px;
		margin-bottom: 10px;
		overflow: hidden;

		h1, h2 {
			margin-top: 0;
			margin-bottom: 5px;
			font-size: 16px;
			line-height: 20px;
		}

		a {
			color: $text-color;
		}
	}

	.product-unavailable {
		margin-bottom: 10px;
		color: #e63327;
		line-height: 20px;

		h3 {
			font-size: 16px;
		}
	}

	.product-price {
		margin-bottom: 20px;

		.price-normal {
			font-size: 40px;
			line-height: 35px;
		}
		/* EURSI-37170
		.price-old {
			display: inline-block;
			font-size: 12px;
			margin-right: 10px;
		}
		*/

		.price-promotion-date {
			margin-top: 3px;
			color: $text-color-lighter;
		}
	}

	.product-button {
		a {
			display: block;
			margin-bottom: 10px;

			&.btn:last-child {
				margin-bottom: 0px;
			}
		}

		button {
			width: 100%;
			margin-bottom: 10px;
			&:last-child { margin: 0; }
		}
	}

	&.original-product {
		width: 225px;
		padding-right: 15px;
		background: #eaeaea;

		.product-photos {
			img { width: 195px; }
		}
	}

	&.promotion-available {
		.price-normal {
			margin-bottom: 10px;
		}
	}
}
