.product-attributes{
	.attributes-row {
		padding-bottom: 5px;
	}
	.attribute-name, .attribute-value, a {
		font-size: 12px;
	}
	a{
		color:$text-color;
		border-bottom:1px dotted $text-color;
		&:hover {
			color:$text-color;
			text-decoration: none;
		}
	}
	.attribute-name{
		&:after{
			content:":";
			@if ($app-ole) {
				margin-left: -6px;
			} @else {
				margin-left: -4px;
			}
		}
	}
	.attribute-value {
		font-weight: bold;
	}
}
