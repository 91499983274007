
.banners-scroll_footer {
	background: #ccc;
	bottom: 0;
	display: none;
	position: absolute;
	width: 100%;
	img {
		display: block;
		height: auto;
		width: 100%;
		will-change: transform;
	}
}

.banners-scroll_footer.is-visible img {
	position: fixed;
	bottom: 0;
}

.page-init .banners-scroll_footer {
	display: block;
}

.show-bottom-bar #main-footer{
	padding-bottom:42px;
}
#main-footer {
	box-shadow: 0 20px 30px -20px rgba(0,0,0,.8);
	min-width: 960px;
	background: #eaeaea;
	width: 100%;
	@if($app-euro){
		border-top: 5px solid #FFE843;
	}@else{
		border-top: 5px solid #39913d;
	}
	margin-bottom: -30px;
	padding-bottom:10px;
	z-index:1;
	.footer-heading {
		font-size: 22px;
		color: #333;
		padding: 0;
		font-weight: bold;
	}
	.container {
		width: #{$breakpoint-large-width}px;
		@media all and (max-width: #{$breakpoint-large}px) {
			width: #{$breakpoint-midium-width}px;
		}
		margin: 0 auto;
		overflow: hidden;
	}
	#footer-logo-brands, #footer-owner, #footer-owner-address{
		width: #{$breakpoint-midium-width}px;
		margin:0 auto;
	}
	.footer-row {
		width: 100%;
		clear:both;
		overflow:hidden;
	}
	#footer-shops {
		border-bottom: 1px solid $border-color;
		padding-bottom: 25px;
		padding-top: 17px;
		overflow: hidden;
		p {
			width: 100%!important;
		}
	}
	#footer-contact {
		border-bottom: 1px solid $border-color;
		.column:first-child p {
			width: 100%;
		}
		margin-top: 0;
		padding-top:37px;
		padding-bottom: 22px;
	}
	p {
		padding-top: 10px;
		font-size: 15px;
		display: inline-block;
		vertical-align: top;
		line-height: 20px;
		width: 297px;
	}
	.column {
		box-sizing: border-box;
		float: left;
		width: 50%;
		position: relative;
	}
	.providers {
		margin:0 auto;
	}
	.footer-chat {
		&::before {
			margin-right: 10px;
			@extend %icon-chat;
			font-size:26px;
		}
	}
	.footer-phone {
		&::before{
			@extend %icon-phone2;
			font-weight:bold;
			font-size:56px;
			margin-right:5px;
		}
		font-size:25px;
		white-space:nowrap;
		left: 232px;
		position: absolute;
		top: -11px;
		font-weight:bold;
	}
	.find-shop {
		text-transform: uppercase;
		font-size: 13px;
		display: block;
		padding: 16px;
		padding-left: 0;
		&:before {
			margin-right: 10px;
			font-size: 22px;
			@extend %icon-search;
		}
	}
	.footer-contact{
		display: block;
		@if($app-euro){
			font-size:24px;
		}@else{
			font-weight: bold;
			font-size:16px;
		}
		margin-top: 3px;
	}
	.privacy-policy {
		float: left;
		margin-top: 6px;
		margin-left: 25px;
	}
}
#footer-menu {
	div + div {
		display: inline-block;
		@if($app-euro){
			padding-left: 72px;
		}@else{
			padding-left: 71px;
		}
	}
	div a {
		float: left;
		clear: left;
		color: #959595;
		padding: 10px 0 10px;
	}
}
#footer-owner,
#footer-owner-address {
	text-align: center;
	width: 100%;
	display: block;
	font-size: 12px;
	color:$text-color-lighter;
	min-height: 17px;
}

.find-shop-map {
	background: url($img-url+'footer-map.png') no-repeat center center;
	display: inline-block;
	@if($app-euro){
		width:115px;
		height:108px;
	}@else{
		width:147px;
		height:138px;
	}

	position: absolute;
	top: -8px;
	right: 7px;

}
.footer-chat-sign,
.footer-chat {
	margin-top: 16px;
}
.footer-chat {
	margin-left: 16px;
}
.footer-chat-sign {
	display: inline-block;
	&:before {
		margin-right: 10px;
		@extend %icon-hand;
		font-size:25px;
	}
}
#footer-menu {
	margin:13px 0;

}
.see-more {
	float: left;
	margin-top: 6px;
	margin-left: 1px;
}

html.footer2bottom {
	body {
		position: relative;
	}

	footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

#footer-links {
	padding-top: 20px;
	border-top: 1px solid #E1E1E1;
	margin: 40px auto 0;
	width: #{$breakpoint-large-width}px;
	@media all and (max-width: #{$breakpoint-large}px) {
		width: #{$breakpoint-midium-width}px;
	}

	&.open {
		.row {
			.column:nth-child(n+6) {
				display: block;
			}
		}

		.toggle-links {
			.toggle-footer-link {
				&::before {
					content: 'Zwi\0144';
				}
				&::after {
					transform: rotate(270deg);
				}
			}
		}
	}
	
	.row {
		display: flex;
		flex-wrap: wrap;
		width: 100%;

		.column:nth-child(n+6) {
			display: none;
		}
	}

	.column {
		box-sizing: border-box;
		flex-basis: 20%;
		margin-bottom: 15px;

		&:not(:first-child) {
			padding-left: 15px;
		}

		&:nth-child(5n + 1) {
			padding-left: 0;
		}

		.column-title {
			color: #666666;
			font-weight: bold;
			padding: 0;
			margin-bottom: 12px;
		}

		li {
			margin-bottom: 10px;
		}

		a {
			color: #999;
		}

	}

	.toggle-links {
		text-align: center;

		.toggle-footer-link {
			text-transform: uppercase;
			&::before {
				content: 'Rozwi\0144';
			}
			&::after {
				@extend %icon-arrow;
				position: relative;
				top: -1px;
				transform: rotate(90deg);
				margin-left: 7px;
			}
		}
	}
}