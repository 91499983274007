.product-sales {
	.product-sales-tabs {
		display: flex;
		justify-content: space-between;
		position: relative;
		background: #fff;
		border: 1px solid #eaeaea;
	}

	.product-categories {
		max-width: 245px;
	}

	.outlet-learn-more {
		display: inline-block;
		position: relative;
	}

	.shop-delivery-info {
		position: relative;
		margin-bottom: 4px;
		@if ($app-euro) {
		&:before {
			color: #1C1C1B;
			font-size: 15px;
			width: 20px;
			margin: 0 1px 0 2px;
			@extend %icon-location;
		}
	}
	}

	.outlet-quality-category {
		display: block;
		position: relative;
		@if ($app-euro) {
		&:before {
			color: #1C1C1B;
			font-size: 15px;
			width: 20px;
			margin: 0 1px 0 2px;
			@extend %icon-box-closed;
		}
		}

		&.show-description .outlet-quality-description {
			opacity: 1;
			visibility: visible;
		}
	}

	.oulet-learn-more-description,
	.outlet-quality-description {
		box-sizing: border-box;
		visibility: hidden;
		opacity: 0;
		transition: .3s;
		position: absolute;
		width: 200px;
		background: #fff;
		left: 50%;
		display: block;
		padding: 5px 8px 5px 8px;
		box-shadow: 0 2px 3px rgba(0,0,0,.25);
		transform: translateX(-50%);
		text-transform: none;
		bottom: 160%;
		border-radius: 4px;
		&:after {
			content: '';
			cursor: pointer;
			position: absolute;
			display: block;
			width: 0;
			top: 100%;
			height: 0;
			width: 10px;
			height: 10px;
			background: #fff;
			transform: translateY(-50%) rotate(45deg);
			box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.20);
			@if ($app-ole) {
				right: 30px;
			} @else {
				left: 45px;
			}
		}
	}

	.oulet-learn-more-description {
		&:after {
			right: 50%;
			transform: translateY(-50%) translateX(50%) rotate(45deg);
		}
	}

	.outlet-quality-description {
		color: #333;
		font-weight: normal;
		right: 0;
		transform: translateX(0);
		@if ($app-ole) {
			left: auto;
		}
	}

	.oulet-learn-more-icon {
		background: #f8f8f8;
		border-radius: 50%;
		cursor: pointer;
		color: $link-color;
		display: block;
		font-style: unset;
		height: 15px;
		line-height: 15px;
		margin-left: 2px;
		width:15px;
		text-align: center;
		&:hover + span {
			visibility: visible;
			opacity: 1;
		}
	}

	.sales-tabs-link {
		cursor: pointer;
		font-size: 12px;
		text-transform: uppercase;
		padding: 10px;
		border-radius: 0;
		&.is-active {
			background: #eaeaea;
			.tab-price {
				display: none;
			}
			&:not(.has-children) {
				cursor: default;
				pointer-events: none;
			}
		}
		.tab-label {
			font-weight: bold;
		}
	}

	.tab-outlet {
		flex: 1;
		text-align: center;
		&.has-children {
			.tab-label:after {
				@extend %icon-arrow;
				transform: rotate(90deg);
				margin-left: 5px;
			}
		}
		&.is-open + .tab-dropdown {
			display: block;
		}
	}

	.tab-dropdown {
		box-sizing: border-box;
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		background: #fff;
		box-shadow: 0 2px 3px rgba(0,0,0,.3);
		align-items: center;
		padding: 10px;
		z-index: 2;
		cursor: default;
	}

	.tab-dropdown-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.select-quality {
		@extend %btn;
		@extend %btn-primary;
		padding: 0 10px;
		font-size: 10px;
		height: 18px;
	}

	.product-sales-category {
		background: #eaeaea;
		&:not(.is-active) {
			display: none;
		}
	}

	.choose-individual-offer {
		width: 100%;
		font-size: 14px;
		text-align: center;
		color: #999;
		margin-left: -5px;
		&:before {
			@extend %icon-arrow-down-long;
			transform: rotate(90deg);
			margin-right: 15px;
		}
	}

	.product-sales-category-outlet {
		.home-delivery-action {
			color: if($app-ole, $primary-color, $outlet-color);
		}
		.add-to-cart {
			margin-bottom: 0;
		}
		.price-normal {
			color: $text-color;
		}
		.price-instalments {
			color: $text-color;
			i {
				border-bottom-color: $outlet-color;
			}
		}
		.shopping-options {
			@extend %btn;
			@extend %btn-big;
			box-sizing: border-box;
			color: #fff;
			width: 100%;
			background: if($app-euro, $outlet-color, $primary-color);
			@if ($app-ole) {
				font-size: 18px;
				padding: 16px 12px
			}
			&:hover {
				color: #fff;
				background: lighten(if($app-euro, $outlet-color, $primary-color), 5%);
			}
		}
	}

	.stock-info {
		color: #999;
	}
}

#product-tabs[data-tab="outlet"] {
	#product-for-sale .product-button:not(.product-button-outlet) .action-primary{
		display: none;
	}
	#product-for-sale .product-button-outlet{
		display: block;
		margin-top:-5px;
		.action-primary{
			padding-top:0;
		}
	}
}
#product-for-sale {
	.product-sales-tabs {
		margin-top: 20px;
	}
	.product-sales-category-outlet {
		margin-bottom: 15px;
		.add-to-cart {
			background: if($app-euro, $outlet-color, $primary-color);
			color: #fff;
			&:hover {
				background: lighten(if($app-euro, $outlet-color, $primary-color), 5%);
			}
		}
	}
}
