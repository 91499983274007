
.promotion-bar-show #promotion-bar{
	display:block;
}

#promotion-bar {
	display:none;
	min-width:#{$breakpoint-midium-width}px;
	max-width:#{$breakpoint-large-width}px;
	margin: 0 auto;
	.item{
		position:relative;
		min-height:20px;
		background:$promotion-bar-background;
		font-size:13px;
		color:#fff;
		>div{
			max-height:100%;
			padding:10px 30px 10px 20px;
		}
		a{
			color:#E9C531;
		}
		>a{
			color:#ffffff;
			position:absolute;
			top:10px;
			right:10px;
			&:hover{
				opacity:0.8;
			}
			&::after{
				@extend %icon-x;
				font-size:15px;
				vertical-align:top;
			}
		}
	}
}
