

	$max-photos:16;
	
	#big-photo {
		display: block;
		position: relative;
		width: 335px;
		height: 298px;
		.hover {
			position: absolute;
			top: 0;
			left: 0;
			visibility: hidden;
			opacity: 0.1;
			&.is-active {
				visibility: visible;
				opacity: 1;
				transition: opacity 0.5s ease-in-out;
			}
		}
		img {
			width: auto;
			height: 100%;
		}
		&:hover::after {
			opacity: 1;
		}
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -14px;
			margin-left: -14px;
			z-index: -1;
			height: 28px;
			width: 28px;
			animation: rotate360 0.8s infinite linear;
			border: 8px solid #eaeaea;
			border-right-color: transparent;
			border-radius: 50%;
		}
		@keyframes rotate360 {
			0%	{ transform: rotate(0deg); }
			100%	{ transform: rotate(360deg); }
		}
		&::after {
			@extend %icon-search;
			left: 50%;
			margin-left: -19px;
			margin-top: -19px;
			position: absolute;
			top: 50%;
			text-align:center;
			color:$text-color;
			font-size:23px;
			line-height:38px;
			transition: all 0.3s ease 0s;
			background-color: #fff;
			opacity: 0;
			width:38px;
			height:38px;
			background:#ffffff;
			border: 1px solid #ccc;
		}
	}
	#miniatures {
		margin-top: 10px;
		position: relative;
		#miniatures-previous {
			&::before{
				@extend %icon-big-arrow;
				font-size:30px;
				width:32px;
				height:58px;
				text-align:center;
				line-height:56px;
				transform: scaleX(-1);
				color:$text-color-lighter;
				font-weight: bold;
			}
			position: absolute;
			top: 5px;
			left: 0;
			&.disabled {
				pointer-events: none;
				opacity: 0.3;
			}
		}
		#miniatures-next {
			&::before{
				@extend %icon-big-arrow;
				font-size:30px;
				width:32px;
				height:58px;
				text-align:center;
				line-height:56px;
				color:$text-color-lighter;
				font-weight: bold;
			}
			position: absolute;
			top: 5px;
			right: 0;
			&.disabled {
				pointer-events: none;
				opacity: 0.3;
			}
		}
	}
	.no-carousel {
		#miniatures-previous, #miniatures-next {
			display: none!important;
		}
		#miniatures-list {
			text-align: center;
			.miniatures-carousel {
				display: inline-block;
				width: auto;
				position: relative;
			}
		}
	}
	#miniatures-list {
		position: relative;
		height: 56px;
		overflow: hidden;
		width: 273px;
		padding: 5px 0;
		margin: 0 30px;
		ul {
			position: absolute;
			margin-left: 1px;
			width: $max-photos * 68px;
		}
		li {
			display: block;
			width: 68px;
			height: 56px;
			line-height: 56px;
			float: left;
			a {
				display: block;
				height: 100%;
				text-align: center;
				width: 100%;
				img {
					height: 100%;
					width: auto;
				}
			}
		}
		.miniatures-carousel {
			transition: all 0.5s ease;
		}
		.miniatures-photo {
			opacity: 0.4;
		}
		.miniatures-photo-active {
			opacity: 1!important;
		}
		@for $i from 0 through $max-photos {
			.shift-#{$i} { left: 0px - 68px * $i; }
		}
	}
	.miniatures-video {
		a::before{
			@extend %icon-video;
			font-size:61px;
			color:$text-color-lighter;
		}
	}
	.miniatures-360-view {
		a::before{
			@extend %icon-360;
			font-size:61px;
			color:$text-color-lighter;
		}
	}
