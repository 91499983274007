@if(not $app-katalog) {
#top{
	z-index:5;
	position: relative;
	@if($app-ole){
		background-color: #00dc4b;
	} @else {
		background-color: #ffffff;
	}
}

#header{
	
	*{
		box-sizing: border-box;
	}
	z-index:2;
	position:relative;
	margin: 0 auto;
	@if($app-ole){
		background-color: transparent;
	} @else if($app-euro){
		background-color: #3A3D40;
	}


	.header-content {
		position: relative;
		margin: 0 auto;
		height: 100%;
		width: 1218px;
		@media all and (min-width: 1440px) {
			width: 1380px;
		}
		
		@media all and (max-width: #{$breakpoint-large}px) {
			width: #{$breakpoint-midium-width}px;
		}
		
	}
	
	height: 80px;
	.links {
		position:absolute;
		height: 36px;
		left: 0;
		line-height: 36px;
		text-transform: uppercase;
		li {
			white-space:nowrap;
			display:inline-block;
			margin-right: 18px;

			&:nth-last-child(1) {
				margin-right:0;
			}
			&.chat {
				a {
					&::before{
						font-size: 15px;
						margin:-2px 3px 0 0;
						@extend %icon-chat2;
					}
					&:hover {
						text-decoration:none;
					}
				}
			}
			&.contact {
				a {
					font-weight:bold;
					color: $text-color;
					&::before{
						margin-right:4px;
						font-size: 15px;
						@extend %icon-phone2;
					}
					&:hover {
						text-decoration:none;
					}
				}
			}
		}
		a {

			font-weight:normal;
			font-size:12px;
			@if($app-euro){
				color:#005ca9;
			}
			@if($app-ole){
				&.highlighted {
					font-weight: bold;
					color: #FF0000;
				}
			}

		}
	}

	#logo {
		position:absolute;
		margin:0;
		@if($app-euro) {
			width:273px;
			height:48px;
			left:1px;
			top:15px;
			@media all and (max-width: #{$breakpoint-large}px) {
				width: 215px;
				top: 20px;
				height:35px;
			}

		} @else {
			width:144px;
			height:84px;
			left: 1px;
			top: 16px;
		}
		img { max-width: 100%; }
	}
}


	#favorite-box, #shops-box, #contact-box{
		position:absolute;
		text-align: center;
		@if($app-euro){
			color:#fff;
		}@else{
			color:#0a0a0a;
		}
		font-size:12px;
		@if($app-euro){
			top:18px;
		}@else{
			top:20px;
		}

		font-weight:normal;
		&:before{
			display: block !important;
			height: 25px;
			width:100%;
			min-width: 30px;
		}
		&:hover{
			text-decoration: none;
		}
	}
	#favorite-box{
		right: 70px;
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 54px;
		}
		width:74px;
		&:before{
			@extend %icon-favorite;
			font-size: 27px;
			margin-left: 2px;
			height: 25px;
		}
		span{
			border: 2px solid #fff;
			background: #f50a1b;
			min-width: 10px;
			padding: 0 5px;
			height: 19px;
			line-height: 15px;
			right: 14px;
			top: -2px;
			color: #fff;
			font-size: 10px;
			border-radius: 10px;
			font-weight: 700;
			display: block;
			position: absolute;
			text-align: center;
			z-index: 1;
			&:empty{
				display:none;
			}
		}
	}
	#shops-box{
		right:249px;
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 211px;
		}
		width: 36px;
		&:before{
			@extend %icon-shops;
			font-size: 20px;
			position: relative;
			top: 3px;
		}
	}
	#contact-box{
		@if ($app-euro) {
			right: 154px;
		}@else{
			right: 156px;
		}
		@media all and (max-width: #{$breakpoint-large}px) {
			right: 128px;
		}
		width:78px;
		&:before{
			@extend %icon-callCenter;
			font-size: 24px;
			top: 1px;
			position:relative;
		}
	}
}