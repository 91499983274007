.product-variants {
	margin: 15px 0 0;
	position: relative;
	width: 100%;
	&:empty {
		display:none;
	}
	.variant {
		display: inline-block;
		margin-bottom: 10px;
		position: relative;
	}
	.current-variant {
		background:#fff;
		border: 1px solid #ccc;
		cursor: pointer;
		margin: 0 5px 0 0;
		padding: 6px 15px 6px 15px;
		position: relative;
		@if ($app-ole) {
			border-radius: 25px;
		}
		&:before {
			content: "";
			position: absolute;
			bottom: -11px;
			width: 12px;
			height: 12px;
			left: 0;
			right: 0;
			margin: 0 auto;
			display: none;
			z-index: 3;
			background: #FFFFFF;
			border-left:1px solid #ccc;
			border-top:1px solid #ccc;
			transform:rotate(45deg);
		}
		&::after {
			@extend %icon-arrow-down;
			color: $link-color;
		}
		&.variant-not-available{
			border:1px solid red;
		}
	}
	.variant-list {
		box-sizing: border-box;
		background: #fff;
		border: 1px solid #ccc;
		display: block;
		visibility: hidden;
		left: 0;
		margin-top: 3px;
		position: absolute;
		padding: 8px 0 2px 0;
		z-index: 2;
		overflow-x: auto;
	}
	.variant-item {
		border: 1px solid #ccc;
		color: $link-color;
		font-weight: bold;
		float: left;
		line-height: 30px;
		margin: 0 5px 5px 5px;
		padding: 0 10px;
		text-transform: uppercase;
		white-space: nowrap;
		@if ($app-ole) {
		  border-radius: 25px;
		}
		&.active-item:not(.variant-unavailable) {
			background: $link-color;
			border-color: $link-color;
			color: #fff;
		}
		&.variant-unavailable{
			background: #f2f2f2;
			color: #444;
			font-size:10px;
			height: 30px;
			text-align: center;
			line-height: 15px;
			&:after{
				content:"niedost\0119pny";
				text-transform: uppercase;
				display: block;
				line-height: 13px;
				font-size:10px;
				font-weight: normal;
				text-align: center;
				color: #666e7b;
			}
			&:hover{
				text-decoration: none;
			}
		}
		&:not(.variant-unavailable):hover {
			color: #fff;
			background: $link-color;
			border-color: transparent;
			text-decoration: none;
			backface-visibility: hidden;
			transition: color 0.5s ease-in-out, background 0.5s ease-in-out, border-color 0.5s ease-in-out;
		}
		&.long-name {
			overflow: hidden;
			text-overflow: ellipsis;
			box-sizing: border-box;
		}
		&.variant-not-available{
			border:1px solid yellow;
		}
	}
	%productsCountLabel {
		border-radius: 50%;
		content: attr(data-products-count);
		display: inline-block;
		font-size: 9px;
		line-height: 17px;
		margin-left: 3px;
		height: 17px;
		text-align: center;
		width: 17px;
	}
	.value[data-products-count]:after {
			@extend %productsCountLabel;
			background: $link-color;
			color: #fff;
	}
	.variant-item[data-products-count] {
		&:after {
			@extend %productsCountLabel;
		}
		&.active-item:after {
			background: #fff;
			color: $link-color;
		}
		&:not(.active-item):after {
			background: $link-color;
			color: #fff;
		}
		&:hover:after {
			background: #fff;
			color: $link-color;
			transition: color 0.5s ease-in-out, background 0.5s ease-in-out;
		}
		.variant-price:before {
			content: 'od ';
		}
	}
	.name, .value {
		text-transform: uppercase;
		display: inline-block;
	}
	.value {
		font-weight: bold;
		color: $link-color;
	}
	.variant-active {
		.variant-list {
			visibility: visible;
		}
		.current-variant:before {
			display: block;
		}
		.current-variant:after {
			@extend %icon-arrow-up;
			color: $link-color;
		}
	}
}

#product-archive .product-variants{
	background: #EAEAEA;
	box-sizing: border-box;
	display: inline-block;
	padding:10px 10px 0;
	&:before{
		content: "Dost\0119pne warianty produktu";
		text-transform: uppercase;
		margin-right:10px;
		font-weight:bold;
		margin-top:2px;
		font-size:13px;
	}
}
