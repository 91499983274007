#delivery-modal {
	max-width: 940px;
	padding: 0 0 20px;

	* {
		box-sizing: border-box;
	}

	.delivery-city-form {
		padding: 20px 30px;

		.title {
			font-size: 24px;
			margin-bottom: 20px;
		}

		.field {
			display: inline-block;
			margin-right: 20px;
			vertical-align: top;
			position: relative;
		}

		label {
			font-size: 14px;
			line-height: 30px;
			margin-right: 10px;
			vertical-align: top;
		}

		.confirm-city-button {
			display: inline-block;
			vertical-align: top;
			margin-top: 8px;
			@extend %btn-third;
			@extend %btn-medium;
		}

		input {
			text-transform: uppercase;
		}

		.select3-container {
			display: inline-block;
		}

		.validation-message {
			padding-left: 10px;
			color: red;
		}

		.search-icon {
			&::after {
				@extend %icon-search;
				font-size: 15px;
				position: absolute;
				right: 17px;
				top: 17px;
				z-index: 1;
			}
		}

		#city-name-delivery {
			width: 250px;
		}

		.city-autocomplete-loading {
			~ .search-icon {
				display: none;
			}
		}

		.city-autocomplete-list {
			left: auto;
			//do usunięcia jak wejdzie zmiana Mikołaja w inputRow
			right: 10px;
			width: 250px;
		}

		.city-name-list-item {
			&:hover {
				color: #fff;
				background-color: #104790;
			}
		}
	}

	.empty-tabs {
		padding: 20px;
		font-size: 15px;
		text-align: center;
		margin: 20px 0;
		border-top: 1px solid #cacaca;
		border-bottom: 1px solid #cacaca;
		background: #ebf8fe;
	}
	$deliveryTabs: "home", "shop", "post", "inpost", "pickuppoint" ;
	.tabs {
		position: relative;
		font-size: 0;
		padding: 26px 30px 0;

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			border-top: 1px solid #cacaca;
			left: 0;
			top: 64px;
			position: absolute;
		}

		label {
			display: inline-block;
			padding: 7px 14px;
			border-style: solid;
			border-color: #fff #fff #cacaca;
			border-width: 1px;
			cursor: pointer;
			font-size: 16px;
			background: #fff;
			position: relative;
			font-weight: bold;
			height: 39px;
		}
	}
	.tab-content {
		font-size: $font-default-size;
		padding: 0 30px 0;
		.content-box {
			display: none;
			padding-top: 30px;
			vertical-align: top;
		}
	}

	@each $tab in $deliveryTabs {
		.show-tab-#{$tab}{
			label.delivery-calculator-#{$tab} {
				border-color: #cacaca #cacaca #fff;
			}
			#delivery-calculator-#{$tab} {
				display: block;
			}
		}
	}
	.info-box {
		padding: 20px 30px 0;
		overflow: hidden;

		.title {
			font-size: 24px;
		}
	}

	.delivery-data {
		float: left;
		width: 50%;
		box-sizing: border-box;
		vertical-align: top;
		margin-top: 20px;

		&::after {
			content: "";
			display: block;
			clear: both;
		}

		.zip-code {
			font-size: 16px;
		}
	}

	.product-data {
		float: left;
		width: 50%;
		border: 1px solid #cacaca;
		box-sizing: border-box;
		vertical-align: top;
		margin-top: 20px;
		padding: 10px;

		.photo {
			float: left;
			width: 110px;
			margin-right: 10px;
			text-align: center;

			img {
				width: 100%;
			}
		}

		.title {
			font-size: 15px;
		}

		.name {
			color: #9b9b9b;
		}

		.oldprice {
			display: inline-block;
			padding-right: 5px;
		}

		.price {
			display: inline-block;
			color: #e30613;
		}

		.actions {
			margin-top: 15px;
			text-align: left;
		}

		& ~ .delivery-data {
			padding-left: 20px;
		}
	}

	.offer-list {
		float: left;
		width: 50%;
		box-sizing: border-box;
		padding-right: 30px;
		vertical-align: top;

		.title {
			padding: 3px 14px;
			font-size: 16px;

			.price {
				color: #ed1c24;
			}
		}

		.details {
			padding: 10px 14px 20px;
		}
	}

	.delivery-calendar {
		float: left;
		width: 50%;
		vertical-align: top;
	}

	.calendar {
		.title {
			font-weight: bold;
			font-size: 16px;
			padding-bottom: 10px;
		}

		.details {
			padding-bottom: 20px;
		}

		table {
			width: 100%;
			text-align: center;
			border: 1px solid #ddd;
			margin-bottom: 20px;
		}

		tr {
			&:first-child {
				text-transform: uppercase;
			}
		}

		td,
		th {
			padding: 7px;
		}

		td {
			border: 1px solid #ddd;
		}

		.day-item {
			cursor: pointer;

			&.active {
				position: relative;

				&::after {
					content: "";
					border: 1px solid #777;
					left: -1px;
					right: 0;
					top: -1px;
					bottom: 0;
					position: absolute;
				}
			}
		}
	}

	.delivery-time {
		margin-top: 20px;
		font-size: $font-default-size;
		clear: both;

		.title {
			font-size: 16px;
			padding: 3px 14px;
			background: #a4ef85;
			display: none;
		}

		&.active {
			.title {
				display: block;
			}
		}

		.days-list {
			display: none;
			padding: 0 14px;

			&.active {
				display: block;
			}
		}

		.group {
			display: inline-block;
			width: 33%;
			vertical-align: top;

			.name {
				margin-top: 10px;
				font-weight: bold;

				span {
					font-weight: normal;
				}
			}

			table {
				margin-top: 10px;
			}

			td {
				padding-right: 20px;
			}
		}
	}

	.delivery-date {
		margin-top: 20px;
		width: 100%;
		color: #9b9b9b;
		font-size: $font-default-size;
		clear: both;
	}

	.shop-data {
		.title {
			font-size: 16px;
			padding: 3px 14px;
			background: #fff971;
			font-weight: bold;
		}

		.name {
			font-size: 16px;
			padding: 3px 14px;
			display: inline-block;
			font-weight: bold;
		}

		.change-shop {
			display: inline-block;
		}

		.address {
			padding: 0 14px;
			line-height: 20px;
		}
	}

	.actions {
		margin-top: 10px;
		text-align: center;

		.add-to-cart {
			@extend %btn;
			@extend %btn-primary;
			@extend %btn-medium;
		}
	}

	#lista_sklepow {
		padding: 20px 30px;
		margin-top: 20px;
		border-top: 1px solid #cacaca;

		.shop-item {
			margin-bottom: 20px;
		}
	}

	&.in-progress {
		@extend %loading;
	}
}
#terminal-enabled-modal{
	width:460px;
}
