#product-archive {
	width: 920px;
	/*overflow:hidden;*/
	padding: 0 0 20px;
	z-index:2;
	position: relative;
	margin: 0 10px;
	@media screen and (max-width: $breakpoint-large+px) {
		width: 700px;
	}
	.top-label {
		display: inline-block;
		margin-bottom: 15px;

		h2 {
			font-size: 23px;
			line-height: 28.8px
		}

		&.status-unavailable {
			width: 220px;
			margin-right: 15px;
			color: #e30613;
		}
	}

	.archive-products-box {
		display: table;
		margin-bottom: 15px;
	}

	.products-list {
		display: table-row;
		width: 100%;

		.product-archive {
			display: table-cell;
			vertical-align: top;
			@media screen and (max-width: $breakpoint-large+px) {
				&:nth-of-type(3) {
					display: none;
				}
			}
		}
	}

	.no-recommended-products {
		display: table-cell;
		padding-left: 50px;
		font-size: 16px;
		text-align: center;
		vertical-align: middle;

		h4 {
			margin-bottom: 10px;
			line-height: 30px;
			font-size: 24px;
		}

		a {
			font-weight: bold;
		}
	}

	.product-community {
		ul {}
		li {
			display: inline-block;
			margin-right: 20px;
			vertical-align: middle;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&.products-loaded{
		.btn-compare-archive{
			display:inline-block;
		}
	}
	.btn-compare-archive {
		@extend %btn;
		@extend %btn-third;
		display:none;
		&:before {
			@extend %icon-compare;
			margin-right: 10px;
			font-size:20px;
		}

		&:hover {
			color: white;
		}
	}

	&.extended-header {
		.product-archive {
			.product-header {
				height: 65px;
			}
		}
	}
}
