.holiday-promotion {
	box-sizing: border-box;
	display: flex;
	width: 100%;
	max-width: 250px;
	padding: 6px 0;
	font-size: 12px;
	font-weight: bold;
	color: #bc0b0b;
	.icon {
		font-size: 16px;
		margin-right: 6px;
		&.icon-default:before {
			// @extend %icon-gift; // wroci w zimie
			@extend %icon-truck;
		}

		&.icon-custom {
			width: 22px;
			height: 22px;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: 100% auto;
		}
	}

	span {
		width: calc(100% - 28px);
		padding-top: 5px;
	}

	&.promotion-status- {
		&3,
		&4,
		&6,
		&7 {
			color: #f57900;

			&:hover {
				color: #f57900;
			}
		}
	}

	&:hover {
		text-decoration: underline;
		color: #bc0b0b;
	}
}
