// GLOBALNY STYL DLA ZNAKÓW ZGODNOŚCI
// NIE USUWAĆ MIMO IŻ TYCH KLAS NIE MA W APLIKACJI
.conformity-mark {
	float:left;
	width:50px;
	height:35px;
	margin-right:10px;
	.mark-icon {
		display:block;
		width:50px;
		height:35px;
	}
	.mark-tip {
		display:none;
	}
}
