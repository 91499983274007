#menu-product {
	width: 930px;
	@media screen and (max-width: $breakpoint-large+px) {
		width:690px;
	}
	margin-left: 5px;
	padding: 0 5px;
	overflow: hidden;
	white-space: nowrap;
	background-color: #fff;
	box-sizing: border-box;
	transition: opacity 0.3s linear;

	li {
		display: inline-block;
		border-bottom: 5px solid #fff;
		margin-right: 5px;
		@media screen and (max-width: $breakpoint-large+px) {
			margin-right: 3px;
		}
		a {
			display: block;
			padding: 20px 4px 10px 0;
		}

		&:first-child {
			a {
				padding-left: 0;
			}
		}

		&:last-child {
			a {
				padding-right: 0;
			}
		}

		span {
			font-size: 11px;
			color: $text-color;
			font-weight: bold;
			text-transform: uppercase;
		}
		a:hover, &.active-nav a {
			text-decoration: none;
		}
		&:hover, &.active-nav {
			border-bottom: 5px solid $link-color;
		}
	}
	&.outlet-tabs{
		li{
			&.akcesoria, &.zestawy,
			&.zobacz-takze, &.pasuje-do{
				display:none !important;
			}
		}
	}
}

.product-menu-fixed #menu-product {
	box-shadow: 0px 8px 6px -6px rgba(0, 0, 0, 0.25);
	+ #product-tabs{
		margin-top:52px;
	}
}
