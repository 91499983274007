.remodal#warranty-encouragement-modal{
	@if($app-katalog){
		padding: 15px 20px;
		overflow: hidden;
		max-width: 960px;
	}@else{
		&.new-warranty-encouragement{
			padding:0;
			.remodal-close{
				margin:6px 11px;
				&:before{
					font-size:30px;
					color:$text-color;
				}
			}
			max-width: 1000px;
		}
	}

	&.show-description{
		padding: 0;
		#warranty-encouragement{
			display: none;
		}

		#warranty-description{
			display: block;
		}
	}
}

.new-warranty-encouragement{
	&.show-description button.remodal-close{
		display: none;
	}
	#warranty-encouragement.varianted-warranties {

	}
	.photo-warranties{
		* {box-sizing: border-box;}
		width:1000px;
		background: #f2f2f2;
		&.default-warranty-encouragement {

			.warranty-actions,
			.warranty-title {
				background: #f7f7f7;
			}

			.warranty-graphics {
				position: absolute;
				left: 75px;
				top: 30px;
				z-index: 1;
			}

			.check-icon,
			.cross-icon {
				position: absolute;
				right: 7px;
				top: 7px;
				z-index: 3;
			}

			.check-icon {
				display: none;
			}

			.service-photo {
				text-align: right;
			}

			.product-photo {
				position: relative;

				> div {
					width: 310px;
					line-height: 310px;
					height: 310px;
					overflow: hidden;
					text-align: center;
					position: relative;
					z-index: 2;
					border-radius: 50%;
					background-color: #fff;
					opacity: .8;
				}
				
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					filter: grayscale(100%);
					width: auto;
					max-width: 100%;
					max-height: 66%;
					margin: 0 auto;
					vertical-align: middle;
				}
			}
		}
		.warranty-title{
			height: 70px;
			padding: 22px 20px;
			font-size: 23px;
			font-weight: bold;
			color:$text-color;
		}
		.warranty-actions{
			padding:17px 20px 0;
			text-align: right;
			min-height: 70px;
			.select3-container{
				margin-top: -2px;
				width: 243px;
				.select3-selected-item{
					border-radius: 5px;
					min-height: 40px;
					line-height: 40px;
					font-size: 13px;
				}
				.select3-option-list {
					top: 0;
					transform: translateY(calc(-100% + 2px));
				}
				.select3-option-list-wrap {
					display: flex;
					flex-direction: column-reverse;
				}
			}
		}
		.warranty-next{
			@extend %btn-primary;
			width:150px;
		}
		.warranty-payment-type-selector{
			display: inline-block;
			vertical-align: middle;
			margin:-2px 20px 0;
		}
		.encouragement-box{
			display: flex;
			align-items: center;
			overflow: hidden;
			min-height: 667px;
			padding:10px 60px 10px;
			position: relative;
			justify-content: flex-end;
			> img{
				position: absolute;
				top:0;
				left:0;
				width:100%;
				&.checked{
					opacity:0
				}
			}
			&.columns-2{
				padding-right:20px;

				.warranty-item{
					width:260px;
				}
				.add-warranty-button {
					padding:0 9px 0 39px;

					.time{
						line-height: 14px;
						max-width: 70px;
						text-align: left;
					}
					.price {
						line-height: 12px;

						.warranty-period {
							line-height: 12px;
							padding-top: 5px;
							padding-bottom: 5px;

							&.subscription-period {
								padding: 1px 0 13px;
							}
						}

						.price-postponed{
							margin: 8px 0 0;
							line-height: 12px;
						}
					}

					&[data-warranty-type=SUBSCRIPTION]{
						&:before{
							top: 14px;
							transform: none;
						}
						&:after{
							top: 19px;
							transform: none;
						}
						.time {
							transform: none;
							top: 18px;
							left: 33px;
						}
						.price {
							.price-postponed {
								margin-top: 20px;
							}

							.price-first-month {
								margin: 10px 0 0;
							}

							.price-one-time{
								display: block;
								line-height: 12px;
							}
						}

					}
				}
			}
			&.columns-1{
			}
			&:not(.is-visible){
				display: none;
			}
			&.is-hidden{
				display: none;
			}
		}
		&.selected {
			.check-icon {
				display: block;
			}

			.cross-icon {
				display: none;
			}

			.product-photo img {
				filter: unset;
			}
			.product-photo > div {
				opacity: 1;
			}
		}
		&.selected .encouragement-box > img{
			&.checked{
				opacity:1;
				transition: opacity 0.5s;
			}
		}
		.warranty-item{
			position: relative;
			background: #fff;
			width:300px;
			margin-top:30px;
			margin-left: 10px;
			border:1px solid #d7d7d7;
			border-radius: 10px;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
			&:before{
				position: absolute;
				top:-30px;
				display: block;
				width:60px;
				height: 60px;
				border:1px solid #d7d7d7;
				border-radius: 50%;
				background: #fff;
				box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
				content:"";
				left: calc(50% - 30px);
			}
			&:after{
				position: absolute;
				top:-30px;
				line-height: 60px;
				width:63px;
				display: block;
				height: 60px;
				font-size:39px;
				text-align: center;
				overflow: hidden;
				left: calc(50% - 30px);
			}
		}
		.warranty-item-full_protection{
			&:after{
				@extend %icon-shield;
				color:#ff0000;
			}
		}
		.warranty-item-extended_protection{
			&:after{
				@extend %icon-shield;
				color:#d87c12;
			}
		}
		.warranty-item-basic_protection{
			&:after{
				@extend %icon-shield;
				color:#d87c12;
			}
		}
		.warranty-item-complementary_protection{
			&:after{
				@extend %icon-shield;
				color:#d87c12;
			}
		}
		.warranty-item-screen_protection{
			&:after{
				@extend %icon-warranty-screen;
				color:#d87c12;
			}
		}
		.warranty-name{
			background: #f2f2f2;
			padding: 39px 20px 10px;
			min-height: 75px;
			text-align: center;
			font-size: 15px;
			overflow: hidden;
			border-bottom: 1px solid #d7d7d7;
			overflow: hidden;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			&.warranty-badge-new::before {
				content: 'nowo\015B\0107';
				position: absolute;
				left: 10px;
				top: 10px;
				display: block;
				padding: 0px 5px;
				border-radius: 10px;
				background-color: #4caf51;
				color: #fff;
				text-transform: uppercase;
				text-decoration: none;
				font-size: 10px;
				line-height: 14px;
				font-weight: bold;
			}
		}
		.warranty-features{
			margin:20px 0 0;
			padding:0 20px 0;
			ul{
				margin:0;padding:0;
			}
			li{
				padding:0 0 10px 20px;
				font-size: 14px;
				line-height: 18px;
				position: relative;
				&:before{
					font-size:13px;
					position: absolute;
					top:0;
					left:0;
				}
				&.checked{
					&:before{
						@extend %icon-check;
						color:#3dce9e;
					}
				}
				&.unchecked{
					color:#d3d0cf;
					&:before{
						@extend %icon-close;
						color:#ffcccc;
					}
				}
			}
		}
		.subscription-benefits:not(.is-visible) {
			display: none;
		}
		.add-warranty-button{
			border: 1px solid $link-color;
			min-height: 53px;
			padding:0 16px 0 39px;
			position: relative;
			border-radius: 2px;
			background: #fff;
			color: $link-color;
			width:100%;
			&.selected{
				background: $link-color;
				* {
					color:#fff !important;
				}
				&:after{
					content:"";
					@extend %center-v;
					left: 12px;
					width: 12px;
					height: 12px;
					display: block;
					border-radius: 50%;
					background: $link-color;
				}
			}
			&:before{
				content:"";
				@extend %center-v;
				left: 7px;
				width: 20px;
				height: 20px;
				display: block;
				border-radius: 50%;
				border:1px solid #dcdcdc;
				background: #fff;
			}
			.time{
				font-weight: 700;
				font-size: 12px;
				float: left;
				display: block;
				color: $link-color;
				text-transform: none;
				padding: 0;
				@extend %center-v;
			}
			.is-hidden {
				display: none;
			}
			&[data-warranty-type=SUBSCRIPTION]{
				.time {
					text-transform: uppercase;
				}
				.price {
					.price-postponed {
						margin: 10px 0 0;
					}

					.price-postponed-period {
						margin: 0;
						font-size: 10px;
					}

					.price-first-month {
						margin: 2px 0 1px;
						font-weight: bold;
						font-size: 10px;
						line-height: 12px;
						color: #D22E26;
						border-left: 2px solid #e30613;
						float: right;
						padding: 0 0 0 5px;
					}
				}
				&.selected{
					.price .price-first-month {
						border-left: 2px solid #fff;
					}
				}
			}
			&[data-warranty-type=ONE_TIME]:not(.instalment-mode) .time{
				padding: 0;
			}
			.price{
				font-weight: normal;
				font-size: 12px;
				height: 100%;
				float: right;
				color: $link-color;
				display: block;
				text-transform: none;
				>div{
					text-align: right;
				}
				.warranty-period{
					font-size: 10px;
					line-height: 21px;
					color: $text-color;
					padding-bottom: 3px;

					&.subscription-period {
						clear: right;
						padding-bottom: 10px;
					}
				}
				.price-first-month {
					padding-bottom: 5px;
					color: #1C1C1B;
					span {
						font-size: 10px;
						line-height: 12px;
						font-weight: 400;
					}
					.price-value {
						font-size: 14px;
						font-weight: bold;
						color: #005CA9;
					}
					br {
						display: none;
					}
				}
				.price-postponed{
					margin:8px 0 3px;
					display: block;
					line-height: 17px;
				}
				.price-one-time{
					display: block;
					line-height: 21px;
				}
				.per-month{
					text-align: right;
				}
				.instalment-price-per-month{
					font-size: 14px;
					margin-top: 8px;
					padding-bottom: 5px;
				}
				.warranty-period-info{
					display: inline-block;
					position: relative;
					margin-left: 3px;
					&:hover {
						.warranty-period-info-message {
							display: block;
						}
					}
				}
				.warranty-period-info-message{
					display: none;
					position: absolute;
					bottom: 24px;
					right: -5px;
					background: #333333;
					width:246px;
					padding: 10px;
					border-radius: 5px;
					color:#fff;
					white-space: normal;
					line-height: 1.2;
					font-size: 12px;

					&:before{
						position: absolute;
						width: 0;
						content:"";
						display: block;
						overflow: hidden;
						height: 0;
						border-style: solid;
						border-width: 6px 5px 0 5px;
						border-color: #333333 transparent transparent transparent;
						bottom: -6px;
						right: 8px;
					}
					ul {
						padding-left: 10px;
						li {
							position: relative;
							font-size: 13px;
							line-height: 16px;
							&:before {
								position: absolute;
								content: '•';
								left: -10px;
								top: 1px;
							}

						}
					}
					&.warranty-period-info-subscription {
						width: 572px;
						p {
							margin: 0;
						}
					}
				}
				.warranty-instalment-info{
					display: inline-block;
					position: relative;
					margin-left: 3px;
					&:hover {
						.warranty-instalment-info-message {
							display: block;
						}
					}
				}
				.warranty-instalment-info-message{
					display: none;
					position: absolute;
					top: 22px;
					right: -5px;
					background: #333333;
					width:206px;
					text-align: left;
					padding: 10px;
					border-radius: 5px;
					color:#fff;
					white-space: normal;
					z-index: 2;
					&:before{
						position: absolute;
						width: 0;
						content:"";
						display: block;
						overflow: hidden;
						height: 0;
						border-style: solid;
						border-width: 0 5px 6px 5px;
						border-color: transparent transparent #333333 transparent;
						top: -6px;
						right: 8px;
					}
				}
				.question-mark {
					border: 1px solid #999;
					border-radius: 50%;
					color: #999;
					display: inline-block;
					font-weight: normal;
					height: 13px;
					line-height: 13px;
					text-align: center;
					width: 13px;
					font-size: 9px
				}
			}

		}
		.warranty-info-link{
			display: block;
			margin:5px 0 0;
			text-align: center;
		}
		.warranty-buttons{
			padding:0 20px;
		}
		.warranty-unavailable-text{}
		.warranty-conditions-button{
			padding-bottom:20px;
			// &:not(:first-child){
			// 	margin-top:10px;
			// }
			&.is-hidden {
				display: none;
			}
		}
	}
}

#warranty-description{
	display: none;

	.warranty-item{
		display: none;

		&.visible{
			display: block;
		}
	}

	.warranty-add-to-cart {
		@extend %btn-primary;
	}

	.warranty-back-button {
		@extend %btn-fourth;
	}
}

.warranty-period-info-message {
	p:not(:last-child) {
		margin-bottom: 10px;
	}
}

.warranties{
	.warranty-period-info {
		position: relative;
		margin-left: 3px;
		&:hover {
			.tooltip-content {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.warranty-unavailable-message {
		font-size: 16px;
		color: #999999;
		font-weight: bold;
	}

	.postponed-installement-count {
		margin-top: 5px;
		position: absolute;
		width: 125px;
		justify-content: center;
		align-items: center;
		display: flex;
		font-size: 11px;
		right: 0;
	}

	.question-mark {
		border: 1px solid #999;
		border-radius: 50%;
		color: #999;
		display: inline-block;
		font-weight: bold;
		height: 16px;
		line-height: 16px;
		text-align: center;
		width: 16px;
	}

	.intro{
		margin: 10px 0 20px;

		p{
			font-size: 12px;

			&:first-child{
				font-size: 16px;
			}
		}
	}

	.warranty-unavailable {
		&:before {
			@extend %icon-close;
			color: #999;
		}
	}

	.warranty-available {
		&:before {
			@extend %icon-check;
			color: #005CA9;
		}
	}
	.warranty-available,
	.warranty-unavailable{
		&:before{
			font-size: 18px;
			font-weight: bold;
		}
	}

	.price-postponed {
		color: red;
		font-size: 24px;
		font-weight: bold;
		text-align: center;
	}
	.warranty-unavailable-text {
		font-size: 16px;
		padding: 0 5px;
		margin-bottom: 15px;
		text-align: center;
	}
	.warranty-unavailable-text,
	.free-warranty {
		color: #999999;
		font-weight: bold;
	}

	.free-warranty {
		display: block;
		margin-bottom: 29px;
		font-size: 18px;
		text-transform: capitalize;
	}

	.warranty-conditions-button {
		margin-top: 10px;
		padding: 0 0 5px;
		label.checkbox-css, label.radio-css{
			position: relative;
			z-index: 1;
			margin: 0 10px 0;
			.toggle-text{
				line-height: 17px;
			}
		}
	}

	.warranty-actions{
		text-align: center;
	}
	.warranty-next{
		@extend %btn-primary;
		@if ($app-ole) {
			padding: 14px 60px;
			font-size: 16px;
		}
	}
}

#warranties {
	.warranty-list {
		margin-top: 15px;
		display: flex;
		flex-flow: column;
	}


	.item {
		padding: 30px 15px;
		border: 1px solid transparent;
		border-bottom: 1px solid #ccc;
		&:only-child .select-warranty-type {
			display: none;
		}

		@if ($app != 'katalog') {
			display: flex;
			&.is-hidden {
				display: none;
			}

			& + .item {
				display: none;
			}
			&.is-visible {
				display: block;
			}
		}

		&:after

		&:hover {
			border-color: $text-color;
		}

		.warranty-icon {
			float: left;
			width: 80px;
			height: 85px;
			overflow: hidden;
		}

		.warranty-info {
			font-size: 14px;
			color: #1C1C1B;
			margin-left: 95px;
		}

		.title {
			font-size: 16px;
			color: #1C1C1B;
			font-weight: bold;
			margin-bottom: 5px;
		}

		.description {
			float: none;
			margin-bottom: 15px;
			width: 100%;
		}

		.actions {
			font-size: 12px;
		}

		.select-warranty-type {
			float: left;
			font-weight: bold;
			margin-right: 20px;
		}

		.warranty-price-box {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.warranty-price-info {
			display: inline-block;
			position: relative;
			margin-right: 15px;
			max-width: 135px;
			min-height: 40px;
		}

		.warranty-details {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.warranty-sales-box {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			text-align: right;
			&.is-hidden,
			+ .warranty-sales-box {
				display: none;
			}
			&.is-visible {
				display: flex;
			}
		}

		.postponed-installement-price {
			margin-top: 5px;
		}

		.price-normal {
			display: inline-block;
			line-height: 31px;
			height: 31px;
			vertical-align: top;
			font-size: 24px;
			margin-right: 12px;
		}

		.add-warranty-with-product {
			display: inline-block;
		}

		.price-per-month {
			font-size: 12px;
			margin-top: 5px;
			text-align: right;
			width: 100%;
		}

		.price-only {
			font-weight: bold;
		}
	}
}

.warranty-info-modal {
	+ .warranty-info-modal,
	&.is-hidden,
	&:only-child .select3-container {
		display: none;
	}

	&.is-visible {
		display: block;
	}

	.warranty-control {
		display: flex;
		.checkbox-css {
			margin-right: 30px;
		}
	}

	.warranty-unavailable-message {
		font-size: 16px;
		color: #999999;
		font-weight: bold;
		margin-bottom: 10px;
	}
}
