#modal-product-details {
	max-width:1216px;
	padding: 26px 32px 32px;
	border-radius: 16px;
	*{
		box-sizing: border-box;
	}
	.remodal-close{
		top: 25px;
		right: 32px;
	}
	.product-opinion-stars-grades{
		.stars-rating{
			display: inline-block;
		}
	}
	h2 {
		font-size:24px;
		margin:0;
		padding: 0 50px 20px 0;
	}
	.content{
		background: #fff;
		padding: 0;
		max-width: 564px;
		margin:0 auto;
		border:0;
	}
	.product-header{
		margin-top: 7px;
		.status-label {
			display: flex;
			margin-right: auto;
			margin-top: 5px;
			padding: 1px 10px 2px;
			width: fit-content;
			border-radius: 12px;
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			white-space: nowrap;
		}
		.product-top-list {
			background: #45abe5;
			&:after {
				content: "Bestseller";
				color: #FFFFFF;
			}
		}
	}
	.category-name{
		font-size: 12px;
		color: #767676;
	}
	.product-plu-number{
		font-size: 12px;
		color: #767676;
	}
	.product-image{
		margin: 0 auto 10px;
		text-align: center;
		width: 100%;
		height: 288px;
		
		img{
			margin: 0 auto;
			width: auto;
			height: 100%;
		}
	}
	.product-price{
		margin-bottom: 20px;
		.price-normal{
			font-size: 32px;
		}
		&.prices-promotion {
			.price-normal{
				color: $promotion-price-color;
			}
		}
		.price-promotion-label{
			font-size: 14px;
			color: $promotion-price-color;
		}
		.price-omnibus{
			margin-top: 3px;
			font-size: 14px;
			text-align: left;
		}
	}
	.product-spec{
		position: relative;
		max-height: 200px;
		overflow: auto;
		&::after{
			content: '';
			position: sticky;
			bottom: 0;left: 0;
			display: block;
			width: 100%;
			height: 16px;
			background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
		}
		ul {
			margin: 0 0 15px 0;
			padding: 0 0 0 20px;
			color: #767676;
		}
		li {
			font-size: 14px;
			list-style: disc;
			line-height: 18px;
			padding: 3px 0 0 0;
			span {
				color: $text-color;
			}
		}
	}
	.product-spec-header{
		font-weight: bold;
		font-size: 16px;
	}
	.product-buttons{
		text-align: center;
		.add-product{
			box-shadow: 0px 1px 3px 0px #30313333;
			background: #016EF4;
			padding: 12px 22px;
			border-radius: 100px;
			color:#fff;
			display: inline-block;
			text-align: center;
			font-size:16px;
			font-weight: bold;
			height: 48px;
			&:hover{
				text-decoration:none;
			}
			margin-bottom: 20px;
			width: 100%;
		}
		.show-more{
			@extend %btn-link;
			font-weight: bold;
			width:100%;
			font-size: 16px;
		}
	}
}