.sticky #comparison {
	#main-menu,  #header {
		display: none;
	}
	#compare-bar {
		display: table;
	}
}

#compare-bar {
	background: #fff;
	display: none;
	margin: 0 auto;
	padding: 0 10px;
	width: 1180px;
	@media (max-width: #{$breakpoint-large}px) {
		width: 940px;
	}
	&.products-count-2 .column {
		width: 33.33%;
	}
	&.products-count-3 .column {
		width: 25%;
	}
	.compare-bar-title {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.column:not(:first-child) {
		border-left: 1px solid #ddd;
		padding-left: 20px;
		padding-right: 20px;
	}
	.column {
		display: table-cell;
		padding: 15px 0;
		vertical-align: top;
	}
	.add-to-cart {
		width: 100%;
	}
	.product-preview {
		.product-preview-photo {
			float: left;
			width: 85px;
			img{
				width:71px;
				height:63px;
				display: block;
				margin-bottom: 9px;
			}
		}
		.product-preview-details {

		}
		.product-preview-name {
			a {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: block;
				max-width: 155px;
			}
			a:hover {
				color: #000;
			}
		}
		.product-preview-price {
			font-size: 20px;
			margin-bottom: 10px;
			padding-left: 84px;
			.price-monthly,
			.price-normal {
				font-size: 20px;
			}
			.not-active, .see-others {
				display: block;
				font-weight: bold;
				font-size: 12px;
				margin-top: 10px;
			}
			.price-omnibus {
				text-align: left;
			}
		}
	}
	.show-difference {
		font-size: 10px;
	}
	.clipboard-button {
		margin-bottom: 10px;
		padding-left: 5px;
	}
	.price-instalments, .stars-rating, .product-category, .see-details, .product-saved, .price-promotion-date, .product-status {
		display: none;
	}
	.price-normal {
		display: inline-block;
	}
	.price-voucher-name, .price-voucher-date{
		text-align: left;
	}

	.subscription-price .promotion-price {
	  font-size: 13px;
	  text-align: right;
	}
	.activation-voucher {
		@extend %activation-voucher;
	}
}
