#helpline {
	display: block;
	margin-left:25px;
	z-index: 2;
	display: flex;

	&:hover {
		cursor: pointer;
		.helpline-phone, .helpline-hours {
			text-decoration: underline;
		}
	}

	&.active .helpline-info {
		opacity: 1;
		visibility: visible;
	}
	&:before {
		@extend %icon-headphones-line;
		color: $link-color;
		font-size: 15px;
		margin-right: 7px;
		line-height: 35px;
	}

	.helpline-phone {
		color: $link-color;
		line-height: 35px;
	}

	.helpline-hours {
		text-transform: uppercase;
		@if($app-ole){
			color: $link-color;
		}@else{
			color: #1C1C1B;
		}
		line-height: 35px;
		&:before {
			content: '\00a0-\00a0';
		}
	}

	.helpline-info {
		top: 25px;
		left: -105px;
	}
}

.helpline-info {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	background-color: #fff;
	border: 1px solid $border-color;
	left: -85px;
	font-size: 10px;
	top: 44px;
	padding: 10px;
	width: 240px;

	&:hover {
		cursor: default;
	}
}

.helpline-info-header {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
}

.helpline-info-hours {
	margin: 8px 0;
}

.helpline-info-phone {
	font-size: 16px;
	font-weight: bold;
}
