@if(not $app-katalog) {
#search-box {
	position:absolute;
	@if($app-euro){
		left: 338px;
		top: 21px;
		height:36px;
		@media all and (max-width: #{$breakpoint-large}px) {
			left: 241px;
			top: 22px;
		}
	}@else{
		left: 208px;
		top: 23px;
		height:36px;
	}
	z-index: 0;
	
	display: flex;
	background:#F7F7F7;
	border-radius: 7px;
		


	
	.input-wrap {
		border-right:1px solid #E5E5E5;
		margin: 1px 0 2px 2px;
		@if($app-euro){
			width: 246px;
		}@else{
			width: 442px;
		}
		@media all and (max-width: #{$breakpoint-large}px) {
			@if($app-euro){
				width: 150px;
			}@else{
				width: 230px;
			}
		}
		@media all and (min-width: 1440px) {
			@if($app-euro){
				width: 405px;
			}@else{
				width:612px;
			}
			
		}
		
		input {
			outline:0;
			font-size:16px;
			height: 100%;
			width: 100%;
			padding-left: 14px;
			padding-right: 14px;
			background: transparent;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
	#search-button {
		
		cursor:pointer;
		text-align:center;
		
		position:relative;
		z-index:12;
		display: flex;
		font-weight: 700;
		align-items: center;
		height:100%;
		padding: 0 20px;
		font-size: 12px;
		line-height: 16px;
		color: rgb(1, 110, 244);
		white-space: nowrap;
		&::before {
			@extend %icon-search2;
			font-size: 16px;
		}
		&::after {
			content: "Szukaj";
			margin-left:8px;
		}
		&:hover{
			text-decoration: none;
		}
		

	}

	#sidebar-results {
		display: none;
		.search-products-item {
			.title-box {
				.product-name {
					padding-right: 10px;
				}

				.product-price {
					flex-shrink: 0;
					display: flex;
					/* EURSI-37170
					.price-old {
						margin-right: 8px;
					}
					*/
				}

				.product-previous-price {
					color: #333333;
					margin-right: 7px;
					text-decoration: line-through;
				}
			}
		}
	}

	.input-wrap {
		position: relative;
	}

	#search-box-result {
		#ac-results {
			width: 520px;
			display: none;
			flex-shrink: 0;
			background-color: #fff;
			border: 1px solid #ccc;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
			box-sizing: border-box;
			margin-left: -2px;
			margin-top: 2px;
			border-top-right-radius: 10px;

			&:empty {
				border: none;
			}

			.ac-item {
				height:35px;
				line-height: 35px;
				cursor: pointer;
				width:100%;
				display:block;
				padding: 0 25px;
				box-sizing: border-box;

				&.ac-active {
					background-color: #e5eef6;
				}

				&:nth-last-child(1) {
					border:0;
				}

				span {
					display:block;
					float:left;
					font-size:12px;
					font-weight: 400;
					padding: 0 10px 0 0;

					&:not(:last-child) {
						padding-right: 4px;
					}

					@if($app-euro){
					color:#005ca9;
					}@else{
						color:#008FC7;
					}

					text-shadow:none;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					line-height: 35px;
					height: 35px;

					b {
						font-weight: normal;
					}
				}
				&.ac-group-categorySuggestions {
					+ .ac-group-suggestions {
						position: relative;
						margin-top: 50px;

						&::before {
							content: 'Popularne has\0142 a';
							position: absolute;
							top: -30px;
							left: 25px;
							color: #999;
							font-size: 14px;
							line-height: 1.6;
							pointer-events: none;
						}
					}
				}
				&.ac-group-categorySuggestions {
					display: flex;
					span {
						float:left;
						flex-shrink: 0;
					}
					i {
						font-size: 14px;
						float:left;
						display:block;
						text-shadow: none;
						color: #333333;
						font-weight: 400;
						line-height: 35px;
						height: 35px;
						padding: 0;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						font-style: normal;
					}
				}
				&.ac-group-saved {
					display: flex;

					&:first-child {
						position: relative;
						margin-top: 44px;
						&::before {
							content: 'Ostatnio wyszukiwane';
							position: absolute;
							top: -30px;
							left: 25px;
							color: #999;
							font-size: 14px;
							line-height: 1.6;
							pointer-events: none;
						}
					}

					&:last-child {
						margin-bottom: 10px;
					}

					.ac-text {
						flex-grow: 1;
						font-size: 14px;
					}

					.forget {
						display:block;
						flex-shrink: 0;
						text-align: right;
						font-size: 14px;
						font-weight: bold;
						padding: 0;

						&:hover {
							text-decoration: underline;
						}

						&::after{
							@extend %icon-close;
							font-size:10px;
							margin-left:7px;
						}
					}
				}
			}
		}
	}

	&.ac-open {
		#search-box-result {
			display: flex;
			#ac-results {
				display: block;
			}
		}
	}

	&.ac-open:not(.empty) {
		border-bottom-left-radius: 0;

		#ac-results {
			position: relative;
		}
	}

	&.ac-open.searching:not(.empty) {
		#search-box-result {
			position: absolute;
			
			width: 720px;
			background: #fff;
			border: 1px solid #ccc;
			padding: 18px 0;
			border-radius: 10px;
			border-top-left-radius: 0;
			top: 34px;
			left: -2px;

			.title {
				color: #999;
				font-size: 14px;
				line-height: 1.6;
				margin-bottom: 10px;
				padding-left: 8px;
			}

			#ac-results {
				width: 415px;
				padding: 8px 0;
				border: none;
				margin-left: 0;

				.ac-item {
					&.ac-group-saved {
						display: none;
					}

					span {
						font-size: 14px;
						max-width: 100%;
					}
				}
			}

			#sidebar-results {
				display: block;
				width: 305px;
				padding: 4px 10px 15px;
				border-left: 1px solid #ccc;
				&:before{
					content: 'Polecane produkty';
					line-height: 1.4;
					font-size: 14px;
					color: #999999;
					margin-bottom: 15px;
					padding-left: 10px;
				}
				%animation{
					position: relative;
					background-color: #F2F2F2;
					color: #F2F2F2;
					overflow: hidden;

					&::before {
						content: '';
						display: block;
						position: absolute;
						width: 50px;
						height: 100%;
						background: linear-gradient(90deg, transparent, #e4e4e4, transparent);
						transform: translateX(-60px);
					}
				}
				&.loading .search-products-item {
					cursor: default;
					pointer-events: none;

					&:hover {
						background-color: transparent;
					}

					.product-photo {
						@extend %animation;
						&::before {
							animation: loading-skeleton-img 1.8s linear infinite;
						}

						img{
							opacity: 0;
						};
					}

					.title-box, .product-category, .stars-rating{
						@extend %animation;
						&::before {
							animation: loading-skeleton-line 1.8s linear infinite;
						}

						> div,
						> span {
							opacity: 0;
						}
					}

					.stars-rating {
						width: 50%;
					}

					.products-link{
						visibility: hidden;
					}
				}

				.search-products-item {
					display: flex;
					line-height: 1.3;
					margin-bottom: 15px;
					padding: 8px;
					text-decoration: none;

					&:nth-last-child(2) {
						margin-bottom: 0;
					}

					&:hover {
						background-color: #e5eef6;

						.product-name {
							text-decoration: underline;
						}
					}

					.product-photo {
						flex-shrink: 0;
						width: 60px;
						height: 53.5px;
						display: flex;
						align-items: flex-start;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							display: block;
						}
					}

					.product-content {
						width: 100%;
						padding-left: 15px;
					}

					.title-box {
						display: flex;
						width: 100%;
						margin-bottom: 2px;

						.product-name {
							flex-grow: 1;
							color: #333333;
							font-weight: bold;
							height: 16px;
							text-overflow: ellipsis;
							overflow: hidden;
							width:100%;
						}
					}

					.product-category {
						margin-bottom: 2px;
						color: #999;
						height: 16px;
						text-overflow: ellipsis;
						overflow: hidden;
						width:100%;
					}
					.stars-rating{
						height: 18px;
					}

					.product-price {
						.price-instalments {
							display: none;
						}
					}
				}

				.products-link {
					display: block;
					padding: 8px;
					line-height: 1.4;

					&:hover {
						background-color: #e5eef6;
					}
				}
			}
		}
	}
}

@keyframes loading-skeleton-img {
	0% { transform: translateX(-60px); }
	25%, 100% { transform: translateX(60px); }
}

@keyframes loading-skeleton-line {
	0%, 20% { transform: translateX(-60px); }
	70%, 100% { transform: translateX(355px); }
}

}