.promotion-package-commercial {
	background: #164194;
	padding: 16px 15px;
	overflow: hidden;
	@mixin promotion-package-label {
		color: #164194;
		padding: 0 5px 1px;
		display: table;
		background: #ffed00;
		border-radius: 2px;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 15px;
		content: attr(data-label);
		white-space: nowrap;
	}

	.promotion-package-content {
		font-size: 14px;
		margin-top: 12px;
		color: #fff;
		font-weight: bold;
		line-height: 19px;
		strong {
			color: #ffed00;
		}
		a {
			color: #fff;
			text-decoration: underline;
		}
	}
	.promotion-package-time {
		font-size: 12px;
		margin-top: 11px;
		color: #fff;
		line-height: 16px;
	}
	.promotion-package-product-price {
		white-space: nowrap;
		font-size: 36px;
		color: #ffed00;
		font-weight: bold;
		span {
			font-size: 20px;
		}
		&:after {
			content: attr(data-bottom);
			display: block;
			font-size: 14px;
			white-space: nowrap;
			font-weight: normal;
			line-height: 25px;
		}
	}
	.promotion-package-service-price,
	.promotion-package-self-deposit {
		white-space: nowrap;
		color: #ffed00;
		font-weight: bold;
		font-size: 20px;
		margin-left: 4px;
	}
	.promotion-package-rrso {
		white-space: nowrap;
		color: #ffed00;
		font-size: 14px;
		margin: 4px 0 0 0;
	}
	.promotion-package-action {
		margin-top: 12px;
		button {
			padding: 9px 5px;
			color: #016ef4;
			font-weight: bold;
			font-size: 12px;
			border: 0;
			background: #ffffff;
			cursor: pointer;
		}
	}

	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	&:not(.wide) {
		.promotion-package-price {
			&:before {
				@include promotion-package-label;
			}
		}
	}
	.promotion-package-product-price,
	.promotion-package-service-price,
	.promotion-package-self-deposit {
		display: inline-block;
	}
	.promotion-package-action {
		order: 1;
		button {
			width: 100%;
		}
	}
	&.promotion-package-commercial-installment {
		.promotion-package-self-deposit,
		.promotion-package-service-price {
			margin: 4px 0 0 0;
		}
	}
	&.wide {
		display: block;
		.promotion-package-content {
			margin-top: 0;
			font-size: 12px;
			line-height: 16px;
			font-weight: normal;
			&:before {
				@include promotion-package-label;
				margin-bottom: 9px;
			}
		}
		.promotion-package-price,
		.promotion-package-action {
			text-align: right;
			float: right;
			width: 308px;
			clear: both;
		}
		.promotion-package-content,
		.promotion-package-time {
			max-width: calc(100% - 308px - 20px);
		}
		.promotion-package-product-price {
			text-align: right;
			font-size: 24px;
			margin-top: -4px;
			display: block;
			line-height: 31px;
			span {
				font-size: 15px;
			}
		}
		.promotion-package-service-price,
		.promotion-package-self-deposit {
			text-align: right;
			font-size: 15px;
			line-height: 20px;
		}
		.promotion-package-rrso {
			text-align: right;
			font-size: 12px;
			margin-top: 0;
		}
		.promotion-package-time {
			font-size: 10px;
			line-height: 16px;
		}
		.promotion-package-action {
			margin-top: 12px;
			button {
				width: 196px;
			}
		}
	}
	&.promotion-package-commercial-cart {
		.promotion-package-product-price {
			margin-top: 12px;
		}
	}

	.services-list & {
		// lista usług na KP
		margin-top: 16px;
	}
	.available-services-modal & {
		margin-bottom: 16px;
	}
	.product-details-aside & {
		// prawy bloczek na KP
		margin-top: 6px;
	}
	.product-for-list & {
		// listing
		padding: 10px 10px;
		margin-top: 10px;
		.promotion-package-service-price,
		.promotion-package-self-deposit,
		.promotion-package-rrso {
			display: block;
			margin-top: 4px;
			margin-left: 1px;
		}
	}
	#service-modal & {
		// warstwa usług na KP
		margin: -15px 20px 20px;
	}

	.service-contents & {
		// warstwa usług na koszyku
		padding: 16px 15px;
		margin: 10px;
		.promotion-package-rrso{
			margin-top:4px;
		}
	}
	table & {
		margin-bottom: 10px;
		.promotion-package-content::before {
			display: table-caption !important;
		}
	}
}
