@charset "UTF-8";

#popular-brands {
	margin:10px 0;
	.section-header {
		font-size: 22px;
		margin: 35px 0 10px;
		padding-bottom: 15px;
		border-bottom: 1px solid #ddd;
	}
	ul{
		padding:5px 10px;
		overflow: hidden;
		@media all and (max-width: #{$breakpoint-large}px) {
			padding:5px 0;
		}
	}
	li{
		display:inline-block;
	}
	a {
		width:144px;
		height:54px;
		margin:5px 10px 10px;
		display:block;
		background:#ffffff no-repeat center center;
		@media all and (max-width: #{$breakpoint-large}px) {
			margin:5px 0 10px;
			width:133px;
		}
	}
}
