#operator-plu-compare{
	position: relative;
	width: 62px;

	&.active{
		> a{
			background: #f2f2f2;
		}
		> div{
			display: block;
		}
	}

	> a{
		display: block;
		border: 1px solid $border-color;
		border-width: 0 1px;
		height: 40px;
		width: 40px;
		line-height: 23px;
		margin: 0 10px;
		text-align: center;
		font-size: 34px;
		&:after{
			@extend %icon-compare;
		}
	}

	.compare-box{
		display: none;
		position: absolute;
		z-index: 4;
		right: 10px;
		top: 40px;
		background: #fff;
		border: 1px solid $border-color;
		background: #f2f2f2;
		font-size: 14px;

		p{
			font-weight: bold;
			margin: 0 0 15px 0;
		}

		.row{
			width: 270px;
			overflow: hidden;
			margin: 0 0 10px 0;
			padding: 0 !important;
		}

		label{
			float: left;
			line-height: 30px;
		}

		input{
			float: right;
			width: 200px;
		}

		.compare, .cart{
			padding: 15px 20px;
			border-bottom: 1px solid $border-color;
			overflow: hidden;
		}

		.cart{
			position: relative;
		}

		.compare-clear-cart{
			@extend %btn;
			@extend %btn-small;
			@extend %btn-fourth;
			float: right;
			width: 190px;
			margin: 12px 0;
		}

		.buttons{
			background: #fff;
			padding: 5px 20px;
			text-align: right;

			a{
				display: block;
				font-size: 10px;
			}
		}
	}

	#operator-goto-compare,
	#operator-clear-compare{
		float: right;
		width: 200px;
	}

	#operator-goto-compare{
		@extend %btn-small;
		@extend %btn-primary;
	}

	#operator-clear-compare{
		margin: 10px 0 0 0;
		text-align: center;
		font-size: 11px;
	}

	#operator-basket{
		position: absolute;
		height: 55px;
		width: 55px;
		top: 0;
		left: 30px;

		&.empty{
			&:after{
				color: #dadada;
			}
			span{
				background-color: #dadada;
				color: #fff;
			}
		}

		&:after{
			@extend %icon-basket;
			display: block;
			position: absolute;
			left: 0;
			top: 18px;
			font-size: 37px;
			font-weight: bold;
			color: #1C1C1B;
		}
		span{
			border: 2px solid #f2f2f2;
			background-color: #FFE843;
			width: 25px;
			height: 25px;
			line-height: 25px;
			border-radius: 50%;
			color: #1C1C1B;
			font-size: 14px;
			font-weight: bold;
			display: block;
			position: absolute;
			top: 9px;
			left: 16px;
			text-align: center;
			z-index: 1;
		}
	}
}
