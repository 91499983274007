#node-description {
	overflow: hidden;
	
	//stylowanie dla elementów, których nie ma w kodzie aplikacji - html pochodzi z komunikatów konsoli (opisy liści kategorii).
	h2 {
		font-size: 20px;
		margin: 0px 0 10px;
	}

	h3 {
		display: block;
		font-size: 16px;
		font-weight: bold;
		margin: 10px 0;
		padding: 0;
	}

	@extend %article-list;
}
