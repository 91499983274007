#promotion-listing {
	* {
		box-sizing: border-box;
	}
	.promotion-listing-header-image {
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 28.736%; //aspect ratio default image size: 870x250px
		margin-bottom: 16px;
		overflow: hidden;
		img {
			position: absolute;
			top:0;right:0;bottom:0;left:0;
			display: block;
			width: 100%;
			height: 100%;
		}
	}
	.promotion-listing-tab {
		padding: 0px 24px;
		margin-bottom: 16px;
		border-radius: 4px;
		border: 1px solid #ccc;
		&.open {
			border: 2px solid #005CA9;
			.tab-title::after {
				transform: rotate(90deg) scale(-1);
				color: #005CA9;
			}
			.tab-content {
				display: block;
				height: auto;
				opacity: 1;
			}
		}
	}
	.tab-title {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		padding: 20px 0;
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
		color: #0A0A0A;
		cursor: pointer;
		.icon {
			position: relative;
			display: inline-flex;
			flex-shrink: 0;
			width: 21px;
			height: 21px;
			margin-right: 15px;
			svg {
				max-width: 100%;
				width: auto;
				max-height: 100%;
				height: auto;
			}
		}
		&::after {
			@extend %icon-arrow;
			position: absolute;
			right: 8px;
			top: 50%;
			font-size: 16px;
			margin-top: -8px;
			transform: rotate(90deg) scale(1);
			color: #0A0A0A;
		}
	}
	.tab-content {
		display: none;
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: linear 0.5s;
	}
	.tab-additional-copy {
		margin-bottom: 16px;
		padding: 16px;
		background: #F7F6FF;
		border-radius: 10px;
		color: #473DB2;
		font-size: 14px;
		line-height: 19px;
	}
	.tab-steps {
		counter-reset: promotion-listing-counter;
		margin: 0;
		padding-left: 0;
		list-style: none;
	}
	.tab-step {
		position: relative;
		display: block;
		min-height: 24px;
		padding: 3px 0 3px 36px;
		margin-bottom: 16px;
		color: #0A0A0A;
		font-size: 14px;
		line-height: 19px;
		&::before {
			content: counter(promotion-listing-counter);
			counter-increment: promotion-listing-counter;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 24px;
			height: 24px;
			font-size: 14px;
			line-height: 24px;
			background: #E5E5E5;
			color: #767676;
			text-align: center;
			border-radius: 50%;
		}
	}
	.tab-button {
		margin-bottom: 16px;
		padding: 6px 24px;
		background: #104790;
		border: 1px solid #005CA9;
		border-radius: 4px;
		color: #FFFFFF;
		font-size: 12px;
		line-height: 19px;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
	}

	.promotion-listing-footer {
		margin-bottom: 20px;
		padding-bottom: 16px;
		border-bottom: 1px solid #CCC;
		font-size: 12px;
		line-height: 16px;
		.legal {
			margin: 0;
			color: #767676;
		}
		.terms {
			padding-top: 16px;
			color: #005CA9;
		}
	}

	.promotion-listing-custom-html {
		max-width: 100%;
		margin-bottom: 20px;
		overflow: hidden;
	}
}