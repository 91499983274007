#modal-partner-subscriptions {
	width: 955px;
	padding: 0;
	min-width: 955px;
	* {
		box-sizing: border-box;
	}

	.header {
		border-bottom: 1px solid #ccc;
		display: flex;
		padding: 25px 50px 10px 30px;
		justify-content: space-around;
		.image {
			width:130px;
			overflow: hidden;
			img{
				width: 100%;
			}
		}

		.description {
			padding: 0 20px;
			width: 100%;
		}

		.name {
			font-size: 28px;
			font-weight: bold;
		}

		.intro {
			padding: 10px 0;
		}

		.prices {
			width: 170px;
		}

		.price {
			color: red;
			font-size: 40px;
			font-weight: bold;
			text-align: center;
			border: 1px solid #ccc;
			border-bottom: 0;
			padding: 10px 10px 0;
		}

		.info {
			text-align: center;
			text-transform: uppercase;
			font-size: 10px;
			border: 1px solid #ccc;
			border-top: 0;
			padding: 0 10px 10px;
			color: #666;
		}
	}

	.subscriptions {
		display: flex;
		margin: 30px 20px 0;
		justify-content: space-around;
		.item {
			flex-grow: 1;
			&:last-child{
				.feature, .price{
					border-right:1px solid #e4e4e4;
				}
				.action{
					border-right:0
				}
			}
		}

		.name {
			font-size:20px;
			padding:10px 20px;
			font-weight: bold;
		}
		.feature{
			&:nth-child(odd){
				background: #f2f2f2;
			}
		}
		.feature, .price{
			padding:10px 20px;
			border-left:1px solid #e4e4e4;
			border-top:1px solid #e4e4e4;
			label{
				font-size:10px;
				white-space: nowrap;
				text-transform: uppercase;
			}
			> div{
				margin-top:2px;
				font-size:12px;
				*{
					vertical-align: middle;
				}
			}
		}
		.price{
			border-bottom:1px solid #e4e4e4;
			> div{
				 font-size:20px;
			}
		}
		.action{
			border-right:1px solid #e4e4e4;
			padding:30px 20px;
			text-align: center;
			.checkbox-css{
				width:120px;
				margin:0 auto 10px;
			}
			a{
				font-size:12px;
				display: inline-block;
				margin-top:15px;
			}
		}
	}

	.buttons {
		display: flex;
		padding: 10px 0 20px;
	}
	.services-next{
		width:100px;
		margin: 0 auto;
		@extend %btn-primary;
	}
}
