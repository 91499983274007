#watched-list {
	.product-box{
		width: 680px;
		background-color: #fff;
	}

	.product-main {
		width: 419px;
		padding-bottom: 35px;
	}

	.product-photo {
		width: 195px;
		height: 173px;
	}

	.product-info {
		width: 197px;
	}

	.product-articles {
		width: 230px;
	}

	.product-top-list, .product-advance-sale {
		width: 678px!important;

		.product-main {
			width: 418px;
		}

		.product-info {
			width: 194px;
		}

		.product-articles,
		.product-sales {
			width: 229px;
		}
	}

	.product-status {
		left: -15px;
	}

	.product-archive {
		padding-top: 4px;
	}
	/* poniższe poprawiane również w ramach EUROTMSI-3053 */
	.product-community {
		.community-list {
			> li {
				margin-right: 0;
			}
			a::before {
				margin-right: 3px;
				padding-right: 0;
			}
		}
	}
}
