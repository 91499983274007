.btn, button{
	@extend %btn;
}
.btn-ultrathin {
	@extend %btn-ultrathin;
}
.btn-thin {
	@extend %btn-thin;
}
.btn-small {
	@extend %btn-small;
}
.btn-medium {
	@extend %btn-medium;
}
.btn-big {
	@extend %btn-big;
}

.btn-input-size {
	@extend %btn-input-size;
}

.btn-primary{
	@extend %btn-primary;
}

.btn-second{
	@extend %btn-second;
}

.btn-third{
	@extend %btn-third;
}

.btn-fourth{
	@extend %btn-fourth;
}
.btn-link{
	@extend %btn-link;
}
.btn-disabled, .btn-disabled:hover{
	@extend %btn-disabled;
}

.add-to-cart {
	@extend %btn-primary;
	&-big {
		@if($app-katalog){
			padding: 12px;
		}@else{
			padding: 19px 12px;
		}
		@if($app-ole) {
			font-size: 18px;
			padding: 16px 12px;
		} @else {
			font-size: 16px;
		}
	}
	&.btn-hide {
		display: none !important;
	}
}

.report-bug {
	&:before {
		@extend %icon-error;
	}
}
@if($app-katalog){
	.app-katalog{
		.clipboard-button{
			min-width:145px !important;
		}
		.clipboard-button-active{
			pointer-events: none;
		}
	}
}
.clipboard-button {
	&:before {
		@extend %icon-heart-empty;
	}

	&:after {
		content: attr(data-base);
	}

	&.clipboard-button-active {
		color: #3e3e3e;
		background: $clipboard-btn-active-background;

		&:after {
			content: attr(data-checked);
		}

		&:before {
			@extend %icon-heart;
		}

		&:hover {
			color: #0076b2;
			opacity: 1;
			min-width: 100px;
			&:after {
				content: attr(data-checked-hovered);
			}
		}
	}
}

.compare-button {
	&:before {
		@extend %icon-compare;
	}

	&:after {
		content: attr(data-base);
	}
	&.compare-button-active {
		color: #3e3e3e;
		min-width: 112px;
		background: $compare-btn-active-background;

		&:after {
			content: attr(data-checked);
		}

		&:hover {
			color: #0076b2;
			opacity: 1;
			

			&:after {
				content: attr(data-checked-hovered);
			}
		}
	}
}

.compare-back-button {
	color:$text-color;

	&::before {
		@extend %icon-navigation-before;
	}

}

.show-difference, .compare-add-next {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	@media only print {
		display: none;
	}
}

.shop-delivery-only-available{
	width: 100%;
	@extend %btn-third;
	padding-left: 10px;
	padding-right: 10px;
}

.show-difference {
	padding-left: 0;

	&::before {
		@extend %icon-hide;
		color: inherit;
		font-size: 22px;
		margin-right:10px;
		vertical-align: middle;
		margin-top: -3px;
	}

	&.btn-hide {
		@extend %btn-small;
	}

	&.btn-show {
		@extend %btn-small;
		span {
		display: none;
		}
		&:after {
		content: attr(data-btn-show);
		}
		&::before {
			@extend %icon-show;
		}
	}
}

.compare-add-next {
	float: right;
}

.print-button, .depositories-button, .clipboard-button, .nearest-shop-button, .compare-button, .compare-back-button, .report-bug, .similars-button, .call-button,.partnership-button {
	display: inline-block;
	height: 28px;
	line-height: 28px;
	font-weight: bold;
	font-size: 10px;
	text-transform: uppercase;
	white-space: nowrap;
	padding: 0 6px 0 0;

	&:before {
		color: inherit;
		font-size: 22px;
		margin-right:5px;
		vertical-align: middle;
	}

	&:hover {
		opacity: 0.85;
		text-decoration: none;
	}
}
.depositories-button{
	&:before {
		margin-right:0;
	}
}
.similars-button{
	&:before {
		@extend %icon-search;
	}
}
.print-button {
	&:before {
		@extend %icon-print;
	}
}
.partnership-button {
	position: relative;
	padding-left: 32px;
	max-width: 140px;
	height: auto;
	line-height: 14px !important;
	white-space: initial;
	&:before {
		@extend %icon-money-multibill;
		position: absolute;
		top: 2px;
		left: 0;
	}
	.label-new{
		display:inline-block;
		margin: 5px 0;
		padding:1px 6px;
		background:#6EB040;
		border-radius:20px;
		text-transform:uppercase;
		color:#ffffff;
		font-size:10px;
		line-height:13px;
		font-weight:bold;
	}
}

.depositories-button, .nearest-shop-button {
	&:before {
		@extend %icon-location;
	}
}

.call-button {
	border: 1px solid #fff;
	&:before {
		@extend %icon-phone3;
		font-size: 18px;
	}
}
.shop-delivery-action, .home-delivery-action, .mail-delivery-action, .phone-delivery-action {
	display: inline-block;
	line-height: 19px;
}

// na potrzeby testu AB nowej KP
.product-card-test-enabled {
	.delivery-message-new {
		color: $text-color;
		font-weight: normal;
		display: inline;

		.delivery-message-label {
			color: $link-color;

			&.with-prefix {
				color: $delivery-info-color;
				font-weight: bold;

				.icon-delivery-info {
					color: $delivery-info-color;
				}
			}

			.icon-delivery-info {
				color: #999;
				margin-left: 3px;
			}
		}

		&:hover {
			text-decoration: underline;
		}

		+ .delivery-message {
			display: none;
		}
	}

	.product-button .delivery-price {
		color: $delivery-info-color;
	}
}

.shop-delivery-action {
	&:before {
		@extend %icon-location;
		color: $text-color;
		font-size: 16px;
		margin-right: 4px;
		margin-left:1px;
	}
}

.delivery-price{
	display: inline-block;
	&:before {
		@extend %icon-shield;
		color: $text-color;
		font-size: 16px;
		margin-right: 3px;
		margin-left: 1px;
	}
}
.terminal-enabled{
	display: inline-block;
	padding-left:22px;
	position: relative;
	&:before {
		@extend %icon-credit-card;
		color: $text-color;
		font-size: 16px;
		margin-right: 4px;
		position: absolute;
		top:3px;
		left:0;
	}
	&:after{
		content:"nowo\015B\0107";
		text-transform: uppercase;
		font-size:10px;
		color:#fff;
		background: #00b242;
		padding: 3px 5px;
		display: inline-block;
		border-radius: 8px;
		font-weight: normal;
	}
}


.pickup-points {
	padding-bottom:10px;
	> div{
		@if($app-ole){
			color:#777;
		}
		@if($app-euro){
			font-weight: bold;
			color:$text-color;
			&:before {
				@extend %icon-location;
				display: inline-block;
				font-size: 16px;
				width:20px;
				text-align:left;
				margin-right: 0px;
				margin-left: 2px;
			}
		}
	}
	span{
		display: inline-block;
		width:50px;
		height:38px;
		background:#fff no-repeat center center;
		background-size: contain;
		border-radius: 18px;
		margin-top:5px;
		+ span{
			@if($app-euro){
				margin-left:2px;
			}
			@if($app-ole){
				margin-left:1px;
			}
		}
	}
	.inpost{
		background-image: url(/img/desktop/inpost.png);
		background-size: 45px;
	}
	.poczta{
		background-image: url(/img/desktop/poczta.png);
		background-size: 32px;
	}
	.zabka{
		background-image: url(/img/desktop/zabka.png);
		background-size: 43px;
	}
}

@if ($app-ole == false) {
	.home-delivery-action {
		&:before {
			@extend %icon-delivery-shop;
			color: $text-color;
			font-size: 17px;
			width:20px;
			text-align:left;
		}
	}
} @else {
	.home-delivery-action {
		.delivery-message-label {
			display: block;
			&:after {
				@extend %icon-delivery-info;
				color: $delivery-info-color;
				margin-left: 3px;
				margin-top: -2px;
			}
		}
	}
}

.mail-delivery-action {
	&:before {
		@extend %icon-cloud;
		color: $text-color;
		font-size: 17px;
		width:20px;
		text-align:left;
		margin-right: 4px;
	}
}

.phone-delivery-action {
	&:before {
		@extend %icon-edit;
		color: $text-color;
		font-size: 17px;
		width:20px;
		text-align:left;
		margin-right: 4px;
	}
	&:after{
		content:"i";
		font-size: 9px;
		width:11px;
		height:11px;
		color:$text-color-lighter;
		vertical-align: middle;
		border:1px solid $text-color-lighter;
		border-radius: 50%;
		text-align:center;
		display: inline-block;
		margin-left: 2px;
		margin-bottom: 4px;
		line-height: 11px;
	}
}

.remove-button {
	&:after {
		@extend %icon-trash;
	}
}