#shops-modal {

	max-width: 940px;
	padding: 0 0 20px;

	* {
		box-sizing: border-box;
	}
	.product-info-box::after{
		content:'';
		display: block;
		clear: both;
	}
	.special-hours-box {
		display: table;
		width:100%;
	}
	.special-working-hours{
		color: #ED1C24;
		padding: 5px 0 0 0;
	}

	.special-working-hours-header{
		font-weight:bold;
		padding-bottom:10px;
	}

	.date-description{
		float:left;
		width:150px;
	}

	.hours-description{
		float:left;
		width:80px;
	}

	.column {
		float: left;
		width: 33.33%;
		&:nth-child(2n), &:nth-child(3n) {
			padding-left:58px;
		}
	}

	.special-data-container {
		width: 100%;
		float: none;
		overflow: auto;
		font-size: 11px;
	}

	.delivery-data {

		float: left;
		width: 50%;
		box-sizing: border-box;
		vertical-align: top;
		margin-top: 10px;


		label {
			font-size: 14px;
			margin-right: 5px;
			display: inline-block;
			width: 130px;
			vertical-align: top;
			line-height: 30px;
		}

		#zip-code, .select3-container {
			display: inline-block;
		}

		#zip-code {
			width: 74px;
		}

		.select3-container {
			width: 280px;

			.select3-option {
				height: 30px;
			}

			.select3-option.hidden-item {
				display: none;
			}
		}

		.confirm-city-button {
			display: inline-block;
			vertical-align: top;
			@extend %btn-primary;
			@extend %btn-input-size;
			margin-left: 10px;
		}

		label.error {
			display: none !important;
		}
	}

	#search-city {
		padding: 20px 30px 0;

		.title {
			font-size: 24px;
		}
	}

	.product-data {
		float: left;
		width: 48%;
		border: 1px solid #cacaca;
		box-sizing: border-box;
		vertical-align: top;
		margin-top: 20px;
		padding: 10px;
		margin-right: 2%;
		display: table;

		.photo {
			display: table-cell;
			margin-right: 18px;
			text-align: center;
			vertical-align: middle;

			img {
				width: 102px;
				width:91px;
			}
		}

		.info {
			display: table-cell;
			vertical-align: middle;
		}

		.product-name {
			font-size: 15px;
		}

		.product-category {
			color: #9b9b9b;
			display:inline-block;
		}
		.product-code{
			color: #9b9b9b;
		}
	}

	.same-day-delivery-notice {
		background-color: #E6F8FE;
		margin: 5px 10px;
		padding: 15px 25px;
		text-align: center;
		font-size: 13px;
	}

	#only-available-box {
		clear: both;
		padding-top: 15px;
		width: 100%;
	}
	#shops-details{
		clear:both;
		margin-top: 10px;
	}
	#show-all-available-shops-message{
		text-align:center;
		padding:10px 0 20px;
		p{
			color: #1c1c1b;
			display:block;
			padding:10px 0 0;
			font-size:14px;
		}
		.show-all-available-shops{
			@extend %btn-third;
			margin:30px auto 0;
		}
	}
	.shop-city-header{
		background-color: #eaeaea;
		color: #1c1c1b;
		font-size: 20px;
		font-weight: bold;
		padding: 5px 15px;
		margin-bottom:10px;
	}
	#shops-list {
		padding: 25px 30px 0;
		margin-top: 12px;
		border-top: 1px solid #cacaca;
		position: relative;
		overflow: hidden;

		.shop-item {
			margin-bottom: 20px;
		}

		.shop-data-box {
			overflow: hidden;
		}

		.shop-data {
			padding-right: 10px;
			float: left;
			width: 38%;
			vertical-align: top;
			line-height: 1.6;
		}

		.shop-code{
			font-weight: bold;
			padding:3px;
			background-color:#FFFFCC;
			margin-bottom:10px;
			display:none;
		}

		.shop-description {
			float: left;
			width: 40%;
			vertical-align: top;
			padding-right: 10px;

			[class*="-tick"] {
				background: none;
				padding-left: 0;
				font-size: 14px;

				&::before {
					margin-right: 5px;
					font-size: 15px;
					margin-top: -7px;
				}
			}

			.no-tick {
				color: #9a9a9a;
				&::before {
					@extend %icon-close;
					color: $text-color-error;
				}
			}

			.yes-tick {
				&::before {
					@extend %icon-checked;
					color: #06b665;
				}
			}

			.status-description {
				display: block;
				margin-left: 17px;
				margin-top: 3px;
				color: #9a9a9a;
			}
		}

		.name {
			padding: 0;
			font-size: 16px;
		}

		.shop-actions {
			float: left;
			width: 22%;
			vertical-align: top;

			.add-to-cart {
				width: 100%;
				@extend %btn;
				@extend %btn-primary;
			}
		}

		.details-hours, .details-transport, .details-phone {
			display: inline-block;
			vertical-align: top;
			padding-right: 30px;
			max-width: 40%;
			line-height: 1.8;
		}

		.details-hours {
			max-width: 20%;
		}

		.details-phone {
			padding-right: 0;
		}

		.details-title {
			font-weight: bold;
		}

		.shop-details {
			padding: 0;
			margin-top: 10px;
			display: none;
		}

		.details-button {
			position: relative;
			display: none;
			padding: 2px 2px 2px 18px;
			cursor: pointer;
			color: $link-color;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -4px;
				border-left: 7px solid transparent;
				border-right: 7px solid transparent;
				border-top: 7px solid #005CA9;
			}

			&.open-details {
				display: block;
			}
		}

		.shop-item-details-state {
			display: none;

			&:checked{
				~ .shop-details {
					display: block;
				}

				~ .shop-data {
					.details-button {
						display: none;
					}
				}

				~ .shop-data-box {
					.details-button {
						display: none;
					}
				}

				~ .details-button {
					&::before {
						transform: rotate(180deg);
					}

					&.hide-details {
						display: block;
					}
				}
			}
		}

		.shop-info {
			margin-top: 10px;
		}

		&.show-more {

			.shop-item {
				display: block;

				&.hidden {
					display: none;
				}
			}

			&::after {
				height: 90px;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				pointer-events: none;
				background: linear-gradient(rgba(255,255,255,0), white);
				content: "";
				display: block;
			}

			~ #more-shops {
				display: block;
			}
		}
	}

	#no-items {
		margin-top: 30px;
		text-align: center;
		padding: 0 30px 30px;
	}

	#more-shops {
		@extend %btn-expand;
		margin-bottom: 0;
		display: none;
	}

	#shop-info {
		padding: 0 30px;
		margin-top: 20px;
	}

	#shops-details.in-progress {
		@extend %loading;
	}

	&.in-progress {
		@extend %loading;
	}
}
