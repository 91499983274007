@if(not $app-katalog) {
#cart-box {
	position:absolute;
	right:5px;
	@if($app-euro){
		top:16px;
	}@else{
		top:18px;
	}
	@media (max-width: #{$breakpoint-large}px) {
		@if($app-euro){
			right: 2px;
		}@else{
			right: 0px;
		}
	}
	width: 44px;
	height: 46px;
	
	
	> a, >span {
		position:relative;
		width:100%;
		height:100%;
		display:block;
		&:hover{
			text-decoration:none;
		}
		&:before{
			@if($app-euro){
				color:#fff;
			}@else{
				color:#0a0a0a;
			}
			display: block;
			font-size: 17px;
			@extend %icon-basket2;
			font-weight: normal;
			text-align: center;
			height: 19px;
			margin-top: 8px;
			margin-left:-3px;
		}
		&::after{
			display:block;
			@if($app-euro){
				color:#fff;
			}@else{
				color:#0a0a0a;
			}
			content: 'Koszyk';
			text-align: center;
			.sticky &{
				display:none;
			}
		}
		> span {
			border:2px solid #fff;
			background: #F50A1B;
			min-width:10px;
			padding:0 6px;
			height:19px;
			line-height:15px;
			right:0px;
			top:-11px;
			color:#fff;
			font-size:10px;
			border-radius:10px;
			font-weight:bold;
			display:block;
			position:absolute;
			text-align:center;
			z-index:1;
			&.empty{
				@if ($app-euro) {
					color:#dadada;
					border-color: #dadada;
					background-color:#fff;
				} @else {
					color:$primary-color;
					background-color:#fff;
					
				}
			}
		}
	}
	&.refreshed > a > span{
		animation-name: balonik;
		animation-duration: 1.5s;
		@keyframes balonik {
			0% {
				@if($app-euro){
					background: #FFE843;
				}
				@if($app-ole){
					background: #368F43;
				}
				transform: scale(1);
			}
			50% {
				@if($app-euro){
					background: red;
				}
				@if($app-ole){
					background: #008FC7;
				}
				transform: scale(1.2);
			}

			100% {
				@if($app-euro){
					background: #FFE843;
				}
				@if($app-ole){
					background: #368F43;
				}
				transform: scale(1);
			}
		}
	}
}

#cart-items{
	display:none;
	background:#fff;
	border:1px solid $border-color;
	position:absolute;
	top:46px;
	right:-15px;
	width:360px;
	z-index:2;
	overflow: hidden;
	#cart-focus{
		position: absolute;
		top:-40px;
		width:10px;
		height: 10px;
	}
	h3{
		margin:12px 0;
		text-align: center;
		font-size:15px;
		font-weight: bold;
		padding:0;
		display: block;
		white-space: nowrap;
	}
	.items {
		max-height: 80vh;
		overflow-y: auto;
		&.loading {
			@extend %loading;
		}
	}
	.cart-added {
		display:none;
		border-top:1px solid $border-color;
		padding:10px 15px 10px;
		background:#eeeeee;
		span{
			font-weight: bold;
		}
	}
	.image {
		float: left;
		margin-right: 8px;
		img {
			border: 1px solid #eee;
			height: auto;
			width: 40px;
			display: block;
			font-size:5px;
			overflow: hidden;
		}
	}
	.item {
		border-bottom: 1px solid #eee;
		display: list-item;
		list-style: none;
		font-size:12px;
		margin: 0 3px 0 8px;
		max-height: 400px;
		padding: 10px 0;
		&:after {
			display: block;
			content: '';
			clear: both;
		}
		.hide {
			display: none;
		}
		&:hover{
			&, a {
				text-decoration: none;
			}
		}
		@if($app-ole){
			a {
				color: #008FC7;
			}
		}
	}
	.item-details {
		float: left;
		width: calc(100% - 50px);
		> div {
			color:#1c1c1b;
			display: inline-block;
			vertical-align: top;
		}
	}
	.promotion-package-service{
		.image{
			width: 40px;
			text-align: center;
			&:before{
				@extend %icon-service1;
				color: #767676;
				font-size:24px;
			}
		}
	}
	.package-item:not(.main-package-item), .promotion-package-service{
		position: relative;
		&:before {
			content: "+";
			position: absolute;
			top: -11px;
			left: 0;
			font-size: 14px;
			color: #999;
			background: #fff;
			width: 10px;
			font-weight: bold;
			padding-right: 10px;
			padding-left: 17px;
		}
	}
	
	.item-services-pack, .item-service, .item-warranty {
		width: 100%;
		> div {
			display: inline-block;
			vertical-align: top;
		}
		border-bottom: 0;
		margin: 0;
		padding: 5px 0 0 0;
	}
	.service-name, .warranty-name {
		width: 70%;
		font-weight: bold;
		font-size: 10px;
	}
	.item-category {
		color: #777;
		font-size: 10px;
	}
	.name {
		width: 62%;
	}
	.quantity {
		white-space: nowrap;
		text-align: right;
		width:20px;
		@if($app-ole){
			font-weight: bold;
		}
		&::after {
			content:"x";
		}
	}
	.accessory-box,
	.partner-subscription-box,
	.subscription-service-box {
		margin-left: 10px;
		margin-top: -1px;
		background: #fff;
		.item{
			border-color: #f4f4f4;
		}
		.item:last-child{
			border-bottom: 0;
		}
		&+.item{
			border-top: 1px solid #eee;
		}
		&:last-child{
			border-bottom: 1px solid #eee;
		}
	}
	.partner-subscription-box + .subscription-service-box,
	.partner-subscription-box + .accessory-box{
		.item:first-child{
			border-top: 1px solid #eee;
		}
	}
	.price {
		font-size: 12px;
		font-weight: bold;
		text-align:right;
		white-space: nowrap;
		width: 55px;
		&.price-promotion{
			color: $promotion-price-color;
		}
	}
	.item-product {
		cursor: default;
	}
	.remove-item {
		float: right;
		cursor: pointer;
		padding: 0 3px 0 5px;
		&:before {
			color: $link-color;
			@extend %icon-trash;
			position: relative;
			top: -1px;
		}
	}
	.summary {
		float: right;
		padding:10px;
	}
	.price-labels {
		float: left;
		margin-right: 10px;
	}
	.prices {
		float: right;
	}
	.products-price, .transport-price {
		text-align: right;
	}
	.products-price {
		font-size: 14px;
		font-weight: bold;
		&.price-promotion{
			color: $promotion-price-color;
		}
	}
	.products-label {
		font-size: 14px;
		font-weight: bold;
	}
	.button {
		padding: 10px;
		float: right;
		@if($app-ole){
			a {
				text-transform: uppercase;
			}
		}
	}
	.banners {
		clear: both;
	}
}

@media all and (max-height: 700px) {
	#cart-items .items{
		max-height: 64vh;
	}
}
@media all and (max-height: 500px) {
	#cart-items .items{
		max-height: 50vh;
	}
}
@media all and (max-height: 400px) {
	#cart-items .items{
		max-height: 45vh;
	}
}

.sticky{
	@media all and (max-height: 700px) {
		#cart-items .items{
			max-height: 74vh;
		}
	}
	@media all and (max-height: 500px) {
		#cart-items .items{
			max-height: 60vh;
		}
	}
	@media all and (max-height: 400px) {
		#cart-items .items{
			max-height: 45vh;
		}
	}
}
}