.main-page-top-banner-wrapper {
	z-index:0;
	position:relative;
	width: 1180px;
	padding:0 10px;
	margin: 0 auto;
	margin-top: 10px;
	#mainPageTopBanerR {

	}
	#bannerView {
		width: 753px;
		height: 331px;
	}
}


@media all and (max-width: #{$breakpoint-large}px) {
	.main-page-top-banner-wrapper {
		width: 940px;
		#mainPageTopBanerR {
			width: 764px;
		}
	}
}

#mainPageExtraBanner {
	width:230px;
	float:right;
	.topNewsBanner {
		overflow: hidden;
	}
}
.topNewsBanner {
	margin-top: 10px;
	height: 118px;
	background-color: #fff;
	border: 1px solid #ccc;
	&:first-child {
		margin-top: 0;
	}
}
