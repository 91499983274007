#category-list {
	h1 {
		font-size: 24px;
		font-weight: bold;
		margin: 0;
		padding: 0;
		display: inline;
	}

	.category-items-count {
		font-weight: $font-weight-light;
		color: #999;
		font-size: 24px;
	}

	.category-list-header {
		padding: 0 10px 10px;
		border-bottom: 1px solid $border-color;
		margin: 0 0 10px;
	}

	.category-list-row {
		margin-bottom: 15px;
	}

	.category-list-container {
		display: table;
		border-spacing: 15px 0;
		margin: 0 -15px;
	}

	.category-list-box {
		border: 1px solid $border-color;
		width: 200px;
		padding: 10px;
		display: table-cell;
	}
	@media screen and (max-width: $breakpoint-large+px) {
		.category-list-box {
			width: 152px;
		}
	}

	.category-list-box-link {
		img {
			width: 122px;
			height: 120px;
			display: block;
			margin: 0 auto;
		}
	}

	.category-list-header-link {
		text-align: center;

		a {
			color: #1C1C1B;
		}
	}

	.category-list-box-number {
		text-align: right;
		color: $text-color-lighter;
	}

	.category-list-box-name {
		color: $text-color;
		font-size: 18px;
		margin-top: 14px;
		display: block;
	}
	
	.category-list-footer {
		line-height: 15px;
		text-align: justify;
		padding: 10px 0px;

		//stylowanie dla elementów, których nie ma w kodzie aplikacji - html pochodzi z komunikatów konsoli (opisy liści kategorii).
		h2 {
			font-size: 20px;
			margin: 0px 0 10px;
		}

		h3 {
			display: block;
			font-size: 16px;
			font-weight: bold;
			margin: 10px 0;
			padding: 0;
		}
		
		@extend %article-list;
	}
}
