.paging {
	padding: 10px 0;
	overflow: hidden;
	display: table;
	margin: 0 auto 20px;
	a, span {
		font-size: 14px;
		cursor: pointer;
		display: table-cell;
	}
	.paging-number {
		border-left: 1px solid #ddd;
		width: 40px;
		height: 40px;
		line-height: 40px;
		box-sizing: border-box;
		text-align: center;
	}
	.paging-number:last-child {
		border-right: 1px solid #ddd;
	}
	.paging-active {
		background-color: $paging-active-background;
		color: $text-color;
		cursor: default;
	}
	.paging-dots {
		cursor: default;
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-left: 1px solid #ddd;
		text-align: center;
	}
	a:hover {
		text-decoration: none;
	}
	.paging-previous, .paging-next {
		text-transform: uppercase;
		height: 40px;
		line-height: 40px;
		text-align: center;
		padding: 0 20px;
	}
}