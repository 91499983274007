@import "../../app";

$text-color					: #000;	//tekst podstawowy
$text-color-lighter			: #444;	//text pomocniczy
$link-color					: #777;	//linki
$link-color-hover			: #333;	//linki

$background-second   			: #333;	//button seccondary
$background-second-hover		: #eee;	//button seccondary hover
$background-alert				: red;	//button alert
$background-alert-hover			: red;	//button alert hover
$background-primary				: #fff;	//button primary
$background-primary-hover		: #eee;	//button primary hover
$background-dark				: #666;	//ciemne tło
$background-light				: #999;	//jasne tło
$background-hightlight-dark		: #ddd;
$background-hightlight-light	: #aaa;
$border-color					: #ccc;
$promotion-price-color          : #ed1c24;

$img-url: '/img/';

$font-default: 'Lato', sans-serif;
$font-default-with-ios: 'Lato', -apple-system, Helvetica, Arial, sans-serif;
$font-default-size: 12px;
$font-default-line-height: 1.2em;

$font-weight-light: 200;
$font-weight-normal:normal;
$font-weight-bold:bold;

$default-box-border-radius:0;

$default-box-shadow-horizontal:0;
$default-box-shadow-vertical:0;
$default-box-shadow-blur:4px;
$default-box-shadow-color: rgba(0,0,0,0.2);
