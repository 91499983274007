#change-password {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    padding: 15px;

    .validate-target {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 380px;
        position: relative;
        input {
                width: 185px;
            }
    }

    .validate-error {
        background-color: #fad6d4;
    }

    .validate-target label {
        margin-right: 5px;
        min-width: 90px;
        &.error {
            margin: 5px auto;
        }
    }

    .obligatory-change-password-btn {
        margin-top: 10px;
        border-radius: 0;
    }
    
    .password-invalid {
        padding-left: 100px;
        position: static;
    
        .password-toggle {
            top: 20px;
            right: 40px;
        }
    }

    .error-box:before {
        margin: 5px 0;
        background-color: #fad6d4;
        opacity: 1;
    }

}

.obligatory-change-password-title {
    margin: 10px;
    font-size: 24px;
}