
#top-bar {
	border-bottom:1px solid $border-color;
	&:empty{
		border-bottom:0;
	}
	background:#fff;
	z-index:3;
	position: relative;
	> div{
		width:$breakpoint-large-width+px;
		margin:0 auto;
		line-height: 40px;
	}
	.operator-bar {
		position: relative;
		display: flex;
		width:$breakpoint-large-width+px;
		margin:0 auto;
		line-height: 40px;
		.legend, .operator-info, #operator-plu-compare {
			display: inline-block;
		}
		#operator-plu-compare {
			margin-left: auto;
		}
		.account-info {
			display: flex;
			align-items: flex-start;
			width: auto;
			margin-left: auto;
		}
		#operator-plu-compare + .account-info {
			margin-left: 0;
		}
	}
	.partner-bar {
		position: relative;
		display: flex;
		margin:0 auto;
		line-height: 40px;
		.operator-info {
			margin-left: auto;
		}
	}
	.legend{
		font-weight:bold;
		font-size:13px;
		width:150px;
	}
	.account-info{
		width: 10px;
		white-space: nowrap;
	}
	.account-info > *, .partner-bar .operator-info{
		text-align:right;
	}
	.partner-name, .operator-name, .customer-name{
		overflow:hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
	}
	.logout-operator, .clear-cart, .logout-partner, .partner-password{
		@extend %btn;
		@extend %btn-small;
	}

	.logout-operator{
		@extend %btn-third;
	}
	.logout-customer{
		@extend %btn-link;
	}
	.clear-cart{
		@extend %btn-primary;
	}
	.logout-partner{
		@extend %btn-third;
	}
	.partner-password{
		@extend %btn-primary;
	}
	a{
		margin-left:20px;
		vertical-align: middle;
	}
	label, .legend{
		display:inline-block;
	}
	span{
		font-weight:bold;
	}
	#operator-logged-customer, .operator-info, .partner-info{
		> label, >span{
			display: inline-block;
			vertical-align: middle;
		}
	}
	#operator-logged-customer {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-content: center;
		padding-right: 20px;
		background: #FFFAD1;
		border-radius: 10px 0 0 10px;
		label {
			display: flex;
			align-items: center;
			padding: 0 5px;
			font-size: 14px;
			font-weight: bold;
			&::before {
				content: '';
				position: relative;
				display: flex;
				width: 51px;
				height: 16px;
				margin: 0 5px;
				background-image: url('/img/desktop/moje-euro.png');
				background-repeat: no-repeat;
				background-size: contain;
			}
			&::after {
				content: '|';
				position: relative;
				display: flex;
				padding-left: 5px;
				font-size: inherit;
			}
		}
		.customer-name {
			font-size: 14px;
			font-weight: normal;
		}
		.logout-customer {
			font-size: 14px;
			margin-left: 10px;
			color: #2878F4;
		}
	}
	.partner-name, .customer-name, .partner-bar .operator-name {
		max-width:250px;
	}
	.operator-bar .operator-name {
		max-width:200px;
	}
}

@media all and (max-width: #{$breakpoint-large}px) {
	#top-bar {
		.partner-bar{
			width:#{$breakpoint-midium-width}px;
		}
		.partner-name, .partner-bar .operator-name{
			max-width:200px;
		}
	}
}
