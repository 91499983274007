
#product-tabs {
	width:920px;
	@media screen and (max-width: $breakpoint-large+px), print {
		width:680px;
	}
	z-index:1;
	margin: 0 10px;
	position:relative;
	.product-tab-header {
		background-color: #eaeaea;
		font-weight: bold;
		padding: 5px 15px;
		font-size: 24px;
		color: #1c1c1b;
	}
	.product-tab {
		margin-bottom: 60px;
		position: relative;
	}
	&.outlet-tabs{
		.product-tab-akcesoria,
		.product-tab-zestawy,
		.product-tab-zobacz-takze,
		.product-tab-pasuje-do{
			display:none !important;
		}
	}
}
