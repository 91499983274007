.warranties-questionnaire {
	display: none;
	.operator-logged &{
		display: flex;
	}
	.operator-logged .new-warranty-encouragement .photo-warranties.default-warranty-encouragement & ~ .warranty-graphics {
		top: 160px;
	}
	flex-direction: row;
	flex-wrap: wrap;
	#product-upselling &{
		flex-direction: column;
	}
	align-items: flex-start;
	margin-bottom: 20px;
	padding: 20px;
	@if($app-ole) {
		padding: 15px;
	}
	border-radius: 5px;
	background: #FFFCE6;
	color: #0A0A0A;
	@if($app-katalog) {
		display: flex;
		background: #E6EEF6;
		#warranties &{
			margin-top: 15px;
		}
	}

	.warranties-questionnaire-title {
		margin: 0 0 10px;
		padding: 0;
		font-size: 14px;
		font-weight: bold;
	}
	.warranties-questionnaire-subtitle {
		margin: 0 0 10px;
		padding: 0;
		font-size: 12px;
		line-height: 18px;
		font-weight: normal;
		text-transform: lowercase;
		&::before{
			content: '–';
			position: relative;
			display: inline;
			margin: 0 5px;
			color: inherit;
			font-weight: inherit;
			font-size: inherit;
			line-height: inherit;
		}
		#product-upselling &{
			text-transform: initial;
			&::before {
				display: none;
			}
		}
	}
	.warranty-questions {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		width: 100%;
		@if($app-katalog) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
		}
	}
	.warranty-question {
		align-self:stretch; 
		padding: 10px;
		background: rgba(255, 255, 255, 0.85);
		box-shadow: 0px 10px 20px rgba(48, 49, 51, 0.07), 0px 2px 7px rgba(40, 41, 42, 0.1), inset 0px 0px 0px 1px #E5E5E5;
		border-radius: 7px;
		@if($app-katalog) {
			width: 50%;
		}
		&:first-child {
			margin-bottom: 10px;
			@if($app-katalog) {
				margin-bottom: 0;
				margin-right: 10px;
			}
		}
		&.error {
			background: #ffcccc;
			box-shadow: inset 0px 0px 0px 2px #ED1C24;
		}
	}
	.warranty-question-copy {
		font-size: 12px;
		font-weight: bold;
		margin-bottom: 10px;
	}
	.warranty-answers {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.warranty-answer {
		display: flex;
		&:first-child {
			margin-right: 15px;
		}
		.text {
			display: flex;
			align-items: center;
			font-size: 12px;
			i{
				margin-right: 5px;
			}
		}
	}
}
@if($app-katalog) {
	#order-products-list {
		.warranties-questionnaire {
			margin-bottom: 0;
		}
	}
	//style dla podgladu zamówienia sa zrealizowane w vue/order-preview/scss/components/_warranty-upselling-items.scss

	
}

.cart-warranty-box {
	.warranty-questions {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		align-self: stretch;
	}
	.warranty-question {
		width: 50%;
		padding-right: 5px;
		&:first-child {
			margin-bottom: 0;
			margin-right: 10px;
		}
		.warranty-answers {
			align-items: flex-start;
		}
	}
	.warranty-answer {
		width: 50%;
		&:first-child {
			margin-right: 10px;
		}
		.text {
			position: relative;
			padding-left: 23px;
			i{
				position: absolute;
				top: 0;
				left: 0;
				margin-right: 0;
			}
		}
	}
}

#warranty-form {
	.warranties-questionnaire {
		margin: 20px;
	}
	.warranty-questions {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		align-self: stretch;
	}
	.warranty-question {
		width: 50%;
		&:first-child {
			margin-bottom: 0;
			margin-right: 10px;
		}
	}
}
@if($app-katalog==false) {
	.operator-logged {
		.encouragement-box {
			flex-wrap: wrap;
			.warranties-questionnaire {
				z-index: 1;
				position: relative;
				display: flex;
				align-self: flex-start;
				flex: 1 0 auto;
				width: 100%;
			}
		} 
		#warranty-form .columns-1 .warranties-questionnaire {
			margin: 0 0 10px 0px;
		}
		#warranty-form .columns-2 .warranties-questionnaire {
			margin: 0 0 10px -40px;
		}
		.new-warranty-encouragement .photo-warranties .encouragement-box > img {
			width: auto;
			height: 100%;
		}
	}
}