#promotions-list-modal {
	padding: 15px 0;
	border-radius: 2px;

	.modal-title {
		padding: 0 15px;
		display: block;
		font-weight: bold;
		font-size: 22px;
		color: #333333;
		margin-bottom: 24px;
	}

	.product-simple-info {
		padding: 0 15px;
		display: flex;
		align-items: center;
		margin-bottom: 24px;

		img {
			width: 78px;
			height: auto;
			border: 1px solid #cccccc;
		}

		.details {
			font-size: 14px;
			margin-left: 12px;
		}

		.name {
			font-weight: bold;
		}

		.price {
			font-size: 14px;
			color: #e30613;
			font-weight: bold;

			&.price-outlet {
				color: #498dc5;
			}
		}
	}

	.promotion-items {
		display: flex;
		flex-direction: column;
		padding: 15px;
		background: #f7f7f7;
	}

	.promotion-item {
		display: flex;
		flex-wrap: nowrap;
		background: #ffffff;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
		margin-bottom: 12px;
		padding: 16px;
		text-decoration: none !important;
		border-radius: 2px;

		&:last-child {
			margin-bottom: 0;
		}

		.promotion-img {
			display: flex;
			flex: 0 0 60px;
			align-items: center;
			width: 60px;
			height: auto;

			img {
				width: 100%;
				height: auto;
			}
		}

		.promotion-description {
			margin-left: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	a.promotion-item {
		&:hover {
			.title {
				text-decoration: underline;
			}
		}
	}

	.promotion-description {
		.badge {
			padding: 4px 6px;
			margin-bottom: 6px;
			font-weight: bold;
			font-size: 12px;
			color: #333333;
			background: #ffffff;
			text-align: left;
			border-radius: 2px;
		}

		.title {
			margin-bottom: 4px;
			font-weight: bold;
			font-size: 14px;
			color: #333333;

			.info-icon {
				position: relative;
				display: inline;

				&:after {
					@extend %icon-delivery-info;
					position: absolute;
					top: 2px;
					color: #999999;
				}
			}
		}

		.details {
			font-size: 11px;
			color: #999999;
		}

		.advertising-date {
			margin-top: 10px;
			font-size: 11px;
			color: #999999;
		}
	}
}