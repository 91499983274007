.accessories {
	&.loading{
		@extend %loading;
	}
	&.show-all-categories {
		.category-access-hidden {
			display: block;
		}
		.category-access-more {
			display: none;
		}
	}
	.accessories-groups {
		margin: 15px 0 5px;
		overflow: hidden;
	}
	.accessories-title {
		font-size: 17px;
		font-weight: bold;
		padding: 0 15px;
	}
	.category-access, .category-access-more {
		text-align: left;
		text-transform: none;
		width: 25%;
		color: $link-color;
		padding: 5px 15px;
		margin-bottom: 5px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		float: left;
		@media screen and (max-width: $breakpoint-large+px) {
			width: 33%;
		}
		&::before {
			@extend %icon-big-arrow;
			padding-right: 10px;
		}
		&:hover {
			background-color: $accessories-active-background;
		}
	}
	.category-access-hidden {
		display: none;
	}
	.active {
		color: $text-color;
		background-color: $accessories-active-background;
	}
	.accessories-list {
		overflow: hidden;
		&.loading{
			@extend %loading;
		}
	}
	.accessory-item {
		position: relative;
		width: 184px;
		float: left;
		padding: 15px;
		border: 1px solid #fff;
		margin: 10px 0;
		box-sizing: border-box;
		&.loading{
			@extend %loading;
			border-color:#ddd;
		}
		@media screen and (max-width: $breakpoint-large+px) {
			width: 170px;
		}
	}
	.col-img {
		margin-bottom: 10px;
	}
	.product-image {
		display: block;
		margin: 0 auto;
		width: 102px;
		height: 91px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.accessory-grade {
		.stars-rating a::before, .stars-rating span::before {
			font-size: 15px;
		}
		.stars-rating a {
			width: 75px;
		}
	}
	.price-omnibus, .price-before {
		font-size: 12px;
		text-align:left;
	}
	.product-prices-instalment{
		.price-normal {
			font-size: 12px;
			font-weight: normal;
			display:inline-block;
		}
		/*
		.price-old {
			font-size: 12px;
			display:inline-block;
			margin-left:10px;
		}
		*/
		.price-instalments-rates{
			font-size:16px;
			//color:#ED1C24;
			font-weight: bold;
		}
	}

	.subscription-price {
		align-items: flex-start;
		p {
			text-align: left;
			span {
				display: block;
			}
		}
		.price-monthly {
			font-size: 18px;
		}
		.promotion-price {
			font-size: 12px;
		}
	}

	.price-normal {
		font-size: 18px;
		display: inline-block;
	}
	/* EURSI-37170
	.price-old {
		display: inline-block;
		font-size: 12px;
		font-weight: normal;
	}*/
	.col-desc {
		height: 131px;
		overflow: hidden;
		position: relative;
		h3 {
			margin: 5px 0;
			max-height: 56px;
			overflow: hidden;
			line-height: 20px;
			padding: 0;
			a {
				font-size: 12px;
				display: block;
				max-height: 56px;
				color:$text-color;
			}
		}
		.product-attributes{
			a, span{
				color:#999;
				line-height: 14px;
				font-size:11px;
			}
			.attributes-row{
				padding:0;
			}
		}
		&:after{
			position: absolute;
			bottom:0;left:0;width:100%;
			height:16px;
			content: "";
			background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
		}
	}
	.price-instalments-rates {
		//color: #e30613;
		margin-top: 5px;
	}
	.add-accessory-to-cart {
		margin: 5px auto;
		width: 115px;
	}
	#accessories-list-view {
		height: 337px;
		overflow: hidden;
		position: relative;
	}
	.accessories-list-carousel {
		position: absolute;
		overflow: hidden;
		left: 0;
	}
	@if($app-katalog){
		#accessories-filter{
			padding:20px 10px;
			border-bottom: 1px solid $border-color;
			label{
				display:inline-block;
				vertical-align: top;
				margin:5px 5px 0 0;
				font-size: 13px;
			}
			.select3-container{
				display:inline-block;
			}

		}
	}

}
