@import "../common/general/variables";

$font-url:'/font/desktop/ole/';
$font-name:'Lato';
$font-regular:'Regular';
$font-bold:'Bold';
$font-italic:'Italic';
$font-light:'Light';

$primary-color: #00c61e;
$accent-color: $primary-color;

$text-color					: #1C1C1B;	//tekst podstawowy
$text-color-lighter			: #999999;	//text pomocniczy
$text-color-error			: #e30613;	//text błędu

$link-color					: #0072ff;	//linki
$link-color-hover			: #0072ff;	//linki

$novelty-color				: #58ad33;  //kolor statusu nowości
$promotion-color			: #d32f36;  //kolor statusu promocji
$sale-color					: #45abe5;  //kolor statusu wyprzedaży
$bestseller-color			: #45abe5;  //kolor statusu bestseller
$advance-sale-color			: #ff9000;  //kolor statusu przedsprzedaz

$loader-color				: #00c61e;	//kolor animacji kręciołka
$filter-selected			: #EBF8FE;	// kolor aktywności w bloczku filtrowania

$checkbox-color				: #00c61e;	//kolor checkboxa
$checkbox-background: $primary-color;
$checkbox-active-background: #333333;
$checkbox-text-color: #fff;
$checkbox-active-text-color: $checkbox-color;
$checkbox-check-color: $checkbox-color;
$checkbox-check-background: #fff;

$instalment-slider: $link-color;

$delivery-info-color: $accent-color;

$outlet-color: #3b80ba;

$promotion-bar-background: #008FC7;
$menu-bar-background: #00c71e;

$new-menu-bar-background: #0090CC;
$new-menu-bar-active-background: #2184FE;

$accessories-active-background: $filter-selected;
$paging-active-background: $filter-selected;
$extended-warranty-background: $filter-selected;
$call-btn-active-background: $filter-selected;
$clipboard-btn-active-background: $filter-selected;
$compare-btn-active-background: $filter-selected;
$opinion-info-background: $filter-selected;
$menu-product-active: $link-color;

//zielony: #368F43;
//niebieski: #008FC7;
//żółty: #E9C531
//ciemne tło: #3C3B3B;
//jasne tło: #EAEAEA;

$button-primary-background			: $primary-color;						//button primary
$button-primary-color				: #fff;																		//kolor button primary
$button-primary-background-hover	: lighten($primary-color, 5%);					//button primary hover
$button-primary-color-hover			: #fff;																		//kolor button primary hover

$button-second-background			: #0072ff;						//button second
$button-second-color				: #fff;																		//kolor button second
$button-second-background-hover		: lighten(#0072ff, 5%);						//button second hover
$button-second-color-hover			: #fff;																		//kolor button second hover

$button-third-background			: $button-second-background;																	//button seventh
$button-third-color					: #ffffff;																	//kolor button seventh
$button-third-background-hover		: $button-second-background-hover;											//button seventh hover
$button-third-color-hover			: #ffffff;																	//kolor button seventh hover

$button-fourth-background			: #5c5c5c;						//button third
$button-fourth-color				: #fff;																		//kolor button third
$button-fourth-background-hover		: lighten(#5c5c5c, 5%);																	//button third hover
$button-fourth-color-hover			: #fff;																		//kolor button third hover

$background-alert					: #E63327;																	//button alert
$background-alert-hover				: #FF372A;																	//button alert hover

$button-disabled-background			: linear-gradient(to bottom, #eee 0%, #ccc 100%);
$button-disabled-color				: #eee;

$border-color						: #CCCCCC;																	//kolor obramowania

$img-url: '/img/desktop/ole/';

$font-default: 'Lato',sans-serif;
$font-default-size: 12px;
$font-default-line-height: 1.2;

$default-box-shadow-horizontal:0;
$default-box-shadow-vertical:0;
$default-box-shadow-blur:4px;
$default-box-shadow-color: rgba(0,0,0,0.25);

$breakpoint-large: 1230;
$breakpoint-midium: 980;
$breakpoint-large-width: 1200;
$breakpoint-midium-width: 960;
$breakpoint-midium-height: 760;

$default-box-border-radius:4px;
