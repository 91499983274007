button {
	cursor: pointer;
}

object {
	pointer-events: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

b, strong { //nadpisane z normalize
	font-weight: bold;
}

a {
	color: $link-color;
	text-decoration: none;

	&:hover {
		color: $link-color-hover;
		text-decoration: underline;
	}
}

h1, h2, h3, h4 {
	margin: 0;
}

div {
	text-align: left;
}

ul {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
	}
}
/* stabilizacja scrolla przy overfow:hidden aby strona nie skakala w prawo przy znikajacym scrollbarze*/
html {
	scrollbar-gutter: stable;
}

body {
	-webkit-overflow-scrolling: touch;
	width: 100%;
}