#breadcrumb {
	overflow-x:auto;
	text-transform: uppercase;
	ul {
		margin:10px 5px 10px 0;
		padding:0;
		display: flex;
		span {
			color: #1C1C1B;
		}
		li {
			display:flex;
			align-items: center;
			div {
				display: inline;
			}
			a.current {
				color: $text-color;
				&:hover {
				  text-decoration: none;
				  cursor: default;
				}
			}
			&::after {
				@extend %icon-arrow;
				margin: 0 4px;
			}
			&:last-child {
				&:after {
					display: none;
				}
				a{
					color: #1C1C1B;
					pointer-events: none;
				}
			}
		}
		> span + li {
			&:before {
				@extend %icon-arrow;
				margin: 0 4px;
			}
		}
	}
}
