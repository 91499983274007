#what-to-choose {
	font-weight: bold;
	font-size: 14px;
	margin:10px 0;

	.header {
		background-color: #eaeaea;
		font-weight: bold;
		padding: 5px 15px;
		font-size: 24px;
		color: #1c1c1b;
	}
	.list{
		overflow:hidden;
		padding:0;
	}
	.item {
		display: block;
		padding: 10px 0;
		width: 50%;
		float: left;
		&:nth-child(2n-1){
			clear:both;
		}
	}
	.photo {
		float: left;
		width:80px;
		display:block;
		height:80px;
		margin-left:10px;
		img{
			width:100%;
			height:100%;
		}
	}

	.description {
		padding: 10px 15px 15px;
		margin-left:90px;
		width:auto;
		float:none;
		.text {
			font-size: 14px;
			display:block;
			color:$text-color;
		}
	}

	a.more {
		margin-top: 4px;
		float: right;
		text-transform: uppercase;
		font-size: 12px;
		&::after{
			@extend %icon-small-arrow;
			font-size: 22px;
		}
	}
}
