#modal-email-rules{
	max-width: 450px;
	.yes{
		margin-top:20px;
        margin-right:5px;
		@extend %btn-primary;
	}
	.no{
		margin-top:10px;
        margin-left:5px;
		@extend %btn-fourth;
	}
	h2{
		padding:0;
		margin:0;
		font-weight: bold;
		font-size: 24px;
	}
	h3{
		padding:0;
		margin:10px 0 0;
		font-weight: normal;
		font-size: 18px;
		line-height: 22px;
	}
    .buttons{
        text-align: center;
    }
}