#leaf,
#node {
	.product-articles,
	.product-button,
	.product-price {
		background: none;
	}

	.product-articles {
		width: 245px;
	}
}

.page-init #products-filter {
	&::after,
	&:before {
		display: none;
	}
}

#products-filter {
	@extend %loading;
	float: left;
	margin: 15px 0 15px 15px;
	width: 270px;
}
@if($app-katalog!=true) {
	 @media all and (max-width: #{$breakpoint-large}px){
		#products-filter {
			width: 240px;
			margin: 15px 0 15px 10px;
		}
	}
}

#product-list {
	float: right;
	width: 870px;
	margin: 0 15px 0 0;
	@if($app-katalog!=true) {
		 @media all and (max-width: #{$breakpoint-large}px){
			width: 680px;
			margin-right: 10px;
		}
	}
}
.view-list #products{
	&.tiles, &.list{
		.product-tile{
			display: none;
		}
		.product-box{
			display: table;
		}
	}
}
.view-tiles #products{
	.product-for-list{
		float:left;
		@media all and (max-width: #{$breakpoint-large}px) {
			&:nth-child(3n) .product-tile{
				margin-right:0;
			}
			&:nth-child(3n+1){
				clear:both;
			}
		}
		@media all and (min-width: #{$breakpoint-large+1}px) {
			&:nth-child(4n) .product-tile{
				margin-right:0;
			}
			&:nth-child(4n+1){
				clear:both;
			}
		}
	}
	&.list, &.tiles {
		.product-box{
			display: none;
		}
		.product-tile{
			display:block;
		}
	}
	.banners-among_products{
		display: none !important;
	}
}
.banners-among_products {
	overflow: hidden;
	.banner-simple:last-child{
		margin-bottom:10px;
	}
	.banner-simple + .banner-simple{
		margin-top:10px;
	}
}

#products {
	padding: 0 0 15px;
	margin: 0 auto;
	.product-box {
		width: 870px;
		box-sizing: border-box;
		padding: 0;
		margin: 0 0 10px;
		display: table;
	}
	&.tiles{
		.product-box{
			display:none;
		}
	}
	&.list{
		.product-tile{
			display:none;
		}
	}

	.product-top-list, .product-advance-sale {
		border-width: 2px;
		border-style: solid;
		position: relative;
		overflow: inherit;

		.product-articles,
		.product-sales {
			width: 244px;
		}

		.product-main {
			&::after {
				text-transform: uppercase;
				position: absolute;
				top: -5px;
				line-height: 1em;
				left: 8px;
				background: #fff;
				padding: 0 7px;
				font-weight: bold;
				font-size: 12px;
			}
		}

		&.product-box .product-main {
			padding-left: 14px;
			border: 0;
		}

		.product-main .product-community {
			left: 15px;
		}
	}

	.product-top-list {
		border-color: #45abe5;

		.product-main {
			&::after {
				@if($app-katalog) {
					content: "Polecany";
				}
				@else {
					content: "Bestseller";
				}
				color: #45abe5;
			}
		}
	}

	.product-advance-sale {
		border-color: $advance-sale-color;
		
		.product-main {
			&::after {
				color: $advance-sale-color;
				content: "Przedsprzeda\017c";
			}
		}

		.przedsprzedaz {
			display: none;
		}
	}

	.product-bottom-bar,
	.product-bottom-utils {
		clear: both;
	}

	.product-main {
		width: 594px;
		padding: 8px 15px 35px;
		border: 1px solid #eaeaea;
		border-right: 0;
		display: table-cell;
		vertical-align: top;
		position: relative;

		.product-community {
			position: absolute;
			bottom: 6px;
			left: 16px;
		}
	}

	.product-sales {
		background: #EAEAEA;
		position: relative;
		display: table-cell;

		@if ($app-katalog) {
			padding-bottom: 45px;

			&.show-reservations {
				padding-bottom: 88px;
			}
		}
	}

	.product-name {
		font-weight: bold;
		font-size: 20px;
		padding-bottom: 5px;
		@media all and (max-width: #{$breakpoint-large}px) {
			font-size: 15px;
		}

		a,
		a:hover {
			color: $text-color;
		}
	}

	.product-promotion-logo {
		bottom: 30px;
	}

	.product-photo {
		@if($app-katalog) {
			width: 180px;
			height: 159px;
		}
		@else {
			width: 260px;
			height: 230px;
		}
		padding-bottom: 5px;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			display: block;
		}

		a {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
		}
	}

	.product-info {
		@if($app-katalog) {
			width: 403px;
		}
		@else {
			width: 320px;
		}
		margin-left: 10px;
		padding-bottom: 5px;
	}

	.product-status {
		left: -16px;
	}
	@if($app-katalog) {
		.add-to-cart-big {
			padding: 12px;
		}
	}
	@else {
		 @media all and (max-width: #{$breakpoint-large}px){
			.product-box {
				width: 680px;
			}

			.product-main {
				width: 419px;
			}

			.product-photo {
				width: 195px;
				height: 173px;
			}

			.product-info {
				width: 196px;
			}

			.product-articles {
				width: 230px;
			}

			.product-top-list {
				.product-main {
					width: 418px;
				}

				.product-articles,
				.product-sales {
					width: 228px;
				}
			}
			@if ($app-euro) {
				.call-button,
				.product-call-and-order {
					display: none;
				}
			}
		}
	}
}

#search-header {
	font-size: 12px;

	span {
		font-weight: bold;
	}

	.suggestion-try {
		color: #ed1c24;
		font-size: 14px;

		a {
			color: #005ca9;
			font-weight: bold;
		}
	}

	.initial-keyword {
		font-weight: bold;
	}

	div:last-of-type {
		padding-bottom: 15px;
	}
}

#empty-search {
	font-size: 18px;
	padding-bottom: 10px;

	span {
		font-weight: bold;
	}
}

#empty-search-message {
	padding-bottom: 20px;
}

.go-top-list {
	display: none;
}

.sticky.pageAjax-enabled {
	.go-top-list:not(.hide) {
		position: fixed;
		display: inline-block;
		background: #EAEAEA;
		top: 50%;
		left: 50%;
		z-index: 1;
		font-size: 17px;
		width: 320px;
		height: 50px;
		color: #333333;
		text-align: center;
		line-height: 50px;
		white-space: nowrap;
		font-weight: bold;
		text-transform: uppercase;

		&:hover {
			color: $text-color-lighter;
			background: #F7F7F7;
			text-decoration: none;

			&::after {
				border-color: transparent transparent #7D7D7D;
			}
		}

		&::after {
			content: "";
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 12px;
			border-color: transparent transparent #333333;
			margin-left: 5px;
		}
	}

	#product-list {
		z-index: 2;
		background: #ffffff;
		position: relative;

		&:after {
			margin-bottom: -20px;
			content: "";
			height: 20px;
			width: 100%;
			display: block;
			background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		}
	}
}

html.pageAjax-load {
	#filter-selected,
	#filter-sort,
	#filter-sort-bar,
	#filter-stocks,
	#no-selection-notify,
	.filter {
		&:not(.filter-redirect-active) {
			opacity: 0.3;
			pointer-events: none;
		}

		&.filter-redirect-active {
			@extend %loading;
			opacity: 1;
		}
	}

	.set-filters {
		opacity: 0.3;
		pointer-events: none;
	}
}

.outlet-carousel {
	border: 1px solid #eaeaea;
	width: 870px;
	box-sizing: border-box;
	padding: 18px 17px;
	margin: 0 0 10px;
	display: flex;
	height: 274px;

	.description {
		flex-basis: 300px;
		flex-shrink: 0;
	}

	#outlet-slider {
		width: 540px;
		overflow: hidden;

		&.product-slider {
			&::before, &::after {
				width: 40px;
			}
		  .product-slider-next, .product-slider-previous {
			height: 86px;
			transform: translate(0, -50%);
			margin-top: 0;
		  }
		}
	}

	.products {
		flex-grow: 1;
		padding-bottom: 20px;
		overflow: hidden;
		left: 0;
		position: absolute;
		display: flex;
	}

	.link {
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 5px;
		display: block;

		&:after {
			@extend %icon-small-arrow;
			color: $link-color;
			font-size: 20px;
		}
	}

	.outlet-product {
		width: 162px;
		box-sizing: border-box;
		padding-right: 10px;

		h3 {
			padding: 0;
		}

		.wrapper {
			border: 1px solid #eaeaea;
			padding: 15px;
		}

		.price-outlet {
			color: $link-color;
			font-weight: bold;
			font-size: 14px;
		}

		.standard-price {
			font-weight: normal;
			color: $text-color-lighter;
		}

		.description {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 99px;
			padding-top: 17px;
		}

		.description-name {
			display: block;
			overflow: hidden;
			max-height: 32px;
			font-size: 12px;
		}

		.product-image {
			height: 86px;
			display: flex;
			justify-content: center;

			img {
				width: auto;
			}
		}

		.group-name {
			font-weight: normal;
			color: $text-color-lighter;
		}
	}
}

@media all and (max-width: #{$breakpoint-large}px) {
	.outlet-carousel {
		width: 680px;
	}
}

#search-related-keywords {
	height: 98px;

	&.loading {
		@extend %loading;
	}

	&.is-hidden {
		display: none;
	}

	.related-keywords-heading {
		font-size: 12px;
		text-transform: uppercase;
		margin-bottom: 1rem;
		padding: 0;
	}

	.related-keywords-list {
		transition: transform .3s ease-in-out;
		will-change: transform;
		flex-wrap: nowrap;
		white-space: nowrap;
	}

	.related-keyword-link {
		margin-bottom: 0;
	}

	.carousel {
		overflow: hidden;
	}

	.carousel-wrapper {
		position: relative;
	}

	.prev, .next {
		@extend %btn-raised;

		position: absolute;
		height: 100%;
		width: 35px;
		text-align: center;
		line-height: 35px;
		padding: 0;
		z-index: 2;
		top: 0;

		&:before {
			@extend %icon-small-arrow;
			font-weight: bold;
			font-size: 32px;
		}
	}

	.prev {
		left: 0;
		transform: translateX(-10px);

		&:before {
			transform: rotate(180deg);
		}
	}

	.next {
		right: 0;
		transform: translateX(10px);
	}
}