.allegro-voucher {
	background: #fffbcc;
	color: #e30613;

	ul {
		padding: 10px 15px;
		display: block;
	}

	li {
		padding: 5px 0;
	}

	.voucher-name {}

	.voucher-code {
		font-weight: bold;
	}

	.voucher-price {}
}

#cart {
	//bloczek jest też na koszyku i ma bardzo ograniczone stylowanie
	.allegro-voucher {
		background: none;
		font-size: 11px;

		ul {
			padding: 0;
			margin-top: 10px;
			border: 0;
		}

		li {
			padding: 0;
		}
	}
}
