.banners{
	.lazy{
		&.thin, &.wide{
			width: auto;
		}
	}
	& + div{
		clear:both;
	}
	.banner-photo .lazy{
		width:auto;
	}
}


@media screen and (min-width: $breakpoint-large+px) {
	.banner-photo .thin{
		display:none;
		visibility: hidden;
	}
}
@media screen and (max-width: $breakpoint-large+px) {
	.banner-photo .wide{
		display:none;
		visibility: hidden;
	}
}
.banner-photo{
	text-align:center;
	margin-top:5px;
	&:first-child{
		margin-top:0;
	}
	img{
		display:block;
		margin: 0 auto;
	}
}
.banners-listing_header{
	margin-bottom:10px;
}
.rotator {
	position: relative;
	@for $i from 0 through 14 {
		&.shift-#{$i} {
			.rotator-progress li:nth-child(#{$i+1}) {
				background-color: #e8cb16;
			}
		}
	}
	.banners-header {
		height: 100px;
		margin-bottom: 15px;
		@media all and (max-width: #{$breakpoint-large}px) {
			height: 80px;
		}
		.banner-photo img{
			height: auto;
			max-width: 100%;
		}
	}

	.banners-listing_header {
		height: 50px;
	}

	&.rotator-listing_header {
		margin-bottom: 20px;
	}
	.banner-photo{
		margin-top:0;
		text-align:center;
		img{
			margin:0 auto;
		}
	}
	.banners {
		position: relative;
		overflow: hidden;
		.banner-item {
			position: absolute;
			width:100%;
			height:100%;
			top: 0;
			left: 500%;
			opacity:0;
			z-index: 0;
			visibility: hidden;
			transition: opacity 0.4s;
		}
	}
	@for $i from 0 through 14 {
		&.shift-#{$i} {
			.banner-item:nth-child(#{$i+1}) {
				visibility: visible;
				opacity:1;
				left:0;
				z-index: 1;
			}
		}
	}
}

.rotator-progress {
	position: absolute;
	z-index: 2;
	bottom: -22px;
	text-align: center;
	width: 100%;

	ul {
		margin: 0 auto;
		list-style-type: none;
		padding: 0;
		font-size: 0;
		display: inline-block;
	}

	li {
		border-radius: 50%;
		width: 10px;
		cursor: pointer;
		height: 10px;
		margin: 5px;
		display: inline-block;
		background-color: #b9b9b9;
		transition: background-color 0.3s ease;
	}
}

.header-sticky #ear-box {
	top: 70px;
}
.sticky #ear-box .list>div{
	top:5px;
}
#ears{
	z-index:0;
}
.sticky #ears{
	position:fixed;
	width:100%;
}
#ear-box{
	margin:0 auto;
	width:$breakpoint-large-width+px;
	.list{
		position:relative;
		>div{
			width:auto;
			position:absolute;
			top:0;
			>div, >a{
				position:absolute;
				right:0;
			}
		}
	}
	img{
		width:auto;
	}

	.list{
		#right-ear:not(.flex){
			>div, >a{
				left:0;
			}
		}
	}

	.flex{
		@media all and (max-width: #{$breakpoint-large+200}px) {
			display:none;
		}
		img, .html{
			width:100%;
		}
	}

	@media all and (max-width: #{$breakpoint-large}px) {
		width: $breakpoint-midium-width+px;
	}

	#left-ear{
		right:$breakpoint-large-width+px;
	}
	#right-ear{
		left:$breakpoint-large-width+px;
	}
}
.banners-among_products{
	.banner-item.banner-photo{
		.lazy.loaded{
			max-width: 100%;
		}
	}
}
