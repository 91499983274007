.operator-logged{
	.operator-profit:not(.profit-0) {
		display:inline-block;
	}
	.filter.filter-operator-profit{
		display:block;
	}
}
.product-outlet , .product-box.product-is-outlet{
	.operator-profit{
		visibility: hidden;	//z racji zajmowania miejsca nie daję dispay none aby treść nie skakała
	}
}
.filter.filter-operator-profit{
	display: none;
	background: #fffbcc;
	.operator-profit{
		padding:0;
		margin:0 0 5px 0;
	}
}
.operator-profit {
	display:none;
	background: #fffbcc;
	padding:5px 4px;
	margin-left:5px;
	position: relative;
	top: -1px;
	span{
		height:18px;
		line-height: 17px;
		display: inline-block;
		color:#fff;
		font-size:11px;
		width:75px;
		text-align: center;
		font-weight: bold;
		border-radius: 8px;
		&:after{
			@extend %icon-arrow-profit;
			font-size:12px;
			font-weight: normal;
		}
		&:before{
			margin-right:3px;
		}
	}
	&.profit-1 span{
		background:#00ccff;
		&:before{
			content:"MINI";
		}
		&:after{
			transform: rotate(-135deg);
		}
	}
	&.profit-2 span{
		background:#00cc99;
		&:before{
			content:"MIDI";
		}
		&:after{
			transform: rotate(180deg);
		}
	}
	&.profit-3 span{
		background:#ff9933;
		&:before{
			content:"MAX";
		}
		&:after{
			transform: rotate(135deg);
		}
	}
	&.profit-4 span{
		background:#cc0033;
		&:before{
			content:"MAX+";
		}
		&:after{
			transform: rotate(90deg);
		}
	}
}
