.product-carousel-list {
	padding: 3px;
	overflow: hidden;

	> div {
		display: flex;
		transition: transform 0.3s ease-in-out;
		will-change: transform;
	}

	&:not(.last-slide) {
		&:after {
			position: absolute;
			height: 100%;
			background: linear-gradient(
							to left,
							white,
							rgba(255, 255, 255, 0));
			display: block;
			content: "";
			right: 0;
			top: 0;
			width: 30px;
			z-index: 1;
		}
	}

	&:not(.first-slide) {
		&:before {
			position: absolute;
			height: 100%;
			background: linear-gradient(
							to right,
							white,
							rgba(255, 255, 255, 0));
			display: block;
			content: "";
			left: 0;
			top: 0;
			width: 30px;
			z-index: 1;
		}
	}
}

.product-carousel-item {
	padding: 10px;
	position: relative;
	border-radius: 4px;
	flex-shrink: 0;
	box-sizing: border-box;

	.product-photo img {
		height: auto;
	}

	a:hover {
		text-decoration: none;
	}

	.price-instalments {
		font-size: 12px;
		margin-bottom: .7rem;

		&:before {
			color: #999;
			content: "raty:";
			margin-right: 2px;
			text-transform: uppercase;
		}
	}

	.product-name {
		a {
			color: $text-color;
			font-weight: bold;
			white-space: nowrap;
		}

		color: $text-color;
		white-space: nowrap;
		font-weight: bold;
		margin-bottom: 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 190px;
	}

	.price-normal {
		font-size: 18px;
		margin-top: 10px;
		margin-bottom: 7px;
	}

	&:hover {
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 1px 5px rgba(0, 0, 0, 0.15);
	}
}