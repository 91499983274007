#scroll-top {
	&::after{
		@extend %icon-scroll;
		color:$text-color;
		font-size:28px;
	}
	&:hover{
		text-decoration:none;
	}
	position: fixed;
	right: -1241px;
	width:30px;
	height:27px;
	left: 0;
	bottom: 10px;
	font-size:0;
	margin: auto;
	transition: all 0.3s ease;
	transform: rotate(30deg) scale(0.8) translate(-10px, 60px);
}

@media screen and (max-width:($breakpoint-large+70)+px) {

	#scroll-top {
		right: 13px;
		left: auto;
	}
}
html.sticky #scroll-top {
	transform: rotate(0deg) scale(1) translate(0px, 0px);
}
