

.email-invalid{
	background: #FFF2E9;
	border-radius: 10px;
	margin: 5px 0 0;
	padding-bottom: 10px;
	.email-invalid-message{
		display: block;
	}
}
#email-invalid-text{
	color: #2B77B8;
		cursor: pointer;
}
.email-invalid-message{
	display: none;
	font-weight: normal;
	font-size: 13px;
	line-height: 14px;
	color: #FF781E;
	margin-top:5px;
	@if($app-katalog==false){
		padding-left: 100px;
	}
	font-weight: bold;
}
