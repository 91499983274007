#node-tree{
	border-top: 1px solid $border-color;
	ul{
		display: block;
		list-style:none;
	}
	li{
		display: block;
		list-style:none;
	}
	.collapsed-category > ul {
		display: none;
	}
	.node-tree-header{
		font-weight: bold;
		padding: 0 10px 0 30px;
		margin-bottom: 10px;
		text-align:left;
		font-size: 14px;
		color:#333;
		position: relative;
		cursor: pointer;
		display: inline-block;
		text-transform: uppercase;
		&:before {
			border-bottom: 8px solid #999;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			content: "";
			left: 7px;
			margin-top: -3px;
			position: absolute;
			top: 50%;
			transition: all 0.3s ease;
		}
		&:hover:before {
			border-bottom-color: $link-color-hover;
		}
	}

	.category-link{
		a{
			display: block;
			padding: 5px 10px;
			text-decoration: none;
			color: $text-color;
			&:hover{
				text-decoration:underline;
				background: $filter-selected;
			}
		}
		&.category-hidden{
			display: none;
		}
	}

	em{
		color: $text-color-lighter;
		font-style: normal;
		float:right;
	}

	.category-show-more{
		a{
			font-size:11px !important;
			color:$link-color;
			font-weight:bold;
		}
	}
	ul.level-1{
		>.category-link {
			>a{
				font-size:14px;
				font-weight:bold;
				em{
					font-size:14px;
					font-weight:bold;
				}
			}
		}
	}
	ul.level-2{
		>.category-link {
			>a{
				font-size:12px;
				font-weight:bold;
				padding-left:20px;
				em{
					font-size:12px;
					font-weight:bold;
				}
			}
		}
		>.category-show-more{
			a{
				padding-left:20px
			}
		}
	}
	ul.level-3{
		>.category-link {
			>a{
				font-size:11px;
				padding-left:30px;
				em{
					font-size:11px;
				}
			}
		}
		>.category-show-more{
			a{
				padding-left:30px
			}
		}
	}
	ul.level-4{
		>.category-link {
			>a{
				font-size:10px;
				padding-left:40px;
				em{
					font-size:10px;
				}
			}
		}
		>.category-show-more{
			a{
				padding-left:40px
			}
		}
	}
	ul.level-5{
		>.category-link {
			>a{
				font-size:10px;
				padding-left:45px;
				em{
					font-size:10px;
				}
			}
		}
		>.category-show-more{
			a{
				padding-left:45px
			}
		}
	}
	&.node-uncollapsed {
		> *:not(.node-tree-header) {
			display: none;
		}
		.node-tree-header {
			margin-bottom: 0;
			&:before {
				transform: rotate(180deg);
			}
		}
	}
}
#leaf {
	#node-tree {
		em {
			float: none;
		}
	}
}
