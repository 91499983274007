input::-ms-clear {
	display: none;
}

.select-multi{
	width:200px;
	display:block;
	position:relative;
	line-height:30px;
	cursor:pointer;

	.select-multi-selected-item {
		background: #fff;
		border: 1px solid #aaa;
		width: 100%;
		padding: 0 20px 0 8px;
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-height: 32px;
	}
	.select-multi-selection-icon {
		position:absolute;
		right:0;
		top:50%;
		margin-top:-2.5px;
		right:16px;
		z-index: 1;
		border-color: #888 transparent transparent;
		border-style: solid;
		border-width: 5px 4px 0;
		position: absolute;
		transition: transform 0.3s ease 0s;
	}
	.select-multi-option-list {
		background:#fff;
		border: 1px solid #aaa;
		top: 100%;
		position:absolute;
		left:0;
		/* poprzednio 100%, inherit wymagany - w przeciwnym razie plugin do scrollbara pobiera
		wartość szerokości jako 100 */
		width:inherit;
		overflow: hidden;
		z-index: 2;
		box-sizing: border-box;
		margin: -1px 0 0;
		&:empty {
			display:none;
		}
		.select-multi-option-list-wrap {
			position: relative;
			width: inherit;
			max-height: 300px;
			overflow-y: auto;
		}
	}
	.select-multi-list-hidden{
		display:none;
	}
	.checkbox-css{
		display: block;
		line-height:1em;
		padding:7px 10px;
		i{
			border:0 !important;
			background: none !important;
		}
		+.checkbox-css{
			margin:0
		}
		&:hover{
			background-color:#005ca9;
			color:#fff;
			i:after{
				color:#fff !important;
			}
		}
	}
}
.select3-container {
	width:200px;
	display:block;
	position:relative;
	line-height:30px;
	cursor:pointer;
	&.theme2 {
		position:absolute;
		left:50px;
		top:50px;
	}
	&.select3-disable {
		pointer-events:none;
		cursor:wait;
		opacity:0.6;
	}
	&.select3-unactive {
		pointer-events:none;
		cursor:wait;
	}
	&.select3-autocomplete {
		.select3-selected-item {
			display:none;
		}
	}
	&.select3-loading {
		.select3-spinner {
			@extend %loading-small;
			position: absolute;
			top: 2px;
			right: 2px;
			height: 26px;
			width: 26px;
			background: #fff;
		}

		.select3-selection-icon {
			display: none;
		}
	}
	.select3-input {
		display: none;
	}
	.select3-filter, .select3-selected-item {
		background: #fff;
		border: 1px solid #aaa;
		width: 100%;
		padding: 0 20px 0 8px;
		box-sizing: border-box;
		cursor: pointer;
	}
	.select3-filter {
		line-height: inherit;
		outline: none;
	}
	.select3-selected-item {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-height: 32px;
		i {
			margin-left:3px;
		}
	}
	.select3-selection-icon {
		position:absolute;
		right:0;
		top:50%;
		margin-top:-2.5px;
		right:16px;
		z-index: 1;
		border-color: #888 transparent transparent;
		border-style: solid;
		border-width: 5px 4px 0;
		position: absolute;
		transition: transform 0.3s ease 0s;
	}
	.select3-option-list {
		background:#fff;
		border: 1px solid #aaa;
		top: 100%;
		position:absolute;
		left:0;
		/* poprzednio 100%, inherit wymagany - w przeciwnym razie plugin do scrollbara pobiera
		wartość szerokości jako 100 */
		width:inherit;
		overflow: hidden;
		z-index: 2;
		box-sizing: border-box;
		margin: -1px 0 0;
		&:empty {
			display:none;
		}
		.select3-option-list-wrap {
			position: relative;
			/* poprzednio 100%, inherit wymagany - w przeciwnym razie plugin do scrollbara pobiera
			wartość szerokości jako 100 */
			width: inherit;
		}
		.select3-option {
			display:block;
			padding:0 8px;
			height: 30px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			i {
				margin-left:3px;
			}
		}
		.select3-option-with-additional-info {
			height: auto;
			&::after {
				content: attr(data-additional-info);
				display: block;
				max-width: 100%;
				height: auto;
				margin-top: -3px;
				padding-bottom: 8px;
				font-size: 10px;
				line-height: 13px;
				white-space: normal;
			}
		}
		.select3-option-hidden {
			display:none;
		}
		.select3-option-selected {
			background-color: #aaa;
			color: #fff;
			i {
				filter: brightness(0) invert(1);
			}
		}
		.select3-option-highlighted {
			background-color: #005ca9;
			color: #fff;
			i {
				filter: brightness(0) invert(1);
			}
		}
		.select3-option-hidden {
			display: none;
		}
		/* Domyslnie elementy maja padding 6px dookola wiec 6px + nowa wartosc */
		.select3-option-level-1 {
			padding-left: 22px!important;
		}
		.select3-option-level-2 {
			padding-left: 29px!important;
		}
		.select3-option-level-3 {
			padding-left: 36px!important;
		}
		.select3-option-level-4 {
			padding-left: 43px!important;
		}
	}
	.select3-list-hidden, .select3-list-empty {
		display:none;
	}
}
.select3-container.expanded {
	.select3-selection-icon {
		transform: rotate(-180deg);
		transform-origin: center center 0;
	}
}

.simplebar, [data-simplebar-direction] {
	position: relative;
	overflow: hidden;
	-webkit-overflow-scrolling: touch; /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

.simplebar .simplebar-scroll-content,
[data-simplebar-direction] .simplebar-scroll-content {
	overflow-y: scroll;
	overflow-x: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	-ms-overflow-style: none; /* hide browser scrollbar on IE10+ */
}

/* hide browser scrollbar on Webkit (Safari & Chrome) */
.simplebar-scroll-content::-webkit-scrollbar {
	display: none;
}

[data-simplebar-direction="horizontal"] .simplebar-scroll-content,
.simplebar.horizontal .simplebar-scroll-content {
	overflow-x: scroll;
	overflow-y: auto;
}

.simplebar-track {
	z-index: 3;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 11px;
}

.simplebar-track .simplebar-scrollbar {
	position: absolute;
	right: 2px;
	min-height: 10px;
	width: 7px;
	background: #999;
	opacity: 0;
	transition: 200ms ease opacity;
	&.visible{
		opacity: 1;
	}
}

[data-simplebar-direction="horizontal"] .simplebar-track,
.simplebar.horizontal .simplebar-track {
	top: auto;
	left: 0;
	width: auto;
	height: 11px;
}

[data-simplebar-direction="horizontal"] .simplebar-track .simplebar-scrollbar,
.simplebar.horizontal .simplebar-track .simplebar-scrollbar {
	right: auto;
	top: 2px;
	height: 7px;
	min-height: 0;
	min-width: 10px;
	width: auto;
}

.select3-container.theme1 {
	float: left;
	width: 180px;
	border: none;
	height: 42px;
	line-height: 42px;
	.select3-selected-item {
		color: #444;
		padding: 0;
		right: 8px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 160px;
		border: none;
		white-space: nowrap;
	}
	.select3-option-list {
		top: 43px;
		width: 239px;
		left: -15px;
		.select3-option {
			padding: 6px;
			height: 15px;
			line-height: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.select3-option-selected {
			color: #000;
			background-color: #ddd;
		}
		.select3-option-highlighted {
			background-color:#005CA9;
			color: #fff;
		}
	}
}
.select3-container.theme1::before {
	background: #cccccc none repeat scroll 0 0;
	content: "";
	height: 22px;
	position: absolute;
	top: 10px;
	left: -15px;
	width: 1px;
}
@media all and (max-width: 1230px) {
	.select3-container.theme1 {
		margin-left: 10px;
		width: 135px;
		.select3-selected-item {
			padding-left: 10px;
			max-width: 105px;
		}
		.select3-option-list {
			left: -60px;
		}
	}
	.select3-container.theme1::before {
		left: 0px;
	}
}

.select3-container.theme-btn-fourth {
	border: none;
	height: 35px;
	background: $button-fourth-background;
	font-size: $font-default-size;

	.select3-selected-item {
		font-weight: bold;
		line-height: 19px;
		color: #fff;
		padding: 8px 35px;
		text-align: center;
	}
	.select3-selection-icon{
		border-color: #fff transparent transparent;
	}
}

.select3-container.theme-opinion-filter-sort{
	.select3-selected-item{
		border: none;
		height: 34px;
		line-height: 34px;
	}
	.select3-selection-icon{
		right: 8px;
	}
}
